import { Link } from 'react-router-dom'

import { useSitemodelContent } from 'sitemodel/shared/hooks'

type Props = {
  uuid: string
  des: string
  sitemodelContent: string | undefined
  uuidObjectClass: string | undefined
}

const FrameButton: React.FunctionComponent<Props> = ({
  uuid,
  des,
  sitemodelContent,
  uuidObjectClass
}): JSX.Element => {
  const content = useSitemodelContent({ sitemodelContent })
  const renderWithinMasterdata = content?.render_within_masterdata
  const isObjectClassSite =
    uuidObjectClass !== 'UUID_Objectclass_Sitemodel' &&
    uuidObjectClass !== undefined
  const url = isObjectClassSite ? `/${uuidObjectClass}/${uuid}` : `/${uuid}`
  const sitemodelLinkText = renderWithinMasterdata ? `Analyze ${des}` : 'Open'
  const linkText = isObjectClassSite ? 'Run' : sitemodelLinkText
  return (
    <Link to={url} className='btn btn-outline-secondary btn-sm'>
      <span className='fw-bolder'>{linkText}</span>
    </Link>
  )
}

export default FrameButton
