import { YesObject } from '@yes.technology/react-toolkit'

export function validateAndParseCoordinates<T extends YesObject>(
  address: T
): false | google.maps.LatLngLiteral {
  if (!address.latitude || !address.longitude) return false
  return {
    lat: parseFloat(address.latitude as string),
    lng: parseFloat(address.longitude as string)
  }
}
