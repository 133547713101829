export type AreaProps = {
  props?: { children?: React.ReactNode } | null
}

const Area = ({ props }: AreaProps) => {
  if (!props?.children) return null

  return <>{props.children}</>
}

export default Area
