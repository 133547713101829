import { Unifree, Text } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const CardTitle = styled(Text).attrs({
  variant: 'content-emphasis-03'
})``

export const Line = styled.hr`
  margin: 8px 0;
`

export const InformationalInput = styled(Unifree).attrs({
  required: true,
  informational: true,
  heightClass: 'xSmall'
})``
