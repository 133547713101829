import {
  CollapsibleCard,
  LinkButton,
  YesObject
} from '@yes.technology/react-toolkit'
import OrderTable from './OrderTable'
import { CardTitle, InformationalInput, Line } from './TransferOrderCard.styles'
import { GenericOrder, TransferOrderCardProps } from './types'
import { useTranslation } from 'react-i18n-lite'
import useStatusByCode from 'status/shared/hooks/useStatusByCode'
import { useCallback } from 'react'
import useTransferOrderDetail from './useTransferOrderDetail'

export default function TransferOrderCard({
  transferOrder
}: TransferOrderCardProps) {
  const { t } = useTranslation()

  const { sid, sender, receiver, uuid_status: statusCode, uuid } = transferOrder

  const { status } = useStatusByCode({
    objectclassUuid: window.uuidObjectclassTransferorder,
    statusCode
  })

  const {
    handoffOrders,
    receiptOrders,
    passOrders,
    loadingOrders,
    unloadingOrders,
    classificationOrders,
    productsCombosDetailedMap
  } = useTransferOrderDetail({ transferOrder })

  const renderOrderTable = useCallback(
    (orders: YesObject[], titleKey: string) =>
      orders?.length ? (
        <OrderTable
          title={t(titleKey)}
          orders={orders as GenericOrder[]}
          productCombos={productsCombosDetailedMap}
        />
      ) : null,
    [productsCombosDetailedMap, t]
  )

  return (
    <CollapsibleCard
      heightClass='doubleXSmall'
      headerColor='white'
      leftHeaderElement={
        <CardTitle>
          {statusCode} | {status?.des}
        </CardTitle>
      }
      rightHeaderElement={<CardTitle>{sid as string}</CardTitle>}
    >
      <Line />

      <InformationalInput
        label={t('transfer-order-card.shipper')}
        value={sender?.[0]?.des}
      />

      <InformationalInput
        label={t('transfer-order-card.receiver')}
        value={receiver?.[0]?.des}
      />
      {renderOrderTable(handoffOrders, 'transfer-order-card.delivery-order')}
      {renderOrderTable(receiptOrders, 'transfer-order-card.receipt-order')}
      {renderOrderTable(passOrders, 'transfer-order-card.pass-order')}
      {renderOrderTable(loadingOrders, 'transfer-order-card.loading-order')}
      {renderOrderTable(unloadingOrders, 'transfer-order-card.unloading-order')}
      {renderOrderTable(
        classificationOrders,
        'transfer-order-card.classification-order'
      )}
      <LinkButton
        href={`/${window.objectDetailsSitemodelUuid}?uuid_objectclass=${window.uuidObjectclassTransferorder}&object-uuid=${uuid}`}
        variant='secondary'
      >
        {t('transfer-order-card.view')}
      </LinkButton>
    </CollapsibleCard>
  )
}
