import { useCallback, useEffect, useState } from 'react'

const useGeoLocation = () => {
  const [currentPosition, setCurrentPosition] =
    useState<google.maps.LatLngLiteral>()

  const handleGeolocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setCurrentPosition({ lat: latitude, lng: longitude })
        },
        (error) => {
          console.error('Error getting the location: ', error)
        }
      )
    }
  }, [])

  useEffect(handleGeolocation, [handleGeolocation])

  return {
    currentPosition,
    setCurrentPosition
  }
}

export default useGeoLocation
