import { Record, String, Optional, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type HeroBannerSecondaryContainerProps = {
  props: {
    des: string
    complement: string
    leftBackgroundImageUrl?: string
    rightBackgroundImageUrl?: string
    anchorIdentifier?: string
    styleMap?: StyleMap
  }
}

export const HeroBannerSecondaryContainerTypeGuard = Record({
  des: String,
  complement: String,
  leftBackgroundImageUrl: Optional(String),
  rightBackgroundImageUrl: Optional(String),
  anchorIdentifier: Optional(String),
  styleMap: Optional(Unknown)
})
