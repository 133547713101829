import { FilterQuery } from '@yes.technology/react-toolkit'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { GroupedFieldValues } from 'site/Renderer/components/FilterFields/types'
import useObjectFields from 'site/Renderer/components/ObjectView/hooks/useObjectFields'
import { useSiteState } from 'siteState/shared'
import { useFetchYesObjects } from 'yesObject/shared'

type UseReportDataProps = {
  filteractionUuid?: string
  enabled?: boolean
}

export default function useReportData({
  filteractionUuid,
  enabled
}: UseReportDataProps) {
  const [fieldValues] = useSiteState<GroupedFieldValues>('field-values')
  const [filterFields] = useSiteState<FilterFieldsResponse>('filter-fields')
  const [filterQuery] = useSiteState<FilterQuery>('filter-query')
  const [filterComposition] = useSiteState<string>('field-composition')
  const [objectclassUUID] = useSiteState<string>('objectclassuuid')

  const {
    yesObjects,
    isLoading: requestLoading
    // TODO: Deal with pagination
  } = useFetchYesObjects({
    filterBody: {
      query: filterQuery,
      objectclass: objectclassUUID
    },
    filteractionUuid,
    initialPagination: {
      limit: 500,
      offset: 0
    },
    enabled
  })

  const objectFields = useObjectFields({
    objectclassUuid: objectclassUUID
  })

  return {
    yesObjects,
    requestLoading,
    objectFields,
    fieldValues,
    filterFields,
    filterComposition
  }
}
