import { Routes, Route } from 'react-router-dom'

import { PrivateRoutes } from 'infrastructure/shared/components'
import WorkFlowEngineContainer from './Render/WorkflowEngineContainer'
import VisualizeTask from 'task/VisualizeTask'
import NotFoundPage from 'infrastructure/NotFoundPage'
import Create from './Create'

const SiteRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        {/* No support for optional parameters in v6 */}
        {/* https://reactrouter.com/docs/en/v6/faq#what-happened-to-regexp-routes-paths */}
        <Route index element={<Create />} />
        <Route path=':sitemodelUuid' element={<Create />} />
        <Route path='masterdata/:objectclassUuid' element={<Create />} />
        <Route
          path='/UUID_OBJECTCLASS_WORKFLOWMODEL/:uuid'
          element={<WorkFlowEngineContainer />}
        />
        <Route path='/tasks/:taskUUID' element={<VisualizeTask />} />

        <Route
          path=':applicationsystem/:subapplicationsystem/:sitemodelUuid'
          element={<Create />}
        />

        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default SiteRoutes
