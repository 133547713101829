import { TypeGuardProps } from './TypeGuard.types'

const TypeGuard = ({ props, type, children }: TypeGuardProps) => {
  if (!type || type.guard(props)) {
    return children
  }
  return null
}

export default TypeGuard
