import {
  FilterBody,
  Objectclass,
  YesObject,
  type Pagination
} from '@yes.technology/react-toolkit'
import apiAsync from 'infrastructure/shared/api/api-async'
import { PaginationResponse } from 'shared/types/pagination.type'

type FetchYesObjectsArgs = {
  filterBody: FilterBody
  showLoading?: boolean
}

export type FetchYesObjectsResponse = {
  metadata: Objectclass
  objects: YesObject[] | null
  pagination: Pagination
}

const formatPagination = (pagination: PaginationResponse): Pagination =>
  pagination && {
    limit: pagination.limit,
    offset: pagination.offset,
    total: pagination.total,
    hasPrevious: pagination.has_previous,
    hasNext: pagination.has_next
  }

async function fetchYesObjects({
  filterBody,
  showLoading = false
}: FetchYesObjectsArgs): Promise<FetchYesObjectsResponse> {
  const response = await apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: 'objects/_filter',
    method: 'POST',
    obj: filterBody,
    showLoading
  })

  return {
    metadata: response.data?.metadata,
    objects: response.data?.objects,
    pagination: formatPagination(response.data?.pagination)
  }
}

export default fetchYesObjects
