import Highcharts from 'highcharts'
import HighchartsSankey from 'highcharts/modules/sankey'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18n-lite'

import useChartEndpoint from 'site/shared/hooks/useChartEndpoint'

HighchartsSankey(Highcharts)

type Props = {
  endpoint: string
}
const ChartHigh = ({ endpoint }: Props) => {
  const { objects, loading, error } = useChartEndpoint({ endpoint })
  const { t } = useTranslation()

  const data = {
    chart: {
      height: 1000
    },
    title: {
      text: ' '
    },
    series: [
      {
        keys: ['from', 'to', 'weight'],
        data: objects,
        type: 'sankey',
        name: 'Rastreabilidade de Fluxos de Materiais'
      }
    ]
  }

  return (
    <div className='card m-3'>
      <div className='card-body'>
        {loading && <p>{t('hello-yes.loading')}</p>}
        {!loading && error && <p>{error}</p>}
        {!loading && !error && objects?.length > 0 && (
          <HighchartsReact
            style={{ height: '100vh' }}
            highcharts={Highcharts}
            options={data}
          />
        )}
      </div>
    </div>
  )
}

export default ChartHigh
