import { createContext, useContext } from 'react'
import { SitemodelContextValue } from 'sitemodel/shared/types'

export const SitemodelContext = createContext<
  SitemodelContextValue | undefined
>(undefined)

export default function useSitemodel(): SitemodelContextValue {
  const context = useContext(SitemodelContext)

  if (!context) {
    throw new Error(
      'useSitemodel must be used within a SitemodelContextProvider'
    )
  }

  return context
}
