import { DocumentListCard, Text } from '@yes.technology/react-toolkit'
import styled from 'styled-components'
import { ImageFormat } from './EventCard.type'

export const Card = styled(DocumentListCard)`
  height: 100%;
  h3 {
    margin-bottom: 8px;
  }
`

export const Container = styled.div<{ imageFormat?: ImageFormat }>`
  display: flex;
  gap: 16px;
  height: 100%;
`

export const Subtitle = styled(Text)`
  color: #006bff;
  line-height: 24px;
`

const getStylesByFormat = (format?: ImageFormat) => {
  if (format === 'vertical') {
    return `
      width: 192px;
      height: 272px;

      > img {
        height: 100%;
        object-fit: cover;
      }
    `
  }

  if (format === 'square') {
    return `
      width: 90px;

      > img {
        width: 90px;
      }


      @media (min-width: 768px) {
        width: 192px;

        > img {
          width: 192px;
        }
      }
    `
  }

  return `
    height: 162px;
    margin-top: 16px;
  `
}

export const ImageWrapper = styled.div<{ format?: ImageFormat }>`
  ${({ format }) => getStylesByFormat(format)}
`

export const TextContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
