import useTableContext from '../hooks/useTableContext'
import { NavigationSlider } from '@yes.technology/react-toolkit'

export type ColumnsNavigationProps = {
  hierarchyLevel?: 'level_1' | 'level_2' | 'level_3'
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
}

const ColumnsNavigation = ({
  hierarchyLevel = 'level_2',
  navigationSliderBackgroundColor,
  navigationSliderColor
}: ColumnsNavigationProps) => {
  const {
    currentColumnIndex,
    unpinnedColumns,
    displayNextColumns,
    displayPreviousColumns,
    pinnedColumns,
    columnsNames
  } = useTableContext()

  const handleAfterClick = (n: number) => {
    if (n - 1 > currentColumnIndex) {
      displayNextColumns()
    } else if (n - 1 < currentColumnIndex) {
      displayPreviousColumns()
    }
  }

  const totalColumns = (pinnedColumns?.length || 0) + unpinnedColumns.length
  const hideColumns = totalColumns - columnsNames.length

  if (!hideColumns) return null

  return (
    <NavigationSlider
      currentPosition={currentColumnIndex + 1}
      first={1}
      total={hideColumns + 1}
      hierarchyLevel={hierarchyLevel}
      textForCurrent='Colunas'
      afterClick={handleAfterClick}
      heightClass='small'
      backgroundColor={navigationSliderBackgroundColor}
      color={navigationSliderColor}
    />
  )
}

export default ColumnsNavigation
