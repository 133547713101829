import { useMemo } from 'react'
import { TableContextValue } from './useTableContext'

const useTableContextValue = ({
  idObjectClass,
  objectclass,
  fields,
  actionColumns,
  actionColumnsPosition,
  columnsNames,
  objects,
  refetchObjects,
  columnsLayouts,
  onSort,
  sortOptions,
  displayPreviousColumns,
  displayNextColumns,
  currentColumnIndex,
  unpinnedColumns,
  pagination,
  totalObjects,
  layoutClassificationUuid,
  hierarchyLevel,
  actionButton,
  selectedRows,
  setSelectedRows,
  mode,
  setMode,
  changedFieldValues,
  setChangedFieldValues,
  relationCounterpartField,
  reportDownloadTriggered,
  triggerReportDownload,
  reportUrl,
  objectclassQueryString,
  displayLayoutSelection,
  informational,
  embedded,
  navigationSliderBackgroundColor,
  navigationSliderColor,
  concatFields,
  pinnedColumns,
  hideRowActionButtons
}: TableContextValue): TableContextValue => {
  return useMemo(
    () => ({
      idObjectClass,
      objectclass,
      fields,
      actionColumns,
      actionColumnsPosition,
      columnsNames,
      objects,
      refetchObjects,
      columnsLayouts,
      onSort,
      sortOptions,
      displayPreviousColumns,
      displayNextColumns,
      currentColumnIndex,
      unpinnedColumns,
      pagination,
      totalObjects,
      layoutClassificationUuid,
      hierarchyLevel,
      actionButton,
      selectedRows,
      setSelectedRows,
      mode,
      setMode,
      changedFieldValues,
      setChangedFieldValues,
      relationCounterpartField,
      reportDownloadTriggered,
      triggerReportDownload,
      reportUrl,
      objectclassQueryString,
      displayLayoutSelection,
      informational,
      embedded,
      navigationSliderBackgroundColor,
      navigationSliderColor,
      concatFields,
      pinnedColumns,
      hideRowActionButtons
    }),
    [
      idObjectClass,
      objectclass,
      fields,
      actionColumns,
      actionColumnsPosition,
      columnsNames,
      objects,
      refetchObjects,
      columnsLayouts,
      onSort,
      sortOptions,
      displayPreviousColumns,
      displayNextColumns,
      currentColumnIndex,
      unpinnedColumns,
      pagination,
      totalObjects,
      layoutClassificationUuid,
      hierarchyLevel,
      actionButton,
      selectedRows,
      setSelectedRows,
      mode,
      setMode,
      changedFieldValues,
      setChangedFieldValues,
      relationCounterpartField,
      reportDownloadTriggered,
      triggerReportDownload,
      reportUrl,
      objectclassQueryString,
      displayLayoutSelection,
      informational,
      embedded,
      navigationSliderBackgroundColor,
      navigationSliderColor,
      concatFields,
      pinnedColumns,
      hideRowActionButtons
    ]
  )
}

export default useTableContextValue
