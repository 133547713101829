import {
  Button,
  Col,
  Grid,
  InformationCard,
  Row,
  Text
} from '@yes.technology/react-toolkit'
import { cloneElement, MouseEvent, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AuthenticationType } from 'authentication/shared/types'
import { useIsOpen } from 'shared/hooks'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { ProtectedActionButtonProps } from './ProtectedActionButton.types'
import { Content } from './ProtectedActionButton.styles'

const ProtectedActionButton = ({
  authenticatedRedirectUrl,
  signup,
  login,
  informationCard,
  children
}: ProtectedActionButtonProps) => {
  const { isOpen, open, close } = useIsOpen(false)

  const navigate = useNavigate()

  const { tokenType } = useSelector(
    (state: AuthenticationType) => state.authentication
  )

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()

    if (tokenType === 'private' && authenticatedRedirectUrl) {
      navigate(`${authenticatedRedirectUrl}`)
      return
    }

    open()
  }

  const handleSignup = () => {
    navigate(`${signup.redirectUrl}`)
  }

  const handleLogin = () => {
    navigate(`${login.redirectUrl}`)
  }

  const clonedChildren = cloneElement(children as ReactElement, {
    onClick: handleClick
  })

  return (
    <>
      {clonedChildren}

      <InformationCard title={''} isOpen={isOpen} close={close}>
        <Grid>
          <Row style={{ marginTop: '-30px' }}>
            <Col lg={12}>
              <Text as='h3' variant='label-required-04'>
                {informationCard.title}
              </Text>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col lg={12}>
              <Content>
                {<SafeHtmlContent html={informationCard.content} />}
              </Content>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Button onClick={handleSignup}>{signup.buttonLabel}</Button>
            </Col>
          </Row>
          <Row className='mt-3 mb-3'>
            <Col lg={12}>
              <Button onClick={handleLogin} variant='secondary'>
                {login.buttonLabel}
              </Button>
            </Col>
          </Row>
        </Grid>
      </InformationCard>
    </>
  )
}

export default ProtectedActionButton
