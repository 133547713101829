import { AreaLine } from '@yes.technology/react-toolkit'
import React from 'react'
import {
  Array,
  Boolean,
  Literal,
  Number,
  Optional,
  Record,
  String,
  Union,
  Unknown
} from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'
import { TopAreaLayoutType } from 'site/shared/types'

export type TopLinePlacementType = React.ComponentProps<
  typeof AreaLine
>['placement']

export type TopLinePropsType = {
  layout?: TopAreaLayoutType | string
  styleMap?: StyleMap
  placement?: TopLinePlacementType
}

const RepresentationType = Union(
  Literal('logo_area'),
  Literal('icon'),
  Literal('des'),
  Literal('action_button'),
  Literal('component'),
  Literal('link'),
  Literal('breadcrumbs'),
  Literal('navigation_menu'),
  Literal('management_dashboard')
)

const ResponsiveImageObjectTypeGuard = Record({
  desktop: Record({
    src: String,
    alt: String
  }),
  mobile: Record({
    src: String,
    alt: String
  })
})

const MenuType = Record({
  des: String,
  filteraction: String
})

const PositionType = Array(
  Record({
    order: Optional(Number),
    align: Optional(Union(Literal('left'), Literal('right'))),
    representation: RepresentationType,
    des: Optional(String),
    image: Optional(Union(String, ResponsiveImageObjectTypeGuard)),
    link: Optional(String),
    component: Optional(String),
    routes: Optional(
      Record({
        des: String,
        link: String
      })
    ),
    menus: Optional(Array(MenuType)),
    components: Optional(
      Array(
        Record({
          uuid: String,
          des: String,
          menus: Optional(Array(MenuType)),
          is_private: Optional(Boolean),
          align: Optional(Union(Literal('left'), Literal('right')))
        })
      )
    )
  })
)

export const TopLineTypeGuard = Record({
  layout: Union(
    Record({
      position_1: Optional(PositionType),
      position_2: Optional(PositionType),
      position_3: Optional(PositionType)
    }),
    String
  ),
  styleMap: Optional(Unknown),
  placement: Optional(
    Union(
      Literal('default'),
      Literal('fixed-top'),
      Literal('fixed-bottom'),
      Literal('sticky-top'),
      Literal('sticky-bottom')
    )
  )
})
