import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import { AuthenticationType } from 'authentication/shared/types'
import Iframe from 'shared/components/Iframe'
import useIframe from 'site/Render/hooks/useIframe'

type Params = {
  readonly objectclassUuid: string | undefined
  readonly urlMasterdata: string
  readonly filteraction: string | undefined
}

const Masterdata: React.FunctionComponent<Params> = ({
  objectclassUuid,
  urlMasterdata,
  filteraction
}): JSX.Element | null => {
  const token = useSelector(
    ({ authentication }: AuthenticationType) => authentication.token
  )

  // filteraction query string
  const filteractionQS = filteraction ? `&filteractionId=${filteraction}` : ''

  const queryString = `token=${token}&lang=pt-BR${filteractionQS}`
  const iframeSrc = `${urlMasterdata}list/${objectclassUuid}?${queryString}`

  const { iframeAttributes, receiveMessage } = useIframe({
    origin: urlMasterdata,
    src: iframeSrc
  })

  useEffect(() => {
    window.addEventListener('message', receiveMessage)
    return () => window.removeEventListener('message', receiveMessage)
  }, [receiveMessage])

  return iframeAttributes ? <Iframe {...iframeAttributes} /> : null
}

export default Masterdata
