import { RepresentationEnum } from 'site/shared/enums'

/**
 * @deprecated
 */
const layout = {
  position_1: [
    {
      representation: RepresentationEnum.LogoArea,
      image: 'https://my.image.png',
      des: 'my logo',
      link: '/home'
    }
  ],
  position_2: [
    {
      representation: RepresentationEnum.Des,
      des: 'my des'
    },
    {
      representation: RepresentationEnum.Link,
      des: 'my link',
      link: 'my-link'
    }
  ],
  position_3: [
    {
      representation: RepresentationEnum.Component,
      component: 'Login',
      des: 'Log in'
    }
  ]
}

export default layout
