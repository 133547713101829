import apiAsync from 'infrastructure/shared/api/api-async'

type UpdateStatusProps = {
  objectUuid: string
  objectclassUuid: string
  objectVersion: string
  destinationStatusCode: string
  showLoading: boolean
}

export default function updateStatus({
  objectUuid,
  objectclassUuid,
  objectVersion,
  destinationStatusCode,
  showLoading
}: UpdateStatusProps) {
  return apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: `objects/${objectUuid}`,
    method: 'PATCH',
    obj: {
      uuid_Objectclass: objectclassUuid,
      uuid_status: destinationStatusCode, // API expects `code` instead of `uuid`
      version: objectVersion
    },
    showLoading
  })
}
