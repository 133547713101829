import { useMap } from '@vis.gl/react-google-maps'
import { MapHandlerProps } from './MapContainer.types'
import { useCallback, useEffect } from 'react'
import { YesObject } from '@yes.technology/react-toolkit'
import { validateAndParseCoordinates } from './utils/address'

export const MapHandler = ({ markers, place }: MapHandlerProps) => {
  const map = useMap()

  const handleMarkers = useCallback(
    (markers: YesObject[], map: google.maps.Map) => {
      if (markers.length === 0) return
      const bounds = new google.maps.LatLngBounds()
      markers?.forEach((marker) => {
        const address = validateAndParseCoordinates(
          marker?.address?.[0] as YesObject
        )
        if (!address) return
        bounds.extend({
          lat: address.lat,
          lng: address.lng
        })
      })
      map.fitBounds(bounds)
      map.panToBounds(bounds)
      map.setCenter(bounds.getCenter())
    },
    []
  )

  const handlePlace = useCallback(
    (place: google.maps.places.PlaceResult | null, map: google.maps.Map) => {
      if (!place) return
      if (place.geometry?.viewport) {
        map.fitBounds(place.geometry?.viewport)
      }
    },
    []
  )

  useEffect(() => {
    if (!map) return
    handleMarkers(markers, map)
    handlePlace(place, map)
  }, [handleMarkers, handlePlace, map, markers, place])

  return null
}

export default MapHandler
