const defaultFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'UTC'
}

const formatDate = (
  date: string,
  formatOptions?: Intl.DateTimeFormatOptions
) => {
  const options = formatOptions || defaultFormatOptions
  return new Date(date)
    .toLocaleDateString('pt-BR', options)
    .replaceAll(', ', ' ')
}

export default formatDate
