import { useCallback } from 'react'
import useSitemodelState from '../useSitemodelState/useSitemodelState'
import {
  ComponentPropType,
  ComponentPropsType,
  SitemodelCallbacksType
} from 'sitemodel/shared/types/sitemodel.type'
import { merge } from 'lodash'

const useInterpolateProps = () => {
  const { sitemodelState } = useSitemodelState()

  const getProps = useCallback(
    ({
      props = {},
      props_params = {},
      callbacks = {}
    }: {
      props: ComponentPropsType
      callbacks: SitemodelCallbacksType
      props_params: ComponentPropsType
    }) => {
      const getIterpolatedProps = (
        prop: ComponentPropType
      ): ComponentPropType => {
        if (typeof prop === 'string') {
          return sitemodelState?.[prop]
        }

        if (Array.isArray(prop)) {
          return prop.map(getIterpolatedProps) as ComponentPropType
        }

        if (typeof prop === 'object') {
          const interpolatedProps: ComponentPropsType = {}

          Object.keys(prop).forEach((key) => {
            interpolatedProps[key] = getIterpolatedProps(prop[key])
          })

          return interpolatedProps
        }

        return {}
      }

      const interpolatedProps: ComponentPropsType = {}

      Object.keys(props_params).forEach((key) => {
        interpolatedProps[key] = getIterpolatedProps(props_params[key])
      })

      return { ...merge(props, interpolatedProps), ...callbacks }
    },
    [sitemodelState]
  )

  return { getProps }
}

export default useInterpolateProps
