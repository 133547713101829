import { RelationClass, YesObject } from '@yes.technology/react-toolkit'
import { TableContextValue } from 'site/Renderer/components/DataTables/hooks/useTableContext'

type GetRelationCounterpartFieldProps = {
  relationFieldRelation?: RelationClass
  fieldValues?: Pick<YesObject, 'uuid' | 'des'>
}

export default function getRelationCounterpartField({
  relationFieldRelation,
  fieldValues
}: GetRelationCounterpartFieldProps) {
  const relationClassesExtraData =
    relationFieldRelation?.object_class?.relation_classes

  const relationCounterpartColumn = Object.values(
    relationClassesExtraData || {}
  ).find(
    (obj) =>
      obj.uuid_relationclass === relationFieldRelation?.uuid_relationclass
  )?.column_json

  const relationCounterpartField: TableContextValue['relationCounterpartField'] =
    relationCounterpartColumn && fieldValues
      ? {
          [relationCounterpartColumn]: [
            {
              uuid: fieldValues.uuid,
              des: fieldValues.des
            }
          ]
        }
      : undefined

  return relationCounterpartField
}
