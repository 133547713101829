import {
  FilteractionObject,
  Pagination,
  ProjectFields,
  SortOption
} from '@yes.technology/react-toolkit'

import defaultFetchYesObjects, {
  FetchYesObjectsResponse
} from './fetchYesObjects'
import defaultFetchFilteractionFilterbody, {
  DEFAULT_ORDER_BY,
  DEFAULT_PAGINATION
} from 'filteraction/shared/api/fetchFilteractionFilterbody'

type PerformFetchArgs = {
  filteraction: FilteractionObject
  showLoading?: boolean
  pagination?: Pagination
  orderby?: SortOption[]
  skipRelations?: boolean
  skipMetadata?: boolean
  projectFields?: ProjectFields
  fetchFilteractionFilterbody?: typeof defaultFetchFilteractionFilterbody
  fetchYesObjects?: typeof defaultFetchYesObjects
}

export async function fetchYesObjectsWithFilteraction({
  filteraction,
  pagination = DEFAULT_PAGINATION,
  orderby = DEFAULT_ORDER_BY,
  showLoading = false,
  projectFields = undefined,
  skipRelations = undefined,
  skipMetadata = undefined,
  fetchYesObjects = defaultFetchYesObjects,
  fetchFilteractionFilterbody = defaultFetchFilteractionFilterbody
}: PerformFetchArgs): Promise<FetchYesObjectsResponse> {
  const filterBody = await fetchFilteractionFilterbody({
    filteraction,
    pagination,
    orderby,
    projectFields,
    skipRelations,
    skipMetadata
  })

  return fetchYesObjects({ filterBody, showLoading })
}
