import { MenuType, TopAreaPositionType } from 'site/shared/types/toparea.type'
import NavigationMenuArea from './NavigationMenuArea'
import Login from './Login/Login'
import LogoArea from './LogoArea'
import { useSelector } from 'react-redux'
import { AuthenticationType } from 'authentication/shared/types'

export interface Component {
  uuid: string
  des: string
  menu?: MenuType[]
  is_private?: boolean
  order?: string
  align?: 'left' | 'right'
}

type ManagementDashboardProps = Pick<TopAreaPositionType, 'components'> & {
  components?: Component[]
}

const componentsPaths: { [key: string]: React.ComponentType<any> } = {
  NavigationMenuArea,
  Login,
  LogoArea
}

const ManagementDashboard = ({ components = [] }: ManagementDashboardProps) => {
  const { tokenType } = useSelector(
    ({ authentication }: AuthenticationType) => authentication
  )

  const isPrivateToken = tokenType === 'private'

  return (
    <>
      {components?.map((component) => {
        if (
          (component.is_private && !isPrivateToken) ||
          !componentsPaths[component.des]
        )
          return null

        const Component = componentsPaths[component.des]
        return <Component key={component.uuid} {...component} />
      })}
    </>
  )
}

export default ManagementDashboard
