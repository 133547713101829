import { Operator, Operators } from '../../types'

type ModalOperatorProps = {
  operators: Operators[]
  handleChange?: (operator: Operator) => void
}

export default function ModalOperator({
  operators,
  handleChange
}: ModalOperatorProps) {
  return (
    <div className='list'>
      {operators.map((item, i) => (
        <button
          type='button'
          key={i}
          onClick={() => handleChange?.(item.operator)}
        >
          {item.des}
        </button>
      ))}
    </div>
  )
}
