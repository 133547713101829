import styled from 'styled-components'

export const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #006bff;
  border-radius: 50%;
`
export const AvatarText = styled.span`
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
`
