import styled, { css } from 'styled-components'

export const Container = styled.div<{ displayActions?: boolean }>`
  align-items: flex-end;
  gap: 4px;

  > :first-child {
    width: 100%;
  }

  ${({ displayActions }) =>
    displayActions &&
    css`
      display: flex;
    `};
`

export const Actions = styled.div`
  flex-basis: fit-content;
`
