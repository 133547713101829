import React, { useCallback, useMemo, useState } from 'react'
import { TabsContentProps } from './TabsContent.types'
import Tabs from 'component/Tabs'
import {
  Col,
  Grid,
  Row,
  Text,
  useIsMobile
} from '@yes.technology/react-toolkit'
import { StyledGrid } from 'shared/style/grid'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { TabSubtitle, TextContainer } from './TabsContent.styles'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

function TabsContent({ props }: TabsContentProps) {
  const { items } = props
  const [currentTab, setCurrenTab] = useState(Object.keys(items)[0])
  const isMobile = useIsMobile()

  const styleProps = useStyleMaps(props.styleMap)

  const tabs = useMemo(
    () =>
      Object.entries(items).map(([title, tab]) => ({
        uuid: tab.uuid,
        des: title
      })),
    [items]
  )

  const onTabSelected = useCallback((key: string) => {
    setCurrenTab(key)
  }, [])

  return (
    <StyledGrid style={styleProps}>
      <Row>
        <Col className='d-flex flex-column align-items-center'>
          <Tabs
            items={tabs}
            onItemSelect={onTabSelected}
            heightClass={isMobile ? 'semiLarge' : 'extraLarge'}
          />

          <TabSubtitle
            variant={isMobile ? 'content-emphasis-06' : 'content-emphasis-07'}
          >
            {items[currentTab].des}
          </TabSubtitle>

          <Grid>
            <Row className='justify-content-center'>
              <Col md={10}>
                <TextContainer>
                  <Text variant={isMobile ? 'content-03' : 'content-04'}>
                    <SafeHtmlContent html={items[currentTab].complement} />
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default TabsContent
