import { SkeletonDots } from '@yes.technology/react-toolkit'
import React from 'react'
import styled from 'styled-components'

const TableHeaderColumnBlock = ({
  children,
  ...thProps
}: React.HTMLAttributes<HTMLTableCellElement>) => (
  <th {...thProps}>
    <span>{children}</span>
  </th>
)

export const TableHeaderColumn = styled(TableHeaderColumnBlock)`
  white-space: nowrap;

  && {
    padding: 0.5rem 0;
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  > span {
    display: flex;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #abbed1;
  }
`

export const DataTableSkeletonDots = styled(SkeletonDots).attrs({ size: 3 })`
  height: 1em;
  align-items: flex-end;
  color: inherit;

  & > div {
    background-color: currentColor;
  }
`

export const AreaLineCenter = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }

  // avoid invisible double-arrow to take up space
  & :first-child > button:first-child,
  & :last-child > button:last-child {
    display: none;
  }
`
