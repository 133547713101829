import { Text } from '@yes.technology/react-toolkit'
import {
  OrderTableBody,
  OrderTableContainer,
  OrderTableSectionHeader,
  OrderTableValue
} from './OrderTable.styles'
import { Line } from './TransferOrderCard.styles'
import { useTranslation } from 'react-i18n-lite'
import { GenericOrder, ProductCombo } from './types'

type OrderTableProps = {
  title: string
  orders: GenericOrder[]
  productCombos: Record<string, ProductCombo>
}

type OrderTableItemProps = {
  order: GenericOrder
  productCombos: Record<string, ProductCombo>
}

function renderProductComboSummary(productCombo: ProductCombo) {
  return (
    `${productCombo.order || '-'} | ${productCombo.productmodel?.[0]?.des} | ` +
    ` ${productCombo.quantity} | ${productCombo.measurementunit?.[0]?.des}`
  )
}

function OrderTableItem({ order, productCombos }: OrderTableItemProps) {
  if (!order?.productcombo || typeof order.productcombo === 'string')
    return null

  return (
    <>
      {order.productcombo.map((product) => (
        <OrderTableValue key={product.uuid}>
          {productCombos[product.uuid] &&
            renderProductComboSummary(productCombos[product.uuid])}
        </OrderTableValue>
      ))}
    </>
  )
}

export default function OrderTable({
  title,
  orders,
  productCombos
}: OrderTableProps) {
  const { t } = useTranslation()

  return (
    <OrderTableContainer level={1} spacingClass='doubleXSmall'>
      <OrderTableSectionHeader>{title}</OrderTableSectionHeader>

      <Text variant='label-required-02'>
        {t('transfer-order-card.order-table-header')}
      </Text>

      <Line />

      <OrderTableBody>
        {orders?.map((order) => (
          <OrderTableItem
            key={order.uuid}
            order={order}
            productCombos={productCombos}
          />
        ))}
      </OrderTableBody>
    </OrderTableContainer>
  )
}
