import { NavigationSlider, Pagination } from '@yes.technology/react-toolkit'

type Props = {
  currentPosition: number
  pagination: Pagination
  onNavigating?: (nextObjectIndex: number) => void
}

const NavigationBetweenObjects = ({
  currentPosition,
  pagination,
  onNavigating
}: Props) => (
  <NavigationSlider
    backgroundColor='#004099'
    currentPosition={currentPosition + 1}
    first={1}
    total={1}
    showTotalPages={false}
    hasPreviousPage={!!pagination.hasPrevious}
    hasNextPage={!!pagination.hasNext}
    arrowOrientationButtons='diagonalArrows'
    hierarchyLevel='level_3'
    afterClick={onNavigating}
    heightClass='small'
    textForCurrent='Objeto'
  />
)

export default NavigationBetweenObjects
