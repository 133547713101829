import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-bottom: 1px;
  padding: 0 1rem;

  @media (min-width: 768px) and (max-width: 1920px) {
    padding: 0 3.75rem;
    max-width: 1920px;
  }

  @media (min-width: 1921px) {
    padding: 0;
    width: 1920px;
    margin: 0 auto;
  }
`
