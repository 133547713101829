import { Style } from '@react-pdf/types'

const mainCard: Style = {
  padding: 8
}

const mainContainer: Style = {
  padding: 8
}

export default function getStyles() {
  return {
    mainCard,
    mainContainer
  }
}
