import { SituationObject } from '@yes.technology/react-toolkit'

import fetchSituations from '../api/fetchSituations'
import { useQuery } from '@tanstack/react-query'

export default function useSituations() {
  const { data, error } = useQuery<SituationObject[] | null, Error>({
    queryKey: ['situations'],
    queryFn: () => fetchSituations(false)
  })

  if (error) console.log(error.message)

  return data || []
}
