import { useMemo } from 'react'
import { ObjectType, SitemodelContextValue } from 'sitemodel/shared/types'

type UseSitemodelContentProps = {
  sitemodelContent: string | ObjectType[] | undefined
}

const useSitemodelContent = ({
  sitemodelContent
}: UseSitemodelContentProps): SitemodelContextValue => {
  return useMemo(
    () =>
      sitemodelContent && typeof sitemodelContent === 'string'
        ? JSON.parse(sitemodelContent)
        : {},
    [sitemodelContent]
  )
}

export default useSitemodelContent
