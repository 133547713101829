import apiAsync from 'infrastructure/shared/api/api-async'
import { PostObjectResponse } from 'shared/types/object.type'

type PostObjectProps = {
  uuid_Objectclass: string
  uuid_status: string
  situation: string
  data: unknown
  showLoading?: boolean
}
export default function postObject({
  uuid_Objectclass,
  uuid_status,
  situation,
  data,
  showLoading
}: PostObjectProps): Promise<PostObjectResponse> {
  return apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: `objects`,
    method: 'POST',
    obj: {
      uuid_Objectclass,
      uuid_status,
      situation,
      ...(typeof data === 'object' ? data : {})
    },
    showLoading
  })
}
