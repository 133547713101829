import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import SupportBanner from './SupportBanner'
import { SupportBannerContainerProps } from './SupportBannerContainer.types'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

const SupportBannerContainer = ({ props }: SupportBannerContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)
  return (
    <SupportBanner
      des={<SafeHtmlContent html={props.des} />}
      button={{
        label: props.buttonLabel,
        href: props.buttonHref
      }}
      leftBackgroundImageUrl={props.leftBackgroundImageUrl}
      rightBackgroundImageUrl={props.rightBackgroundImageUrl}
      style={styleProps}
    />
  )
}

export default SupportBannerContainer
