import { useDocumentTitle } from 'infrastructure/shared/hooks'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AuthenticationType } from 'authentication/shared/types'
import Iframe from 'shared/components/Iframe'
import useIframe from 'site/Render/hooks/useIframe'
import useTaskTopAreaLayout from 'task/shared/hooks/useTaskTopAreaLayout'
import useYesObject from 'yesObject/shared/hooks/useYesObject'
import { Task, Taskmodel } from 'task/shared'

import { useUrlByActionHandler } from '..'
import TopLine from 'site/Renderer/components/TopLine'
import { useTranslation } from 'react-i18n-lite'

const VisualizeTask: React.FunctionComponent = (): JSX.Element => {
  const { t, language } = useTranslation()

  useDocumentTitle({ title: t('task-title') })

  const { taskUUID = '' } = useParams<string>()

  const { yesObject: task } = useYesObject<Task>({
    objectUUID: taskUUID,
    objectclassUUID: window.uuidObjectclassTask,
    showLoading: true
  })

  const topAreaLayout = useTaskTopAreaLayout({
    taskDes: task?.des
  })

  const token = useSelector(
    ({ authentication }: AuthenticationType) => authentication.token
  )

  const workflow = task?.workflow?.[0]

  const workflowExecutionState = JSON.parse(
    workflow?.workflow_execution_state || '{}'
  )
  const dataContainer = workflowExecutionState?.data_container
  const workflowTasks = workflowExecutionState?.state?.tasks || []

  const currentTask = workflowTasks.find((task: Task) => task.uuid === taskUUID)
  const taskmodelUUID = currentTask?.taskmodel_uuid

  const taskmodels: Taskmodel[] =
    workflowExecutionState?.workflowmodel_instructions_from_version?.taskmodels
  const taskmodel = taskmodels?.find(
    (taskmodel: Taskmodel) => taskmodel.uuid === taskmodelUUID
  )

  const workflowUUID = workflow?.uuid

  const iframePath = useUrlByActionHandler({
    taskmodel,
    workflowUUID,
    dataContainer
  })
  const iframeQueryString = `token=${token}&lang=${language}`
  const iframeUrl = `${window.waWorkflowengineUrl}${iframePath}?${iframeQueryString}}`

  const { iframeAttributes } = useIframe({
    origin: iframeUrl,
    src: iframeUrl
  })

  return (
    <>
      <TopLine props={{ layout: topAreaLayout }} />
      {taskUUID && iframeAttributes ? (
        <Iframe key={iframeAttributes.src} {...iframeAttributes} />
      ) : null}
    </>
  )
}

export default VisualizeTask
