import { TopAreaLayoutType, TopAreaPositionType } from 'site/shared/types'
import { RepresentationEnum } from 'site/shared/enums'
import { Component } from '../ManagementDashboard'
import { AreaLine, Icon, Text } from '@yes.technology/react-toolkit'
import { useIsOpen } from 'shared/hooks'
import { Container, Menu, IconButton } from './CollapsibleMenu.styles'
import useFetchMenus from '../useFetchMenus'
import { MenuType } from 'site/shared/types/toparea.type'
import LogoArea from '../LogoArea'
import AuthControl from './AuthControl'
import { useMemo, useState } from 'react'
import ActiveSession from '../Login/ActiveSession'
import { useSelector } from 'react-redux'
import { AuthenticationType } from 'authentication/shared/types'
import { useTranslation } from 'react-i18n-lite'
import MenuItem from './MenuItem'

type FlattenedComponentsType = {
  topAreaComponents: Record<string, TopAreaPositionType>
  genericComponents: Record<string, Component & { menus?: MenuType[] }>
}

type CollapsibleMenuProps = {
  layout: TopAreaLayoutType
}

const CollapsibleMenu = ({ layout }: CollapsibleMenuProps) => {
  const { isOpen, open, close } = useIsOpen(false)
  const [isActiveSessionOpen, setIsActiveSessionOpen] = useState(false)
  const { t } = useTranslation()

  const { token, tokenType } = useSelector(
    ({ authentication }: AuthenticationType) => authentication
  )

  const isLogged = useMemo(
    () => token && tokenType === 'private',
    [token, tokenType]
  )

  const flattenedComponents = useMemo(() => {
    const components: FlattenedComponentsType = {
      topAreaComponents: {},
      genericComponents: {}
    }

    Object.keys(layout).forEach((key) => {
      const position = layout[key as keyof TopAreaLayoutType]

      position?.forEach((component) => {
        if (
          component.representation === RepresentationEnum.ManagementDashboard
        ) {
          component.components?.forEach((dashboardComponent) => {
            if (
              !dashboardComponent.is_private ||
              (dashboardComponent.is_private && isLogged)
            )
              components.genericComponents[dashboardComponent.des] =
                dashboardComponent
          })
        } else {
          components.topAreaComponents[component.representation] = component
        }
      })
    })

    return components
  }, [layout, isLogged])

  const menus = useMemo(() => {
    const navigationMenu =
      flattenedComponents.topAreaComponents[RepresentationEnum.NavigationMenu]
    const navigationMenuArea =
      flattenedComponents.genericComponents['NavigationMenuArea']

    return [
      ...(navigationMenu?.menus || []),
      ...(navigationMenuArea?.menus || [])
    ]
  }, [flattenedComponents])

  const fetchedMenus = useFetchMenus({ menus })

  const logoArea =
    flattenedComponents.topAreaComponents[RepresentationEnum.LogoArea]

  const leftAreaElement = logoArea && <LogoArea {...logoArea} />

  const centerAreaElement = (
    <Text variant='content-emphasis-04'>
      {document.title || t('navigation-menu.navigation')}
    </Text>
  )

  const rightAreaElement =
    fetchedMenus?.length > 0 ? (
      <IconButton onClick={isOpen ? close : open}>
        <Icon
          iconName={isOpen ? 'CloseNew' : 'MenuOutline'}
          icColor='_006BFF'
        />
      </IconButton>
    ) : undefined

  return (
    <>
      <Container>
        <AreaLine
          className='navbar-expand-lg'
          leftAreaElement={leftAreaElement}
          centerAreaElement={centerAreaElement}
          rightAreaElement={rightAreaElement}
          alignment='center'
          colorScheme='white'
          heightClass='xSmall'
        />
      </Container>
      {isOpen &&
        fetchedMenus &&
        (isActiveSessionOpen ? (
          <ActiveSession
            goBack={() => {
              setIsActiveSessionOpen(false)
            }}
            {...{ close }}
          />
        ) : (
          <Menu>
            <AuthControl
              login={flattenedComponents.genericComponents['Login']}
              logoArea={flattenedComponents.genericComponents['LogoArea']}
              onAvatarClick={() => {
                setIsActiveSessionOpen(true)
              }}
            />
            {fetchedMenus.map((menu, index) => (
              <MenuItem {...menu} key={index} />
            ))}
          </Menu>
        ))}
    </>
  )
}

export default CollapsibleMenu
