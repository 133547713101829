import styled from 'styled-components'

export const MainContainer = styled.div<{ $reverse: boolean }>`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: ${(props) => (props.$reverse ? 'row-reverse' : 'row')};
    gap: 24px;
  }
`

export const ArticleTitle = styled.h2`
  color: ${({ theme }) => theme.colors.blackText};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-align: justify;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 48px; /* 150% */
  }
`

export const ArticleImage = styled.img`
  width: 100%;

  @media (min-width: 768px) {
    align-self: center;
    width: 432px;
  }
`

export const ContentContainer = styled.div`
  margin-top: 16px;
  font-family: Barlow;

  @media (min-width: 768px) {
    align-self: center;
  }
`

export const ArticleParapgrahs = styled.div``

export const ArticleContent = styled.div<{ $textColor?: string }>`
  color: ${({ theme, $textColor }) => $textColor || theme.colors.blackText};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: unset;
  margin-top: 16px;
  text-align: justify;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 24px;
  }
`

export const ArticleImageDocument = styled.div`
  width: 100%;

  > img {
    border-radius: 8px;
  }

  @media (min-width: 768px) {
    align-self: center;
    width: 432px;
  }
`
