import { Button } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const FilterButton = styled(Button)`
  width: 100%;

  @media (min-width: 767px) {
    max-width: 152px;
  }
`

export const FilterActionSetup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: end;
  margin-bottom: 16px;

  @media (min-width: 767px) {
    flex-direction: row;
  }
`
