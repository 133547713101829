import { Icon } from '@yes.technology/react-toolkit'

import { DataOrigin, FilterDate, StyledHierarchyCard } from '../Cockpit.styles'
import { useTranslation } from 'react-i18n-lite'

const AppliedFilters = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const firstDayOfYear = new Date(currentYear, 0, 1)
  const lastDayOfYear = new Date(currentYear, 11, 31)
  const today = new Date()
  return (
    <StyledHierarchyCard>
      <DataOrigin>
        <h1>{t('cockpit.filter.data-origin-title')}</h1>
        <FilterDate>
          <Icon iconName='FiltersFill' icSize='extraSmall' />
          <p>
            {t('cockpit.filter.reference-period')} ≥{' '}
            {firstDayOfYear.toLocaleDateString()}
          </p>
          <p>
            {t('cockpit.filter.reference-period')} ≤{' '}
            {lastDayOfYear.toLocaleDateString()}
          </p>
          <p>
            {t('cockpit.filter.reference-date')} = {today.toLocaleDateString()}
          </p>
        </FilterDate>
      </DataOrigin>
    </StyledHierarchyCard>
  )
}

export default AppliedFilters
