import {
  Row as ToolkitRow,
  Col as ToolkitCol,
  Grid as ToolkitGrid,
  BreakpointStart
} from '@yes.technology/react-toolkit'
import useStyleMaps, { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type GridProps = {
  props: {
    children: React.ReactNode
    styleMap?: StyleMap
  }
}

export const Grid = ({ props: { children, styleMap } }: GridProps) => {
  const styleProps = useStyleMaps(styleMap)
  return <ToolkitGrid {...{ children, style: styleProps }} />
}

export type RowProps = {
  props: {
    children: React.ReactNode
    styleMap?: StyleMap
  }
}

export const Row = ({ props: { children, styleMap } }: RowProps) => {
  const styleProps = useStyleMaps(styleMap)
  return <ToolkitRow {...{ children, style: styleProps }} />
}

type Breakpoint = keyof typeof BreakpointStart

export type ColProps = {
  props: {
    styleMap?: StyleMap
    children: React.ReactNode
  } & Record<Breakpoint, number | 'auto'> & {
      offset: Record<Breakpoint, number>
    }
}

export const Col = ({
  props: { xs, sm, md, lg, xl, offset, children, styleMap }
}: ColProps) => {
  const styleProps = useStyleMaps(styleMap)
  return (
    <ToolkitCol
      {...{ xs, sm, md, lg, xl, offset, children, style: styleProps }}
    />
  )
}
