import Iframe from 'shared/components/Iframe'
import useIframe from 'site/Render/hooks/useIframe'
import { AuthenticationProps } from './Authentication.types'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

type EventDataMessage = {
  token?: string
}

const Authentication = ({ props }: AuthenticationProps) => {
  const navigate = useNavigate()

  const styleProps = useStyleMaps(props.styleMap)

  const waAuthenticationUrl = window.waAuthenticationUrl
  const { iframeAttributes, receiveMessage } = useIframe({
    origin: waAuthenticationUrl,
    src: `${waAuthenticationUrl}`,
    title: 'Authentication'
  })

  const onReceivedMessage = useCallback(
    (event: MessageEvent) => {
      receiveMessage(event)

      const { token }: EventDataMessage = event.data

      if (token) {
        const redirectOriginURL = new URL(props.redirectOrigin)
        redirectOriginURL.searchParams.set('token', token)

        navigate({
          pathname: redirectOriginURL.pathname,
          search: redirectOriginURL.search
        })
      }
    },
    [navigate, receiveMessage]
  )

  useEffect(() => {
    window.addEventListener('message', onReceivedMessage)
    return () => window.removeEventListener('message', onReceivedMessage)
  }, [onReceivedMessage])

  if (!iframeAttributes) {
    return null
  }

  return <Iframe {...iframeAttributes} style={styleProps} />
}

export default Authentication
