import { Classification, Field } from '@yes.technology/react-toolkit'

import { findFieldByUUID } from 'classification/shared/utils/classifyFields'

interface ClassificationWithColumns extends Classification {
  columns?: string[]
}

const getClassifiedFields = (
  allFields: Field[],
  classificationContent?: string
) => {
  if (!classificationContent) return allFields

  if (!allFields.length) return []

  const classification: ClassificationWithColumns[] = JSON.parse(
    classificationContent
  )
  const classifiedFields: any[] = classification.map((el) => {
    const field = findFieldByUUID(allFields, el.UUID_OBJECT)
    if (!field) return null
    return {
      ...field,
      columns: el?.columns?.length ? el.columns : ['col-md-3']
    }
  })

  return classifiedFields
}

export default getClassifiedFields
