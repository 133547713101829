import { classifyFields } from 'classification/shared/utils'
import { Classification, Field } from '@yes.technology/react-toolkit'
import { useMemo } from 'react'
import { FilterSectionLayout } from 'site/Renderer/components/FilterFields/types'

export type UseClassifiedFieldsProps = {
  fields: Field[]
  classificationContent?: string
  sectionsLayout?: FilterSectionLayout[]
}

const useClassifiedFields = ({
  fields,
  classificationContent,
  sectionsLayout
}: UseClassifiedFieldsProps): Field[][] => {
  const classifiedFields = useMemo(() => {
    if (!fields.length) {
      return [[]]
    }

    if (sectionsLayout) {
      return sectionsLayout.map((sectionLayout) =>
        sectionLayout.filter_fields
          .map((fieldLayout) =>
            fields.find((field) => fieldLayout.field_uuid === field.uuid)
          )
          .filter(Boolean)
      ) as Field[][]
    }

    return classificationContent
      ? classifyFields(
          fields,
          JSON.parse(classificationContent) as Classification[]
        )
      : [fields]
  }, [classificationContent, fields, sectionsLayout])

  return classifiedFields
}

export default useClassifiedFields
