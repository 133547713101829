import { useSelector } from 'react-redux'

import { AuthenticationType } from 'authentication/shared/types'

const useAuth = () => {
  const { expiration_date: expirationDate, token } = useSelector(
    (state: AuthenticationType) => state?.authentication
  )

  return {
    expirationDate,
    token
  }
}

export default useAuth
