import styled from 'styled-components'

export const Container = styled.div`
  margin: 8px 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`

export const ButtonContainer = styled.div`
  flex: 1;
  max-width: 1105px;
`

export const ButtonList = styled.div`
  gap: 8px;
  display: grid;

  @media (min-width: 768px) {
    display: flex;
    gap: 16px;
  }
`
