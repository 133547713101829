import { useState } from 'react'
import ModalOperator from './ModalOperator'
import { useClickOutside } from 'shared/hooks'
import { Operator, OperatorsList } from '../../types'

type OperatorsProps = {
  currentOperator: string
  type?: 'string' | 'array'
  handleChange?: (operator: Operator) => void
}

export const operators: OperatorsList = {
  string: [
    { operator: 'eq', des: '=' },
    { operator: 'ne', des: '≠' },
    { operator: 'gt', des: '>' },
    { operator: 'gte', des: '>=' },
    { operator: 'lt', des: '<' },
    { operator: 'lte', des: '<=' },
    { operator: 'REGEX', des: 'RGX' }
  ],
  array: [
    { operator: 'in', des: '=' },
    { operator: 'nin', des: '≠' }
  ]
}

const Operators = ({
  currentOperator,
  type = 'string',
  handleChange
}: OperatorsProps) => {
  const [showModal, setShowModal] = useState(false)

  const onChange: OperatorsProps['handleChange'] = (operator) => {
    setShowModal(false)
    handleChange?.(operator)
  }

  const nodeRef = useClickOutside(() => {
    setShowModal(false)
  })

  return (
    <div ref={nodeRef} className='operator-container'>
      <button
        type='button'
        className='current'
        onClick={() => setShowModal(!showModal)}
      >
        {getOperatorDescription(operators, type, currentOperator)}
      </button>
      {showModal && (
        <ModalOperator operators={operators[type]} handleChange={onChange} />
      )}
    </div>
  )
}

export function getOperatorDescription(
  operators: OperatorsList,
  type: string,
  currentOperator: string
) {
  return (
    operators[type].find((item) => item.operator === currentOperator)?.des ||
    '='
  )
}

export default Operators
