import { locales as toolkitLocales } from '@yes.technology/react-toolkit'
import deepmerge from 'deepmerge'

import localePtBr from './pt-BR.json'
// import localeEnUs from './en-US.json'
// import localeDeDe from './de-DE.json'

export default {
  'pt-BR': deepmerge(toolkitLocales['pt-BR'], localePtBr)
  // 'en-US': deepmerge(toolkitLocales['en-US'], localeEnUs),
  // 'de-DE': deepmerge(toolkitLocales['de-DE'], localeDeDe)
}
