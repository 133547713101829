import {
  AutocompleteUniselection,
  FilteractionObject
} from '@yes.technology/react-toolkit'
import styled from 'styled-components'

type Filteraction = Pick<
  FilteractionObject,
  'uuid' | 'des' | 'query' | 'complement'
>

export type FilteractionSelectionProps = {
  value: string
  filteractions: Filteraction[]
  onSelectFilteraction: (filteraction?: Filteraction) => void
  setValue: (value: string) => void
}

const Container = styled.div`
  width: 100%;

  @media (min-width: 767px) {
    max-width: 672px;
  }
`

export default function FilteractionSelection({
  filteractions,
  value,
  setValue,
  onSelectFilteraction
}: FilteractionSelectionProps) {
  const sortedFilteractions = filteractions.sort(
    (a, b) => parseInt(a.complement) - parseInt(b.complement)
  )

  const options = sortedFilteractions.map(({ uuid, des }) => ({
    id: uuid,
    value: des
  }))

  const onChange = (newValue: string | { des: string; uuid: string }) => {
    const newValueString =
      typeof newValue === 'string' ? newValue : newValue.des
    setValue(newValueString)
    onSelectFilteraction(
      filteractions.find((filteraction) => filteraction.des === newValueString)
    )
  }

  return (
    <Container>
      <AutocompleteUniselection
        label='Ação de Filtragem'
        {...{ value, options, onChange }}
      />
    </Container>
  )
}
