import { useCallback } from 'react'
import { useNavigate, NavigateOptions, To, useLocation } from 'react-router-dom'

export default function useNavigateMergingState() {
  const navigate = useNavigate()
  const { state: locationState } = useLocation()

  const navigateMergingState = useCallback(
    (to: To, options: NavigateOptions) => {
      const { state } = options

      navigate(to, {
        ...options,
        state: {
          ...locationState,
          ...state
        }
      })
    },
    [locationState, navigate]
  )

  return navigateMergingState
}
