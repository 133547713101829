import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import {
  Field,
  PDFCard,
  PDFInformationalInput,
  YesObject
} from '@yes.technology/react-toolkit'
import getStyles from './styles'

interface SubCardProps {
  fields: Field[]
  object: YesObject
  title: string
}

function SubCard({ title, fields, object }: SubCardProps) {
  const styles = getStyles()
  return (
    <PDFCard style={styles.baseCard} noGutters>
      <View style={styles.innerContainer}>
        <Text style={styles.sectionTitle}>{title}</Text>
        <View style={styles.fieldSection}>
          {fields?.map((field) => (
            <View key={field.uuid} style={styles.fieldView}>
              <PDFInformationalInput
                heightClass='doubleXSmall'
                label={field.display_text.des}
                value={`${object?.[field?.column_json] || ''}`}
              />
            </View>
          ))}
        </View>
      </View>
    </PDFCard>
  )
}

export default SubCard
