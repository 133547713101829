import { YesObject } from '@yes.technology/react-toolkit'
import { Array, Optional, Record, String, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type ObjectsTypes = {
  uuid: string
  des: string
  complement: string
  document?: YesObject[]
  cor_representativa?: string
}

export type CardContainerProps = {
  props: {
    objects: ObjectsTypes[]
    link: string
    linkParams?: []
    justifyContent?: 'start' | 'center' | 'end'
    styleMap?: StyleMap
  }
}

const ObjectsTypeGuard = Record({
  uuid: String,
  des: String,
  complement: String
})

export const CardContainerTypeGuard = Record({
  objects: Array(ObjectsTypeGuard),
  link: String,
  linkParams: Optional(Array(String)),
  justifyContent: Optional(String),
  styleMap: Optional(Unknown)
})
