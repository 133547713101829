import { AuthenticationType } from 'authentication/shared/types'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '../Avatar/Avatar'
import { getInitials } from 'shared/utils/string/userInitials'
import jwtDecode from 'jwt-decode'
import { Component } from '../ManagementDashboard'
import LogoArea from '../LogoArea'
import { Button } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import styled from 'styled-components'
import { useLogout } from 'shared/hooks'

export const AuthControlContainer = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;

  > :nth-child(2) {
    flex: 0 50%;
  }
`

export const PrivateContainer = styled.div`
  display: flex;
  gap: 12px;
  flex: 1;
`

type AuthControlProps = {
  login?: Component
  logoArea?: Component
  onAvatarClick: () => void
}

const AuthControl = ({ login, logoArea, onAvatarClick }: AuthControlProps) => {
  const { t } = useTranslation()

  const { handleLogout } = useLogout()

  const { token, tokenType } = useSelector(
    ({ authentication }: AuthenticationType) => authentication
  )

  if (!login && !logoArea) {
    return null
  }

  const redirectToAuth = () => {
    if (window?.managementEnvironmentSitemodel) {
      window.location.assign(`/${window.managementEnvironmentSitemodel}`)
      return
    }

    const redirectOrigin = `redirectOrigin=${encodeURIComponent(window.location.href)}`
    const waAuthenticationUrl = window.waAuthenticationUrl

    window.location.assign(`${waAuthenticationUrl}login?${redirectOrigin}`)
  }

  const { name, username, email } =
    (token &&
      jwtDecode<{
        name?: string
        username?: string
        email?: string
      }>(token)) ||
    {}

  const user = name || username || email
  const isLogged = token && tokenType === 'private'

  return (
    <AuthControlContainer>
      {(isLogged || logoArea) && (
        <PrivateContainer>
          {login && isLogged && (
            <Avatar
              initials={getInitials(user || 'Yes')}
              title={user}
              onClick={onAvatarClick}
            />
          )}
          {logoArea && <LogoArea {...logoArea} />}
        </PrivateContainer>
      )}
      {login && (
        <Button
          heightClass='semiSmall'
          onClick={isLogged ? handleLogout : redirectToAuth}
        >
          {isLogged ? t('topline.logout') : t('login.button.des')}
        </Button>
      )}
    </AuthControlContainer>
  )
}

export default AuthControl
