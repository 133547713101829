import { useCallback } from 'react'

import { OrderbyType, AreasType } from 'site/shared/types'
import { ObjectType } from 'sitemodel/shared/types'
import { ComponentEnum } from 'site/shared/enums'
import Row from 'site/Render/components/DataArea/Row'
import Frame from 'site/Render/components/DataArea/Frame'
import ItemView from 'site/Render/components/DataArea/ItemView'
import YoutubeVideos from 'site/Render/components/DataArea/YoutubeVideos'
import { DataTable } from 'component/DataTable'

const useDataArea = () => {
  const configureLayout = useCallback(
    (
      sitemodels: ObjectType[],
      orderby: OrderbyType[] | undefined
    ): AreasType[] => {
      const areas: AreasType[] = []

      // As areas do layout serão montadas de acordo com a sequencia de layout configurados
      orderby?.forEach(({ component, align, field, sort, ...areaProps }) => {
        sitemodels.forEach((sitemodel) => {
          // O layout é montado de acordo com o field apontado na configuração: por exemplo: des ou qualquer relation
          if (!(field in sitemodel)) {
            return
          }

          const key = sitemodel[field as keyof ObjectType]

          /*
          Apenas uma configuração de layout.
          Por exemplo: [{ "component" : "Frame", "field" : "des"}]
          */
          if (typeof key === 'string' && orderby.length === 1) {
            areas.push({
              ...areaProps,
              component,
              align,
              field,
              sort,
              objects: [sitemodel]
            })
          }

          /*
          Indicado mais de uma (>1) configuração de layout.
          Por exmplo: [{ "component" : "Row", "field" : "Applicationarea"},{ "component" : "Frame", "field" : "des"}]

          Por padrão, os objetos devem ter uma relação.
          */
          if (Array.isArray(key) && orderby.length > 1) {
            for (const relation of key) {
              const index = areas.findIndex(
                ({ uuid }) => uuid === relation.uuid
              )
              if (index === -1) {
                areas.push({
                  ...areaProps,
                  ...relation,
                  component,
                  align,
                  field,
                  sort,
                  objects: [sitemodel]
                })
              } else {
                areas[index].objects.push(sitemodel)
              }
            }
          }

          // Aqui entrarão outras variações de layout
        })
      })

      return areas
    },
    []
  )

  const chooseComponent = useCallback(
    (
      isLoading: boolean | undefined,
      areas: AreasType[] | null | undefined,
      metadata: any
    ) => {
      return (
        !isLoading &&
        areas?.map((area: AreasType, index: number) => {
          switch (area.component) {
            case ComponentEnum.Row:
              return <Row key={index} {...area} />
            case ComponentEnum.Frame:
              return area.objects.map((objects: ObjectType) => {
                return (
                  <div key={objects.uuid} className='col-md-3'>
                    <Frame
                      {...objects}
                      uuidObjectClass={objects.uuid_Objectclass}
                    />
                  </div>
                )
              })
            case ComponentEnum.ItemView:
              return (
                <ItemView
                  key={index}
                  object={area.objects[0]}
                  metadata={metadata}
                />
              )
            case ComponentEnum.YoutubeVideos:
              return area.objects.map((objects: ObjectType) => {
                return <YoutubeVideos key={objects.uuid} {...objects} />
              })
            case ComponentEnum.DataTable:
              return (
                <DataTable
                  key={index}
                  objectclassUuid={area.uuid_objectclass}
                />
              )
            default:
              return null
          }
        })
      )
    },
    []
  )

  return { configureLayout, chooseComponent }
}

export default useDataArea
