import { PDFDocument } from 'pdf-lib'

export default async function mergePdfs(pdfBuffers: ArrayBuffer[]) {
  const [firstDocument, ...documentsToAppend] = pdfBuffers

  const pdfMerge = await PDFDocument.load(firstDocument)

  const documentsPagesToAppend = await Promise.all(
    documentsToAppend.map(async (document) => {
      const loadedDocument = await PDFDocument.load(document)

      return pdfMerge.copyPages(loadedDocument, loadedDocument.getPageIndices())
    })
  )

  documentsPagesToAppend.forEach((pagesToAppend) =>
    pagesToAppend.forEach((page) => pdfMerge.addPage(page))
  )

  return pdfMerge.save()
}
