import { Style } from '@react-pdf/types'

const mainContainer: Style = { width: '100%', flexDirection: 'row' }

const operatorBox: Style = {
  borderTopWidth: 2,
  borderColor: '#ABBED1',
  padding: 0,
  width: 20,
  height: 20,
  alignItems: 'center',
  justifyContent: 'center'
}

const operatorText: Style = {
  fontSize: 8,
  fontFamily: 'Barlow',
  fontWeight: 600,
  color: '#45586B'
}

const valuesBox: Style = {
  flexGrow: 1,
  borderLeftWidth: 2,
  borderTopWidth: 2,
  borderColor: '#ABBED1',
  paddingHorizontal: 8,
  paddingVertical: 4
}

const labelText: Style = {
  maxHeight: 24,
  marginBottom: 2,
  fontSize: 8,
  fontWeight: 600,
  fontFamily: 'Barlow',
  color: '#303032'
}

const valueText: Style = {
  height: 12,
  fontFamily: 'Barlow',
  fontSize: 8,
  fontWeight: 400,
  fontStyle: 'normal',
  color: '#303032'
}

export default function getStyles() {
  return {
    mainContainer,
    operatorBox,
    operatorText,
    valuesBox,
    labelText,
    valueText
  }
}
