import { useEffect } from 'react'
import { Objectclass } from '@yes.technology/react-toolkit'

import fetchObjectclass from '../api/fetchObjectclass'
import { useQuery } from '@tanstack/react-query'

export type UseObjectclassArgs = {
  objectclassUuid?: string | null | undefined
}

export default function useObjectclass({
  objectclassUuid
}: UseObjectclassArgs) {
  const { data, error, isLoading } = useQuery<Objectclass | null, Error>({
    queryKey: ['objectclass', objectclassUuid],
    queryFn: () => fetchObjectclass(objectclassUuid, false),
    enabled: !!objectclassUuid
  })

  useEffect(() => {
    if (error) console.log(error.message)
  }, [error])

  return {
    objectclass: data || undefined,
    isLoading
  }
}
