import { copyToClipboard } from 'shared/utils'
import useFormattedValue from '../../hooks/useFormattedValue'
import useTableContext from '../../hooks/useTableContext'
import { TableCellProps } from './TableCell.types'
import EditableCellInput from './EditableCellInput'

export default function TableCell({
  columnName,
  value,
  objectUuidOrIndex,
  dateOptions
}: TableCellProps) {
  const { columnsLayouts, fields, mode } = useTableContext()

  const { type } = fields?.[columnName] || {}

  const formattedValue = useFormattedValue({ value, type, dateOptions })

  const columnLayout = columnsLayouts.find(
    (layout) => layout.name === columnName
  )
  const textWrapping = columnLayout?.text_wrapping

  const copyValueToClipboard = () => {
    formattedValue && copyToClipboard(formattedValue)
  }

  if (mode !== 'view') {
    return (
      <td>
        <EditableCellInput
          {...{
            value,
            columnName,
            objectUuidOrIndex,
            formattedValue
          }}
        />
      </td>
    )
  }

  return (
    <td
      className={`${textWrapping ? 'text-wrapping' : ''}`}
      key={columnName}
      onDoubleClick={copyValueToClipboard}
    >
      {formattedValue}
    </td>
  )
}
