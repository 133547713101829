import { useMemo, useState } from 'react'

import { ColumnLayout } from '../types'
import { isNotIncludedIn } from 'shared/utils'

type UseColumnsParams = {
  columns: string[]
  columnsLayouts: ColumnLayout[]
}

export default function useColumns({
  columns,
  columnsLayouts = []
}: UseColumnsParams) {
  const [currentColumnIndex, setCurrentColumnIndex] = useState(0)

  const pinnedColumns = useMemo(
    () =>
      columnsLayouts
        .filter((column) => column.freeze)
        .map((column) => column.name),
    [columnsLayouts]
  )

  const unpinnedColumns = useMemo(() => {
    // TODO: E se usássemos as colunas completas ao invés dos nomes?
    const layoutColumnsNames = columnsLayouts.map((column) => column.name)

    const unpinnedLayoutColumns = layoutColumnsNames.filter(
      isNotIncludedIn(pinnedColumns)
    )
    const columnsNotInLayout = columns.filter(
      isNotIncludedIn(layoutColumnsNames)
    )

    return [...unpinnedLayoutColumns, ...columnsNotInLayout]
  }, [columns, columnsLayouts, pinnedColumns])

  const displayedColumns = useMemo(() => {
    const currentUnpinnedColumns = unpinnedColumns.slice(
      currentColumnIndex,
      currentColumnIndex + (columnsLayouts.length - pinnedColumns.length)
    )
    return [...pinnedColumns, ...currentUnpinnedColumns]
  }, [
    unpinnedColumns,
    currentColumnIndex,
    columnsLayouts.length,
    pinnedColumns
  ])

  const displayPreviousColumns = () => {
    if (currentColumnIndex > 0) {
      setCurrentColumnIndex((currentColumnIndex) => currentColumnIndex - 1)
    }
  }

  const displayNextColumns = () => {
    if (currentColumnIndex < unpinnedColumns.length - 2) {
      setCurrentColumnIndex((currentColumnIndex) => currentColumnIndex + 1)
    }
  }

  return {
    displayedColumns,
    displayPreviousColumns,
    displayNextColumns,
    currentColumnIndex,
    unpinnedColumns,
    pinnedColumns
  }
}
