import { ChartProps } from 'site/Renderer/components/Cockpit/Cockpit.types'

export interface Classification {
  uuid_object: string
  column?: string
  children: Classification[]
}

export function classifyCharts<T extends ChartProps, U extends Classification>(
  elements: T[],
  classifications: U[]
): Array<Array<T | undefined>> {
  return classifications.map(classifyChildren(elements))
}

function classifyChildren<T extends ChartProps, U extends Classification>(
  elements: T[]
) {
  return ({ children }: U): Array<T | undefined> => {
    if (!children) {
      return []
    }
    return children?.map((child) => {
      const element = findFieldByUUID(elements, child.uuid_object)
      if (element) {
        element.column = child.column
      }
      return element
    })
  }
}

export function findFieldByUUID<T extends ChartProps>(
  elements: T[],
  uuid: string
): T | undefined {
  return elements.find((element) => {
    let template
    try {
      template = JSON.parse(element.template)
    } catch (e) {
      return false
    }
    return template?.uuid_element === uuid
  })
}
