import { ApiSystemType } from 'infrastructure/Application/Container'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

type UseCardAnimation = {
  onMoveTask: (
    taskUuid: string,
    newStatusColumn: string,
    setIsFadeInAnimationStarted: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
  initialTaskParams?: {
    taskUuid: string
    newStatusColumn: string
  }
}

const useMoveTaskCardAnimation = ({
  onMoveTask,
  initialTaskParams = {
    taskUuid: '',
    newStatusColumn: ''
  }
}: UseCardAnimation) => {
  const cardAnimationContextRef =
    useRef<UseCardAnimation['initialTaskParams']>(initialTaskParams)

  const isLoading = useSelector((state: ApiSystemType) => state.api?.isPending)

  const [targetCardUuid, setTargetCardUuid] = useState('')

  const onStartTaskMove = (taskUuid: string, newStatusColumn: string) => {
    cardAnimationContextRef.current = { taskUuid, newStatusColumn }
  }

  const [isFadeOutAnimationStarted, setIsFadeOutAnimationStarted] =
    useState(false)

  const [isFadeInAnimationStarted, setIsFadeInAnimationStarted] =
    useState(false)

  const onControlMoveTask = useCallback(
    (taskUuid: string, newStatusColumn: string) => {
      setTargetCardUuid(taskUuid)

      setIsFadeOutAnimationStarted(true)

      debounce(() => {
        setIsFadeOutAnimationStarted(false)
        onMoveTask(taskUuid, newStatusColumn, setIsFadeInAnimationStarted)
      }, 800)()
    },
    [onMoveTask]
  )

  useEffect(() => {
    if (!isLoading && cardAnimationContextRef.current?.taskUuid) {
      const { taskUuid, newStatusColumn } = cardAnimationContextRef.current

      onControlMoveTask(taskUuid, newStatusColumn)

      cardAnimationContextRef.current = { taskUuid: '', newStatusColumn: '' }
    }
  }, [onControlMoveTask, isLoading])

  return {
    isFadeOutAnimationStarted,
    isFadeInAnimationStarted,
    targetCardUuid,
    onStartTaskMove
  }
}

export default useMoveTaskCardAnimation
