import React from 'react'
import { CenterText, MainContainer, SliderButton } from './styles'
import { NavigationSliderSecondaryProps } from './NavigationSliderSecondary.types'
import { useTranslation } from 'react-i18n-lite'
import { useIsMobile, Icon } from '@yes.technology/react-toolkit'

function NavigationSliderSecondary({
  options,
  currentIndex,
  onNextClick,
  onPrevClick,
  disabled,
  disabledArrow
}: NavigationSliderSecondaryProps) {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const currentOption = options[currentIndex]

  const isArrowDisabled = (arrow: 'prev' | 'next') => {
    return disabled || disabledArrow === arrow
  }

  if (!currentOption) {
    return null
  }

  const { value, color } = currentOption
  const previousLabel = `${value} - ${t('navigation-slider.previous')}`
  const nextLabel = `${value} - ${t('navigation-slider.next')}`

  return (
    <MainContainer>
      <SliderButton
        onClick={onPrevClick}
        type='button'
        aria-label={previousLabel}
        disabled={isArrowDisabled('prev')}
      >
        <Icon
          iconName={
            isArrowDisabled('prev')
              ? 'NavigationLeftDisabled'
              : 'NavigationLeft'
          }
          icSize={isMobile ? 'semiLarge' : 'extraLarge'}
          icColor='blackText'
          // TODO: Remove this when navigation button is created
          style={{ padding: isArrowDisabled('prev') ? '4px' : undefined }}
        />
      </SliderButton>

      <CenterText $color={color}>{value}</CenterText>

      <SliderButton
        onClick={onNextClick}
        type='button'
        aria-label={nextLabel}
        disabled={isArrowDisabled('next')}
      >
        <Icon
          iconName={
            isArrowDisabled('next')
              ? 'NavigationRightDisabled'
              : 'NavigationRight'
          }
          icSize={isMobile ? 'semiLarge' : 'extraLarge'}
          icColor='blackText'
          // TODO: Remove this when navigation button is created
          style={{ padding: isArrowDisabled('next') ? '4px' : undefined }}
        />
      </SliderButton>
    </MainContainer>
  )
}

export default NavigationSliderSecondary
