import {
  ArrayFieldValue,
  FieldValues
} from 'site/Renderer/components/FilterFields/types'

import { InterdependentFilteringConfig } from 'site/Renderer/components/FilterFields/types/layout'

export type EvaluateFieldDependencyProps = {
  fieldName: string
  fieldValues: FieldValues
  interdependentFilteringConfig?: InterdependentFilteringConfig
}

export default function evaluateFieldDependency({
  interdependentFilteringConfig,
  fieldName,
  fieldValues
}: EvaluateFieldDependencyProps) {
  const fieldConfig = interdependentFilteringConfig?.[fieldName]

  let disabled = false
  let interdependentFilteringQuery = {}
  const dependencyFieldName = fieldConfig?.depends_on
  const dependency = dependencyFieldName
    ? fieldValues[dependencyFieldName]
    : undefined

  const dependantFieldName = interdependentFilteringConfig
    ? Object.keys(interdependentFilteringConfig).find(
        (key) => interdependentFilteringConfig[key].depends_on === fieldName
      )
    : undefined
  const dependant = dependantFieldName
    ? fieldValues[dependantFieldName]
    : undefined

  if (fieldConfig && dependency) {
    disabled = !!(
      fieldConfig.disable_on_unfulfilled_dependency && !dependency.value.length
    )

    if (typeof dependency.value === 'string') {
      interdependentFilteringQuery = {
        [`${fieldConfig.depends_on}.des`]: {
          $regex: dependency.value
        }
      }
    }

    if (Array.isArray(dependency.value)) {
      interdependentFilteringQuery = {
        [`${fieldConfig.depends_on}.uuid`]: {
          $in: (dependency.value as ArrayFieldValue).map(({ uuid }) => uuid)
        }
      }
    }
  }

  return {
    dependencyFieldName,
    dependency,
    dependantFieldName,
    dependant,
    disabled,
    interdependentFilteringQuery
  }
}
