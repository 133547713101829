import { Style } from '@react-pdf/types'

const mainCard: Style = {
  marginHorizontal: 8,
  marginTop: 4,
  width: undefined,
  paddingBottom: 4
}

export default function getStyles() {
  return {
    mainCard
  }
}
