import { useSelector } from 'react-redux'
import { Button } from '@yes.technology/react-toolkit'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import jwtDecode from 'jwt-decode'

import { AuthenticationType } from 'authentication/shared/types'
import Avatar from '../Avatar/Avatar'
import { getInitials } from 'shared/utils/string/userInitials'

import {
  ActiveSessionCard,
  ActiveSessionCardContainer,
  LoginButtonContainer,
  LoginContainer
} from './styles'
import ActiveSession from './ActiveSession'

const Login: React.FunctionComponent = (): JSX.Element | null => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const outerRef = useRef<HTMLDivElement>(null)
  const btnRef = useRef<HTMLButtonElement>(null)

  const { token, tokenType } = useSelector(
    ({ authentication }: AuthenticationType) => authentication
  )

  const encodeHref = encodeURIComponent(window.location.href)
  const redirectOrigin = `redirectOrigin=${encodeHref}`
  const waAuthenticationUrl = window.waAuthenticationUrl

  const handleActiveSession = () => {
    setIsOpen(!isOpen)
  }

  const handleLogin = () => {
    if (window?.managementEnvironmentSitemodel) {
      window.location.assign(`/${window.managementEnvironmentSitemodel}`)
    } else {
      window.location.assign(`${waAuthenticationUrl}login?${redirectOrigin}`)
    }
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (btnRef.current && btnRef.current.contains(event.target as Node)) {
        return
      }

      if (
        outerRef.current &&
        !outerRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        setIsOpen(false)
      }
    },
    [outerRef, btnRef, isOpen]
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => window.removeEventListener('click', handleClickOutside, true)
  }, [handleClickOutside])

  // user initials
  const decoded =
    (token &&
      jwtDecode<{
        name?: string
        username?: string
        email?: string
      }>(token)) ||
    {}

  const user = decoded?.name || decoded?.username || decoded?.email

  return (
    <LoginContainer>
      {token && tokenType === 'private' && (
        <>
          <Avatar
            initials={getInitials(user || 'Yes')}
            title={user || undefined}
            onClick={handleActiveSession}
            ref={btnRef}
          />
        </>
      )}

      {(!token || tokenType === 'public') && (
        <LoginButtonContainer>
          <Button heightClass='semiSmall' onClick={handleLogin}>
            {t('login.button.des')}
          </Button>
        </LoginButtonContainer>
      )}

      {token && (
        <ActiveSessionCardContainer
          ref={outerRef}
          {...{ isOpen }}
          data-testid='active-session-card'
        >
          <ActiveSessionCard>
            <ActiveSession close={() => setIsOpen(false)} />
          </ActiveSessionCard>
        </ActiveSessionCardContainer>
      )}
    </LoginContainer>
  )
}

export default Login
