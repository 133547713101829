import { useEffect } from 'react'
import { FilteractionObject } from '@yes.technology/react-toolkit'

import fetchFilteractions from '../api/fetchFilteractions'
import { useQuery } from '@tanstack/react-query'

type UseObjectClassFilteractionsArgs = {
  objectclassUuid?: string
}

export default function useObjectClassFilteractions({
  objectclassUuid
}: UseObjectClassFilteractionsArgs) {
  const { data, error } = useQuery<FilteractionObject[] | null, Error>({
    queryKey: ['object-class-filteractions', objectclassUuid],
    queryFn: () => {
      return fetchFilteractions(objectclassUuid, false)
    },
    enabled: !!objectclassUuid
  })

  useEffect(() => {
    if (error) console.log(error.message)
  }, [error])

  return data || []
}
