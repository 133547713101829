import {
  Icon,
  LinkButton,
  SkeletonDots,
  Text,
  useIsMobile
} from '@yes.technology/react-toolkit'

import {
  Container,
  NavigationSliders,
  Navigation,
  NavigationWrapper,
  Content,
  ContentMiddle,
  BulletPoints,
  Complement,
  Image,
  ImageWrapper,
  ContentText,
  RightText
} from './DynamicInfoCardHighlight.styles'
import { DynamicInfoCardHighlightProps } from './DynamicInforCardHighlight.types'
import NavigationSliderSecondary from 'component/NavigationSliderSecondary'

export default function DynamicInfoCardHighlight({
  navigationHeading,
  navigationSliderLabels,
  des,
  image,
  bulletPoints,
  text,
  complement,
  callToAction,
  isLoading = false,
  showNavigationSliders = true,
  onClickNavigationSlider,
  mainNavigationColor = '#6F7AA7',
  secondaryNavigationColor = '#FBC521',
  buttonPosition = 'bottom',
  complementPosition = 'bottom',
  secondaryNavigationDisabled
}: DynamicInfoCardHighlightProps) {
  const [mainNavigationLabel, secondaryNavigationLabel] = navigationSliderLabels
  const isMobile = useIsMobile()

  const button = (
    <LinkButton
      href={callToAction.href}
      disabled={isLoading}
      heightClass={`${isMobile ? 'semiLarge' : 'large'}`}
    >
      {!isLoading ? callToAction.label : <SkeletonDots />}
    </LinkButton>
  )

  return (
    <Container>
      {showNavigationSliders && (
        <Navigation>
          <NavigationWrapper>
            <Text variant='content-emphasis-06' as='h2'>
              {navigationHeading}
            </Text>
            <NavigationSliders>
              <NavigationSliderSecondary
                currentIndex={0}
                options={[
                  {
                    value: mainNavigationLabel,
                    color: mainNavigationColor
                  }
                ]}
                onPrevClick={() => onClickNavigationSlider('main-previous')}
                onNextClick={() => onClickNavigationSlider('main-next')}
              />
              {secondaryNavigationLabel && (
                <NavigationSliderSecondary
                  currentIndex={0}
                  options={[
                    {
                      value: secondaryNavigationLabel,
                      color: secondaryNavigationColor
                    }
                  ]}
                  onPrevClick={() =>
                    onClickNavigationSlider('secondary-previous')
                  }
                  onNextClick={() => onClickNavigationSlider('secondary-next')}
                  disabled={secondaryNavigationDisabled}
                />
              )}
            </NavigationSliders>
          </NavigationWrapper>
        </Navigation>
      )}
      <Content>
        {isLoading && <SkeletonDots />}
        {!isLoading && (
          <>
            <h2>{des}</h2>

            {complementPosition === 'top' && !isMobile && complement && (
              <Complement>{complement}</Complement>
            )}

            <ContentMiddle>
              <ImageWrapper>
                <Image>
                  <img {...image} />
                </Image>
              </ImageWrapper>

              {complementPosition === 'top' && isMobile && complement && (
                <Complement>{complement}</Complement>
              )}

              <RightText>
                {bulletPoints && (
                  <BulletPoints>
                    {bulletPoints.map((bulletPoint) => (
                      <li key={bulletPoint}>
                        <Icon iconName='Success' icColor='primary' />
                        <ContentText>{bulletPoint}</ContentText>
                      </li>
                    ))}
                  </BulletPoints>
                )}

                {text && <ContentText>{text}</ContentText>}

                {buttonPosition === 'right' && !isMobile && button}
              </RightText>
            </ContentMiddle>

            {complementPosition === 'bottom' && complement && (
              <Complement>{complement}</Complement>
            )}
          </>
        )}

        {(buttonPosition === 'bottom' || isMobile) && button}
      </Content>
    </Container>
  )
}
