import useYesObject from 'yesObject/shared/hooks/useYesObject'
import { Classification, classifyCharts } from '../utils/classifyChartElements'
import { UseClassifiedChartsProps } from '../Cockpit.types'

const useClassifiedCharts = ({
  charts,
  classificationUuid
}: UseClassifiedChartsProps) => {
  const { yesObject: classificationObject, isLoading } = useYesObject({
    objectUUID: classificationUuid,
    objectclassUUID: window.classificationObjectclassUuid,
    skipRelations: false
  })

  const classificationContent =
    classificationObject?.classification_content as string

  if (!charts)
    return {
      isLoading,
      classifiedCharts: [[]]
    }

  const classifiedCharts = classificationContent
    ? classifyCharts(
        charts,
        JSON.parse(classificationContent) as Classification[]
      )
    : [charts]

  return {
    isLoading,
    classifiedCharts
  }
}

export default useClassifiedCharts
