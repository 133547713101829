import {
  Button,
  GenericObjectCard as ToolkitGenObjCard,
  YesObject
} from '@yes.technology/react-toolkit'
import { Link } from 'react-router-dom'

import ImageDocument from 'component/ImageDocument/ImageDocument'
import { ActionButtonType } from './GenericObjectCard.types'

export type GenericObjectCardProps = Pick<YesObject, 'des' | 'complement'> &
  Partial<Pick<YesObject, 'sitemodel_content' | 'Document'>>

const GenericObjectCard = ({
  des,
  complement,
  sitemodel_content: sitemodelContent,
  Document: image
}: GenericObjectCardProps) => {
  const actionButtons =
    typeof sitemodelContent === 'string'
      ? getActionButtons(sitemodelContent)
      : null
  const defaultButton = getButtonByType(actionButtons, 'default')
  const workflowmodelButton = getButtonByType(actionButtons, 'workflowmodel')
  const helptexttButton = getButtonByType(actionButtons, 'helptext')

  const imageForwardProp = image && <ImageDocument documents={image} />
  const buttonForwardProp = defaultButton ? (
    <Link
      to={`/${defaultButton.action}`}
      style={{ width: '100%', textDecoration: 'none' }}
    >
      <Button
        style={{ fontSize: '12px' }}
        heightClass='semiSmall'
        variant='secondary'
      >
        {defaultButton.label}
      </Button>
    </Link>
  ) : undefined

  return (
    <ToolkitGenObjCard
      des={des}
      complement={complement}
      image={imageForwardProp}
      defaultButton={buttonForwardProp}
      fullHeight
    >
      {workflowmodelButton && (
        <Link
          to={`/UUID_OBJECTCLASS_WORKFLOWMODEL/${workflowmodelButton.action}`}
          style={{ width: '100%', textDecoration: 'none' }}
        >
          <Button
            style={{ fontSize: '10px' }}
            heightClass='small'
            variant='primary'
          >
            {workflowmodelButton.label}
          </Button>
        </Link>
      )}
      {helptexttButton && (
        <Link
          to={`/${helptexttButton.action}`}
          style={{ width: '100%', textDecoration: 'none' }}
        >
          <Button
            style={{ fontSize: '10px' }}
            heightClass='small'
            variant='secondary'
          >
            {helptexttButton.label}
          </Button>
        </Link>
      )}
    </ToolkitGenObjCard>
  )
}

const getActionButtons = (jsonString: string) => {
  const json = JSON.parse(jsonString)
  return json.action_buttons
}

const getButtonByType = (
  actionButtons: ActionButtonType[] | undefined,
  type: string
): ActionButtonType | null => {
  if (!actionButtons) return null
  const [first] = actionButtons?.filter((button) => button.type === type)
  return first || null
}

export default GenericObjectCard
