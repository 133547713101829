import { useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18n-lite'
import useFetchYesObjects from './useFetchYesObjects'
import {
  ProjectFields,
  YesObject,
  feedbackcard
} from '@yes.technology/react-toolkit'

type UseFetchYesObjectsArgs = {
  objectUUID: string
  objectclassUUID: string
  showLoading?: boolean
  skipRelations?: boolean
  skipMetadata?: boolean
  projectFields?: ProjectFields
  displayMessageOnNoItemsFound?: boolean
  enabled?: boolean
}

export default function useYesObject<YesObjectType extends YesObject>({
  objectUUID,
  objectclassUUID,
  showLoading = false,
  skipRelations = false,
  skipMetadata = false,
  projectFields,
  displayMessageOnNoItemsFound = true,
  enabled = true
}: UseFetchYesObjectsArgs) {
  const { t } = useTranslation()

  const filterBody = useMemo(
    () => ({
      query: {
        $and: [
          {
            'uuid.des': {
              $eq: objectUUID
            }
          }
        ]
      },
      objectclass: objectclassUUID,
      skip_relations: skipRelations,
      skip_metadata: skipMetadata,
      project_fields: projectFields
    }),
    [objectUUID, objectclassUUID, projectFields, skipMetadata, skipRelations]
  )

  const {
    yesObjects,
    isLoading,
    fetchYesObjects,
    pagination,
    isFetched,
    isFetching
  } = useFetchYesObjects({
    filterBody,
    showLoading,
    enabled: enabled && !!objectclassUUID && !!objectUUID,
    initialPagination: { limit: 1, offset: 0 }
  })

  useEffect(() => {
    if (isFetched && !yesObjects.length && displayMessageOnNoItemsFound) {
      feedbackcard(t('error-message.object-not-found'), { type: 'error' })
    }
  }, [isFetched, yesObjects, displayMessageOnNoItemsFound, t])

  return {
    yesObject: yesObjects[0] as YesObjectType | undefined,
    isLoading,
    isFetching,
    pagination,
    fetchObjects: fetchYesObjects
  }
}
