import { render, screen } from '@testing-library/react'
// import userEvent from '@testing-library/user-event'

// redux
import configureMockStore from 'redux-mock-store'
import { Provider } from 'react-redux'

import Login from 'site/Render/components/TopArea/Login'

const mockStore = configureMockStore([])

it('Login component with authentication token', async () => {
  const initialState = {
    authentication: {
      token: '123456'
    }
  }

  const store = mockStore(initialState)

  /*
  const redux = { useDispatch }
  const useDispatchSpy = jest.spyOn(redux, 'useDispatch')
  const mockDispatchFn = jest.fn()
  useDispatchSpy.mockReturnValue(mockDispatchFn)
  */

  render(
    <Provider store={store}>
      <Login />
    </Provider>
  )

  // const user = userEvent.setup()

  screen.getByRole('button', { name: /configure/i })
  screen.getByRole('button', { name: /logout/i })

  // await user.click(logout)
  // expect(dispatch).toHaveBeenCalledWith({ type: 'login/LOGOUT' })

  // useDispatchSpy.mockClear()
})

it('Login component without authentication token', async () => {
  const initialState = {
    authentication: {
      token: ''
    }
  }

  const store = mockStore(initialState)

  render(
    <Provider store={store}>
      <Login />
    </Provider>
  )

  screen.getByRole('button', { name: /login/i })
})
