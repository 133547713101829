import { EnvironmentUrls } from '../types'

const requiredUrls: (keyof EnvironmentUrls)[] = [
  'msLogin',
  'msAuth',
  'msPlatform',
  'objectclass'
]

export default function validateConfigUrls(configUrls: EnvironmentUrls) {
  requiredUrls.forEach((requiredUrl) => {
    if (!configUrls[requiredUrl]) {
      window.alert('Variaveis de ambiente não configuradas')
      console.log('Url: ' + requiredUrl + ' não configurada')
    }
  })

  if (window.waEnv === 'dev') {
    Object.keys(configUrls).forEach((environmentUrl) => {
      if (!requiredUrls.includes(environmentUrl as keyof EnvironmentUrls)) {
        window.alert('Variaveis de ambiente não configuradas')
        console.log('Url: ' + environmentUrl + ' não configurada')
      }
    })
  }
}
