import { FilterFieldLayout } from '../../types'
import styled from 'styled-components'

export const StyledField = styled.div<{ fieldLayout?: FilterFieldLayout }>`
  @media (min-width: 768px) {
    flex-basis: ${({ fieldLayout }) => fieldLayout?.width || '50%'};
    flex-grow: ${({ fieldLayout }) => (fieldLayout?.width ? 0 : 1)};
  }

  @media (min-width: 992px) {
    flex-basis: ${({ fieldLayout }) => fieldLayout?.width || '33.3333%'};
  }

  @media (min-width: 1200px) {
    flex-basis: ${({ fieldLayout }) => fieldLayout?.width || '25%'};
  }
`
