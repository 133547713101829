import { HierarchyCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const StyledHierarchyCard = styled(HierarchyCard)`
  position: relative;

  > div:first-child {
    padding: 42px 16px 16px 16px;
  }

  @media (min-width: 768px) {
    > div:first-child {
      padding: 64px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;

  @media (min-width: 768px) {
    right: 32px;
    top: 32px;
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
  }
`

export const Title = styled.h2`
  color: #303032;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0px 0px 8px 0px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
    margin: 0px 0px 36px 0px;
  }
`

export const FullText = styled.div`
  text-align: justify;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    gap: 26px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`
