import { ObjectType } from 'sitemodel/shared/types'
import Frame from './Frame'

type Props = {
  des?: string
  complement?: string
  objects: ObjectType[]
}

const Row = ({ des, complement, objects }: Props) => {
  return (
    <div className='col-md-12 mb-3'>
      <div className='card shadow-sm' style={{ borderColor: '#eeeff1' }}>
        <div className='card-body'>
          <h5 className='card-title'>{des}</h5>
          <h6 className='card-subtitle mb-2 text-muted'>{complement}</h6>
          {objects?.length > 0 && (
            <div className='row'>
              {objects?.map((object: ObjectType) => {
                return (
                  <div key={object.uuid} className='col-md-3'>
                    <Frame {...object} />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Row
