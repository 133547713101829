import {
  FilterQuery,
  HierarchyCard,
  Pagination,
  SkeletonDots,
  SortOption,
  YesObject
} from '@yes.technology/react-toolkit'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useYesObject from 'yesObject/shared/hooks/useYesObject'
import Fields from './components/Fields/Fields'
import NavigationBetweenObjects from './components/NavigationBetweenObjects/NavigationBetweenObjects'
import RelationClasses from './components/RelationClasses/RelationClasses'
import useObjectFields from './hooks/useObjectFields'
import { ObjectViewProps } from './components/types/object-view'
import StatusCard from 'status/StatusCard/StatusCard'
import { updateStatus, useStatusActions, useStatuses } from 'status/shared'
import { useSitemodel } from 'site/Render/hooks'
import useEditableFields from './hooks/useEditableFields/useEditableFields'
import patchObject from 'yesObject/shared/api/patchObject'
import { ScrollToHashElement } from 'shared/utils'
import { useSiteState } from 'siteState/shared'
import { useFetchYesObjects } from 'yesObject/shared'
import { useEffect, useState } from 'react'
import { concatenateFields } from 'shared/utils/object/concatenateFields'
import SectionDivider from '../SectionDivider'
import ShowSituation from './components/ShowSituation/ShowSituation'
import { useTranslation } from 'react-i18n-lite'
import useObjectViewReports from './hooks/useObjectViewReports/useObjectViewReports'
import selectObjectSource from './lib/selectObjectSource/selectObjectSource'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

type LocationState = {
  objectIndex?: number
  sortOptions: SortOption[]
  objectclassUuid: string
  pagination?: Pagination
}

const ObjectView = ({ props }: ObjectViewProps) => {
  const {
    relations_view: relationConfig,
    uuid_classification: classificationUuid,
    uuid_objectclass_query: objectclassUuidQueryKey = 'uuid_objectclass',
    informational = false,
    concat_fields: concatFields,
    field_settings: fieldSettings,
    styleMap
  } = props

  const sitemodel = useSitemodel()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const locationState = location?.state as LocationState

  const [filterQuery] = useSiteState<FilterQuery | undefined>('filter-query')
  const [objectIndex, setObjectIndex] = useSiteState<number | undefined>(
    'object-index'
  )

  const styleProps = useStyleMaps(styleMap)

  const [shouldUpdateURL, setShouldUpdateURL] = useState(false)

  const objectUuidInUrl = searchParams.get('object-uuid') || ''
  const objectclassUuid = searchParams.get(objectclassUuidQueryKey) || ''

  const objectFields = useObjectFields({
    objectclassUuid,
    classificationUuid,
    fieldSettings
  })

  const { sortOptions } = locationState || {}

  const hasObjectIndex = typeof objectIndex === 'number'
  const shouldFetchFromIndex = !!filterQuery && hasObjectIndex

  const {
    yesObjects: [objectFromIndex],
    isFetching: isFetchingObjectFromIndex,
    pagination,
    setPage,
    setSortOptions,
    fetchYesObjects: fetchObjectFromIndex
  } = useFetchYesObjects({
    filterBody: {
      query: filterQuery || {},
      objectclass: objectclassUuid
    },
    initialSortOptions: sortOptions,
    initialPagination: { limit: 1, offset: objectIndex || 0 },
    enabled: shouldFetchFromIndex
  })

  useEffect(() => {
    setSortOptions(sortOptions)
  }, [sortOptions, setSortOptions])

  const {
    yesObject: objectFromUuid,
    isFetching: isFetchingObjectFromUuid,
    fetchObjects: fetchObjectFromUuid
  } = useYesObject({
    objectUUID: objectUuidInUrl,
    objectclassUUID: objectclassUuid,
    enabled: !shouldFetchFromIndex
  })

  const { yesObject, isFetchingObject, refetchObject } = selectObjectSource({
    fetchObjectFromIndex,
    fetchObjectFromUuid,
    isFetchingObjectFromIndex,
    isFetchingObjectFromUuid,
    objectFromIndex,
    objectFromUuid
  })

  const isFetching = isFetchingObject || objectFields.isLoading

  useEffect(() => {
    if (hasObjectIndex) setPage(objectIndex + 1)
  }, [hasObjectIndex, objectIndex, setPage])

  const handleNavigation = (newPage: number) => {
    setObjectIndex(newPage - 1)
    setShouldUpdateURL(true)
    navigate(`${location.pathname}${location.search}#object-view`, {
      replace: true,
      state: location.state
    })
  }

  const updateUuidInUrl = () => {
    if (
      !yesObject?.uuid ||
      yesObject.uuid === objectUuidInUrl ||
      !shouldUpdateURL
    )
      return

    setShouldUpdateURL(false)
    searchParams.set('object-uuid', yesObject.uuid)
    navigate(`${location.pathname}?${searchParams}#object-view`, {
      replace: true,
      state: location.state
    })
  }

  useEffect(updateUuidInUrl, [
    yesObject?.uuid,
    objectUuidInUrl,
    searchParams,
    location,
    navigate,
    shouldUpdateURL
  ])

  const statuses = useStatuses({ objectclassUuid })
  const { statusActions } = useStatusActions({ statuses })

  const { fieldValues, isEditing, onChange, onToggleEditing } =
    useEditableFields({
      fields: objectFields.fields,
      yesObject,
      onSave(values) {
        patchObject({
          uuid: objectUuidInUrl,
          data: {
            ...values,
            uuid_Objectclass: objectclassUuid,
            version: yesObject?.version
          },
          showLoading: true
        }).then(refetchObject)
      }
    })

  const [contentMinHeight, setContentMinHeight] = useState(0)
  const updateContentMinHeightFromDom = (container: HTMLDivElement | null) => {
    if (container?.offsetHeight) {
      setContentMinHeight(container.offsetHeight)
    }
  }

  const isYesObjectEmpty = !isFetching && !yesObject
  const isObjectFieldsEmpty = !isFetching && !objectFields?.fields?.length

  const sitemodelTitle = sitemodel?.title
  const objectclassName = objectFields?.objectclass?.des
  const title = sitemodelTitle || objectclassName || ''
  const completeTitle = title
    ? `${title} | ${t('area-title.object-view')}`
    : t('area-title.object-view')

  const { reportButtons, singleReport, mergedReport, batchReport } =
    useObjectViewReports({
      isFetching,
      yesObject,
      filterQuery,
      informational
    })

  if (isYesObjectEmpty || isObjectFieldsEmpty)
    return (
      <SectionDivider
        id='object-view'
        title={completeTitle}
        centerHeaderElement={
          shouldFetchFromIndex ? (
            <NavigationBetweenObjects
              currentPosition={objectIndex}
              pagination={pagination}
              onNavigating={handleNavigation}
            />
          ) : undefined
        }
        backgroundColor='#004099'
        disabled
        style={styleProps}
      />
    )

  const values = isEditing ? fieldValues : yesObject

  const concatenatedFields = concatFields
    ? concatenateFields(concatFields, values as YesObject)
    : values

  return (
    <SectionDivider
      id='object-view'
      title={title}
      centerHeaderElement={
        shouldFetchFromIndex ? (
          <NavigationBetweenObjects
            currentPosition={objectIndex}
            pagination={pagination}
            onNavigating={handleNavigation}
          />
        ) : undefined
      }
      rightHeaderElement={reportButtons}
      backgroundColor='#004099'
      style={styleProps}
    >
      <HierarchyCard className='pb-4'>
        {isFetching && (
          <div style={{ minHeight: contentMinHeight }}>
            <SkeletonDots />
          </div>
        )}

        {!isFetching && (
          <div ref={updateContentMinHeightFromDom} className='p-0'>
            <ScrollToHashElement />
            {!informational && yesObject?.uuid && yesObject?.version && (
              <div className='row g-2'>
                <StatusCard
                  informational={informational}
                  statusUuid={yesObject?.uuid_status || ''}
                  updateStatus={(destinationStatusCode) =>
                    updateStatus({
                      objectVersion: yesObject.version,
                      objectUuid: yesObject.uuid,
                      objectclassUuid,
                      destinationStatusCode,
                      showLoading: true
                    }).then(refetchObject)
                  }
                  {...{
                    statusActions,
                    statuses
                  }}
                />

                <ShowSituation
                  yesObject={yesObject}
                  situationCode={yesObject?.situation}
                  objectClassUUID={objectclassUuid}
                  objectUUID={yesObject.uuid}
                  objectVersion={yesObject.version}
                  onRefresh={refetchObject}
                  mainActionLabel={isEditing ? 'Salvar e Ativar' : 'Modificar'}
                  mainActionOnClick={onToggleEditing}
                  informational={informational}
                />
              </div>
            )}

            <Fields
              fields={objectFields.fields}
              informational={informational || !isEditing}
              fieldValues={concatenatedFields}
              onChange={onChange}
            />

            <RelationClasses
              relationFields={objectFields.relationFields}
              fieldValues={yesObject}
              relationConfig={relationConfig}
              informational={informational}
            />
          </div>
        )}
      </HierarchyCard>

      {singleReport}
      {mergedReport}
      {batchReport}
    </SectionDivider>
  )
}

export default ObjectView
