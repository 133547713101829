import { NavigationSliderSecondary } from '@yes.technology/react-toolkit'

import { NavigationSliderSecondaryContainerProps } from './NavigationSliderSecondaryContainer.types'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const DataNavigationSiderSecondary = ({
  props
}: NavigationSliderSecondaryContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)

  return (
    <div style={styleProps}>
      <NavigationSliderSecondary {...props} />
    </div>
  )
}

export default DataNavigationSiderSecondary
