import styled from 'styled-components'

// TODO: Share code across NotFoundPage
export const MainContainer = styled.div`
  width: calc(100% - 32px);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 16px;
`
