import React from 'react'
import { AccordionProps } from './Accordion.types'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import {
  AccordionContainer,
  AccordionDivider,
  AccordionTitle,
  ContentContainer
} from './Accordion.styles'
import { Row, Col, Text, Icon } from '@yes.technology/react-toolkit'
import { StyledGrid } from 'shared/style/grid'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

function Accordion({ props }: AccordionProps) {
  const { items, styleMap } = props

  const styleProps = useStyleMaps(styleMap)

  return (
    <StyledGrid style={styleProps}>
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={12}>
          <AccordionContainer>
            {items.map((item) => (
              <React.Fragment key={item.uuid}>
                <details key={item.uuid}>
                  <AccordionTitle>
                    <Text variant='content-emphasis-05'>{item.des}</Text>
                    <Icon iconName='DropdownArrowDown' icSize='regular' />
                  </AccordionTitle>
                  <ContentContainer>
                    <SafeHtmlContent html={item.complement} />
                  </ContentContainer>
                </details>
                <AccordionDivider />
              </React.Fragment>
            ))}
          </AccordionContainer>
        </Col>
      </Row>
    </StyledGrid>
  )
}

export default Accordion
