import { useIsMobile } from '@yes.technology/react-toolkit'

type DescriptionAreaProps = {
  des?: string
  isApplicationSystemName?: boolean
}

const DescriptionArea = ({
  des,
  isApplicationSystemName
}: DescriptionAreaProps) => {
  const isMobile = useIsMobile()

  const displayNone = 'd-none d-md-block'
  const className = `nav-link h6 mb-0${isMobile && isApplicationSystemName ? '' : ` ${displayNone}`}`

  return <span className={`${className}`}>{des}</span>
}

export default DescriptionArea
