import { StatusObject } from '@yes.technology/react-toolkit'

const mockStatuses: StatusObject[] = [
  {
    code: '1',
    complement: '',
    date_creation_row: '2022-01-17T18:28:31Z',
    date_end_estimated_row: '',
    date_end_row: '',
    date_modification_row: '2022-02-03T15:02:38Z',
    date_start_estimated_row: '',
    date_start_row: '',
    des: 'TODO',
    obs: '',
    situation: '40',
    uuid: '897ba2c3-571d-4f22-ace4-5299dff7ec48',
    uuid_Objectclass: 'UUID_OBJECTCLASS_STATUS',
    uuid_language: '',
    uuid_status: '1',
    uuid_user_creation_row: '',
    uuid_user_modification_row: 'c74c58c8-568b-11ec-ac85-0e8c17226e8a',
    version: '2'
  },
  {
    code: '2',
    complement: '',
    date_creation_row: '2021-11-09T15:49:04Z',
    date_end_estimated_row: '',
    date_end_row: '',
    date_modification_row: '2021-11-09T15:49:04Z',
    date_start_estimated_row: '',
    date_start_row: '',
    des: 'DOING',
    obs: '',
    situation: '40',
    uuid: 'd51aa873-a2cc-413c-b1bb-ec2911ef0ce0',
    uuid_Objectclass: 'UUID_Objectclass_Status',
    uuid_language: 'pt-BR',
    uuid_status: '1',
    uuid_user_creation_row: '',
    uuid_user_modification_row: '',
    version: '1'
  },
  {
    code: '3',
    complement: '',
    date_creation_row: '2021-11-09T16:04:59Z',
    date_end_estimated_row: '',
    date_end_row: '',
    date_modification_row: '2022-02-03T19:05:45Z',
    date_start_estimated_row: '',
    date_start_row: '',
    des: 'DONE',
    obs: '',
    situation: '40',
    uuid: '4aae75be-953f-4131-b3cf-041674ab2036',
    uuid_Objectclass: 'UUID_Objectclass_Status',
    uuid_language: 'pt-BR',
    uuid_status: '1',
    uuid_user_creation_row: '',
    uuid_user_modification_row: '5666dabc-a13c-11eb-998f-0e8c17226e8a',
    version: '3'
  }
]

export default mockStatuses
