import React from 'react'

interface RenderEmbeddedProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
  embeddedClassName?: string
  embedded: boolean
}

const RenderEmbedded = React.memo(function RenderEmbedded({
  children,
  className,
  embeddedClassName,
  embedded,
  ...rest
}: RenderEmbeddedProps) {
  if (embedded) {
    return (
      <div className={embeddedClassName} {...rest}>
        {children}
      </div>
    )
  }
  return (
    <div {...{ className }} {...rest}>
      {children}
    </div>
  )
})

export default RenderEmbedded
