import { StatusObject } from '@yes.technology/react-toolkit'

import { useFetchYesObjects } from 'yesObject/shared'

type UseStatusByCodeArgs = {
  objectclassUuid: string
  statusCode: string
}

export default function useStatusByCode({
  objectclassUuid,
  statusCode
}: UseStatusByCodeArgs) {
  const {
    yesObjects: [status],
    isFetching
  } = useFetchYesObjects<StatusObject>({
    filterBody: {
      query: {
        $and: [
          { 'code.des': { $eq: statusCode } },
          { 'objectclass.uuid': { $eq: objectclassUuid } },
          { 'situation.des': { $eq: '40' } }
        ]
      },
      objectclass: window.uuidObjectclassStatus
    }
  })

  return { status, isLoading: isFetching }
}
