import {
  FilterQuery,
  SortOption,
  YesObject
} from '@yes.technology/react-toolkit'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { merge } from 'lodash'
import { useObjectclass } from 'objectclass/shared'
import Report from 'report'
import { FieldSettings } from 'shared/types'
import { updateDisplayText } from 'shared/utils'
import { ConcatFields } from 'shared/utils/object/concatenateFields'
import { useSitemodel } from 'site/Render/hooks'
import useStyleMaps, { StyleMap } from 'site/Renderer/hooks/useStyleMap'
import { useSiteState } from 'siteState/shared'
import useBoundFilterQueryElementUuid from 'siteState/shared/hooks/useBoundFilterQueryElementUuid'
import { useFetchYesObjects } from 'yesObject/shared'
import DataTable from './DataTable'
import DataTableAreaLine from './DataTableAreaLine'
import useChangedFieldValues from './hooks/useChangedFieldValues'
import useTableLayouts from './hooks/useTableLayouts'
import useTableMode from './hooks/useTableMode'

type DataTablesProps = {
  props: {
    elementUuid: string
    uuid_objectclass: string
    uuid_layout_classification?: string
    uuid_filteraction?: string
    objectclassQueryString?: string
    informational?: boolean
    concat_fields?: ConcatFields
    hide_row_action_buttons?: boolean
    hide_main_collapsible_container?: boolean
    merge_filter_query?: FilterQuery
    field_settings?: FieldSettings
    styleMap?: StyleMap
    is_show_title?: boolean
    is_collapsible_card?: boolean
    objects?: YesObject[]
  }
}

const DataTables = ({ props }: DataTablesProps) => {
  const sitemodel = useSitemodel()

  const {
    elementUuid,
    uuid_objectclass: objectclassUuid,
    uuid_layout_classification: layoutClassificationUuid,
    objectclassQueryString,
    informational = false,
    hide_main_collapsible_container: hideMainCollapsibleContainer = false,
    hide_row_action_buttons: hideRowActionButtons = false,
    merge_filter_query: mergeFilterQuery,
    field_settings: fieldSettings,
    styleMap,
    is_show_title: isShowTitle,
    is_collapsible_card: isCollapsibleCard,
    objects = []
  } = props
  const { objectclass, isLoading: isLoadingObjectClass } = useObjectclass({
    objectclassUuid
  })

  const hasInitialObjects = objects?.length > 0

  const { fields = {} } = objectclass || {}

  const updatedFields = useMemo(
    () => updateDisplayText(fields, fieldSettings),
    [fieldSettings, fields]
  )

  const columns = useMemo(() => Object.keys(updatedFields), [updatedFields])

  const boundFilterQueryElementUuid =
    useBoundFilterQueryElementUuid(elementUuid)

  const [filterQuery] = useSiteState<FilterQuery>(
    'filter-query',
    boundFilterQueryElementUuid
  )
  const [refetchFilterQuery, setRefetchFilterQuery] = useSiteState(
    'refetch-filterquery',
    boundFilterQueryElementUuid
  )

  const styleProps = useStyleMaps(styleMap)

  const finalFilterQuery = useMemo(() => {
    if (filterQuery && mergeFilterQuery) {
      return { ...merge(filterQuery, mergeFilterQuery) }
    }
    return filterQuery
  }, [filterQuery, mergeFilterQuery])

  const [_, setObjectclassUUID] = useSiteState<string>('objectclassuuid')
  const [shouldResetObjectArea, setShouldResetObjectArea] = useState(true)

  const columnsLayouts = useTableLayouts({ layoutClassificationUuid })

  const orderBy = columnsLayouts?.[0]?.defaultOrderBy.map(
    ([key, value]) => ({ [key]: value }) as SortOption
  )

  const columnsToRemove = columnsLayouts?.[0]?.removeColumns ?? []

  columnsToRemove.forEach((columnToRemove) => {
    const index = columns.indexOf(columnToRemove)
    if (index > -1) {
      columns.splice(index, 1)
    }
  })

  const {
    yesObjects,
    isFetching: isLoadingObjects,
    pagination,
    totalObjects,
    setPage,
    sortOptions,
    setSortOptions,
    fetchYesObjects
  } = useFetchYesObjects({
    filterBody: {
      query: finalFilterQuery,
      objectclass: objectclassUuid
    },
    enabled: !!finalFilterQuery,
    shouldMergeFilters: true
  })

  const { mode, setMode } = useTableMode()
  const { changedFieldValues, setChangedFieldValues } = useChangedFieldValues()

  const [reportDownloadTriggered, setReportDownloadTriggered] = useState(false)
  const triggerReportDownload = useCallback(
    () => setReportDownloadTriggered(true),
    []
  )

  const [lastReportUrl, onUrlReady] = useState<string>('')
  const reportUrl = reportDownloadTriggered ? lastReportUrl : ''

  const sitemodelTitle = sitemodel?.title
  const objectclassName = objectclass?.des
  const title = sitemodelTitle || objectclassName || ''

  const prevOrderBy = useRef({})

  useEffect(() => {
    if (JSON.stringify(prevOrderBy.current) !== JSON.stringify(orderBy)) {
      setSortOptions(orderBy)
      prevOrderBy.current = orderBy
    }
  }, [orderBy, setSortOptions])

  useEffect(() => {
    setShouldResetObjectArea(true)
  }, [sortOptions, finalFilterQuery, pagination])

  useEffect(() => {
    objectclassUuid && setObjectclassUUID(objectclassUuid)
  }, [objectclassUuid, setObjectclassUUID])

  useEffect(() => {
    if (!refetchFilterQuery) return
    fetchYesObjects()
    setRefetchFilterQuery(false)
  }, [fetchYesObjects, refetchFilterQuery, setRefetchFilterQuery])

  const preventRendering = !hasInitialObjects && !finalFilterQuery

  if (preventRendering) {
    return (
      <DataTableAreaLine
        hierarchyLevel='level_2'
        title={title}
        titleOnly
        disabled
        hideMainCollapsibleContainer
        style={styleProps}
      />
    )
  }

  const isLoading = isLoadingObjectClass || isLoadingObjects

  if (isLoading && reportDownloadTriggered) {
    setReportDownloadTriggered(false)
  }

  const finalObjects = finalFilterQuery ? yesObjects : objects

  return (
    <>
      <DataTable
        idObjectClass={objectclassUuid}
        objects={finalObjects}
        refetchObjects={fetchYesObjects}
        onSort={setSortOptions}
        setCurrentPage={setPage}
        actionColumnsPosition='last'
        columnsLayouts={columnsLayouts?.[0]?.columnsLayouts}
        concatFields={props.concat_fields}
        displayLayoutSelection={!informational}
        {...{
          isLoading,
          informational,
          title,
          isShowTitle,
          isCollapsibleCard,
          objectclass,
          columns,
          fields: updatedFields,
          pagination,
          totalObjects,
          sortOptions,
          layoutClassificationUuid,
          mode,
          setMode,
          changedFieldValues,
          setChangedFieldValues,
          reportDownloadTriggered,
          triggerReportDownload,
          reportUrl,
          objectclassQueryString,
          shouldResetObjectArea,
          setShouldResetObjectArea,
          hideMainCollapsibleContainer,
          hideRowActionButtons,
          fieldSettings
        }}
        style={styleProps}
      />
      {reportDownloadTriggered && (
        <Report
          reportType='list'
          downloadType='single-pdf'
          {...{ layoutClassificationUuid, onUrlReady }}
        />
      )}
    </>
  )
}

export default DataTables
