import { YesObject } from '@yes.technology/react-toolkit'
import { ProductCombo, TransferOrder } from './types'
import { useFetchYesObjects } from 'yesObject/shared'
import { useMemo } from 'react'

type UseTransferOrderProps = {
  transferOrder: TransferOrder
}

const useOrderObjects = (orders: YesObject[] = [], enabled = !!orders.length) =>
  useFetchYesObjects({
    filterBody: {
      objectclass: orders?.[0]?.uuid_Objectclass,
      query: { $or: orders.map((item) => ({ uuid: { $eq: item.uuid } })) },
      skip_relations: false
    },
    enabled
  })

export default function useTransferOrderDetail({
  transferOrder
}: UseTransferOrderProps) {
  const {
    handofforder_transferorder,
    receiptorder_transferorder,
    passorder_transferorder,
    loadingorder_transferorder,
    unloadingorder_transferorder,
    classificationorder
  } = transferOrder

  const { yesObjects: handoffOrders, isFetched: isHandoffFetched } =
    useOrderObjects(handofforder_transferorder)
  const { yesObjects: receiptOrders, isFetched: isReceiptFetched } =
    useOrderObjects(receiptorder_transferorder)
  const { yesObjects: passOrders, isFetched: isPassFetched } = useOrderObjects(
    passorder_transferorder
  )
  const { yesObjects: loadingOrders, isFetched: isLoadingFetched } =
    useOrderObjects(loadingorder_transferorder)
  const { yesObjects: unloadingOrders, isFetched: isUnloadingFetched } =
    useOrderObjects(unloadingorder_transferorder)
  const {
    yesObjects: classificationOrders,
    isFetched: isClassificationFetched
  } = useOrderObjects(classificationorder)

  const productCombos = useMemo(
    () =>
      [
        handoffOrders,
        receiptOrders,
        passOrders,
        loadingOrders,
        unloadingOrders,
        classificationOrders
      ]
        .map((orders) => orders.map((order) => order.productcombo))
        .flat(2)
        .filter((productCombo) => productCombo) as YesObject[],
    [
      classificationOrders,
      handoffOrders,
      loadingOrders,
      passOrders,
      receiptOrders,
      unloadingOrders
    ]
  )

  const canFetchProductsCombo = [
    isHandoffFetched ||
      (handofforder_transferorder && !handofforder_transferorder.length),
    isReceiptFetched ||
      (receiptorder_transferorder && !receiptorder_transferorder.length),
    isPassFetched ||
      (passorder_transferorder && !passorder_transferorder.length),
    isLoadingFetched ||
      (loadingorder_transferorder && !loadingorder_transferorder.length),
    isUnloadingFetched ||
      (unloadingorder_transferorder && !unloadingorder_transferorder.length),
    isClassificationFetched ||
      (classificationorder && !classificationorder.length)
  ]
    .filter((bool) => bool !== undefined)
    .every(Boolean)

  const { yesObjects: productsCombosDetailed } = useOrderObjects(
    productCombos,
    canFetchProductsCombo
  )

  const productsCombosDetailedMap = useMemo(() => {
    const map: Record<string, YesObject> = {}

    productsCombosDetailed.forEach(
      (productCombo) => (map[productCombo.uuid] = productCombo)
    )

    return map as Record<string, ProductCombo>
  }, [productsCombosDetailed])

  return {
    handoffOrders,
    receiptOrders,
    passOrders,
    loadingOrders,
    unloadingOrders,
    classificationOrders,
    productsCombosDetailedMap
  }
}
