import { Optional, Record, String, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type WorkflowmodelContainerProps = {
  props: {
    workflowmodelUuid?: string
    workflowUuid?: string
    styleMap?: StyleMap
  }
}

export const WorkflowmodelContainerTypeGuard = Record({
  workflowmodelUuid: Optional(String),
  workflowUuid: Optional(String),
  styleMap: Optional(Unknown)
})
