import moment from 'moment'

import { CellValue } from '../types'
import { useTranslation } from 'react-i18n-lite'
import { YesObject } from '@yes.technology/react-toolkit'

export type FieldType = 'DATETIME' | 'RELATION' | 'STRING' | 'UUID'

type UseFormattedValueProps = {
  value: CellValue
  type?: FieldType
  dateOptions?: Intl.DateTimeFormatOptions
}

const formatArrayValue = (value: YesObject[]) =>
  value.map(({ des }) => des).join(', ')

const formatObjectValue = (value: object) => JSON.stringify(value)

export default function useFormattedValue({
  value,
  type = 'STRING',
  dateOptions
}: UseFormattedValueProps): string | null {
  const { language } = useTranslation()

  if (!value) return null

  if (Array.isArray(value)) {
    return formatArrayValue(value)
  }

  if (typeof value === 'object') {
    return formatObjectValue(value)
  }

  if (type === 'DATETIME' && moment(value).isValid()) {
    return new Date(value).toLocaleString(language, dateOptions)
  }

  return String(value)
}
