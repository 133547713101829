import apiAsync from 'infrastructure/shared/api/api-async'
import { PatchObjectResponse } from 'shared/types/object.type'

type PatchObjectProps = {
  uuid: string
  data: Record<string, unknown>
  showLoading?: boolean
  allowSpecialFields?: boolean
}

export default function patchObject({
  uuid,
  data,
  allowSpecialFields,
  showLoading
}: PatchObjectProps): Promise<PatchObjectResponse> {
  const specialFields = ['uuid_status', 'situation']

  const obj = allowSpecialFields
    ? data
    : Object.fromEntries(
        Object.entries(data).filter(([key]) => !specialFields.includes(key))
      )

  return apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: `objects/${uuid}`,
    method: 'PATCH',
    obj,
    showLoading
  })
}
