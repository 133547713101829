import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  CarouselItem,
  DynamicInfoCardHighlightContainerProps,
  NavigationPair,
  NavigationSliderCommand
} from './DynamicInfoCardHighlightContainer.types'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { StyledContainer } from './DynamicInfoCardHighlight.style'
import DynamicInfoCardHighlight from 'component/DynamicInfoCardHighlight'
import mod from './utils/mod'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const DynamicInfoCardHighlightContainer = ({
  props
}: DynamicInfoCardHighlightContainerProps) => {
  const { sliderObjects: carouselItems = [], param1, param2, styleMap } = props

  const styleProps = useStyleMaps(styleMap)

  const itemsMap = useMemo(() => {
    return carouselItems.reduce(
      (acc, item) => {
        acc[item.uuid] = item
        return acc
      },
      {} as Record<string, CarouselItem>
    )
  }, [carouselItems])

  const groupEntitiesMap = useMemo(() => {
    const groupMenu: Record<string, NavigationPair[]> = {}

    carouselItems.forEach((item) => {
      const [firstMenu, secondMenu] = item.navigationMenu

      const roles = groupMenu[firstMenu.des]

      const pair = {
        leftMenu: firstMenu,
        rightMenu: secondMenu,
        pairUuid: item.uuid
      }

      if (!roles) groupMenu[firstMenu.des] = [pair]
      else groupMenu[firstMenu.des].push(pair)
    })

    return groupMenu
  }, [carouselItems])

  const groupKeys = useMemo(
    () => Object.keys(groupEntitiesMap),
    [groupEntitiesMap]
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const updateUrl = useCallback(
    (nextCarouselItem: CarouselItem) => {
      setSearchParams(
        (prevParams) => {
          const newParams = new URLSearchParams(prevParams)
          const menu = nextCarouselItem.navigationMenu
          newParams.set(param1, menu?.[0]?.des)
          if (param2 && menu?.[1].des) {
            newParams.set(param2, menu[1].des)
          }
          return newParams
        },
        { replace: true }
      )
    },
    [param1, param2, setSearchParams]
  )

  const param1Value = searchParams.get(param1)
  const param2Value = param2 && searchParams.get(param2)

  const current = useMemo(() => {
    if (!param1Value) return carouselItems[0]

    if (param2Value) {
      const item = groupEntitiesMap[param1Value]?.find(
        (pair) => pair.rightMenu.des === param2Value
      )

      if (item) {
        return itemsMap[item.pairUuid]
      }
    }

    const itemUuid = groupEntitiesMap[param1Value]?.[0]?.pairUuid

    return itemUuid && itemsMap[itemUuid]
  }, [carouselItems, groupEntitiesMap, param2Value, itemsMap, param1Value])

  const getNextItem = useCallback(
    (direction: number, isMain: boolean) => {
      if (!param1Value) return carouselItems[0]

      if (isMain) {
        const index = groupKeys.findIndex((item) => item === param1Value)
        const nextPos = mod(index + direction, groupKeys.length)
        return itemsMap[groupEntitiesMap[groupKeys[nextPos]][0].pairUuid]
      } else {
        const rightValues = groupEntitiesMap[param1Value]
        const index = rightValues.findIndex(
          (pair) => pair.rightMenu.des === param2Value
        )
        const nextPos = mod(index + direction, rightValues.length)
        return itemsMap[rightValues[nextPos].pairUuid]
      }
    },
    [
      param1Value,
      param2Value,
      carouselItems,
      groupKeys,
      groupEntitiesMap,
      itemsMap
    ]
  )

  if (!current || !param1Value) return null

  const onClickNavigationSlider = (command: NavigationSliderCommand) => {
    const direction = command.split('-')[1] === 'next' ? 1 : -1
    const isMain = command.includes('main')
    const next = getNextItem(direction, isMain)
    next && updateUrl(next)
  }

  const labels: [string, string?] = [
    current.navigationMenu[0]?.des,
    current.navigationMenu[1]?.des
  ]

  return (
    <StyledContainer $isLoading={false} style={styleProps}>
      <DynamicInfoCardHighlight
        navigationHeading={''}
        navigationSliderLabels={labels}
        des={<SafeHtmlContent html={current.des} />}
        complement={
          current.complement && <SafeHtmlContent html={current.complement} />
        }
        onClickNavigationSlider={onClickNavigationSlider}
        image={current.image}
        bulletPoints={current.bulletPoints}
        text={current.text && <SafeHtmlContent html={current.text} />}
        showNavigationSliders={true}
        callToAction={{
          label: current.button?.label,
          href: current.button?.href
        }}
        mainNavigationColor={current.navigationMenu[0]?.cor_representativa}
        secondaryNavigationColor={current.navigationMenu[1]?.cor_representativa}
        isLoading={false}
        buttonPosition={current.buttonPosition}
        complementPosition={current.complementPosition}
        secondaryNavigationDisabled={groupEntitiesMap[param1Value]?.length <= 1}
      />
    </StyledContainer>
  )
}

export default DynamicInfoCardHighlightContainer
