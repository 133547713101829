import { YesObject } from '@yes.technology/react-toolkit'
import Renderer from 'site/Renderer/Renderer'
import { Component } from 'site/shared/types'

interface FilteractionRendererProps {
  props: {
    elements: YesObject[]
  }
}

interface Element {
  uuid?: string
  des?: string
  element_content?: string
  elementmodel?: YesObject[]
}

const ElementRenderer = ({ props }: FilteractionRendererProps) => {
  const { elements } = props

  const components: Component[] =
    elements?.map((element: Element) =>
      element?.element_content && typeof element.element_content === 'string'
        ? JSON.parse(element.element_content)
        : {}
    ) || []

  if (components.length === 0) return null

  return <Renderer {...{ components }} />
}

export default ElementRenderer
