import { NavigationSlider } from '@yes.technology/react-toolkit'

import { StyledGridContainer } from '../Cockpit.styles'
import { SectionsProps } from '../Cockpit.types'
import { useTranslation } from 'react-i18n-lite'

const Sections = ({
  children,
  currentSection,
  totalSections,
  onNavigate,
  style
}: SectionsProps) => {
  const { t } = useTranslation()
  return (
    <div style={style}>
      <StyledGridContainer>
        <h1>{t('cockpit.title')}</h1>
        <NavigationSlider
          textForCurrent={t('cockpit.section.text-for-current')}
          currentPosition={currentSection}
          total={totalSections}
          hierarchyLevel='level_2'
          heightClass='small'
          afterClick={onNavigate}
        />
      </StyledGridContainer>
      {children}
    </div>
  )
}

export default Sections
