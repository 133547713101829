import { Style } from '@react-pdf/types'

const chipText: Style = {
  color: '#FFF',
  fontFamily: 'Barlow',
  fontSize: 8,
  fontWeight: 600
}

const chipContainer: Style = {
  paddingVertical: 1,
  paddingHorizontal: 8,
  height: 14,
  borderRadius: '100%',
  backgroundColor: '#0056CC',
  width: 80
}

export default function getStyles() {
  return {
    chipText,
    chipContainer
  }
}
