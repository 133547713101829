import { HierarchyCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

interface LoginButtonContainer {
  backgroundColor?: string
  color?: string
}

export const LoginContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const ActiveSessionCard = styled(HierarchyCard)`
  height: 100%;

  @media (max-width: 769px) {
    & > *:first-child {
      border-radius: 0;
    }
  }

  & > *:first-child {
    height: 100%;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #bbb;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #999;
    }
  }
`

export const LoginButtonContainer = styled.div<LoginButtonContainer>`
  button {
    color: #fff;
    width: 50px;
    font-size: 12px;
  }

  @media (min-width: 768px) {
    button {
      width: 115px;
    }
  }
`

export const ActiveSessionCardContainer = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => (isOpen ? undefined : 'display: none')};

  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 56px;
  right: 0;
  overflow-y: auto;
  z-index: 9999;

  /* desktop */
  @media (min-width: 768px) {
    position: absolute;
    width: 356px;
    height: calc(100vh - 120px);
    left: initial;
    right: 0;
    top: 45px;
  }
`

export const GoBackButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;

  &:focus {
    box-shadow: unset !important;
  }

  > figure:focus {
    outline: unset !important;
  }

  &:hover {
    opacity: 0.8;
  }
`
