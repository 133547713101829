import { YesObject } from '@yes.technology/react-toolkit'
import { AuthenticationType } from 'authentication/shared/types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SitemodelUuid } from 'sitemodel/shared/types'
import { useSiteState } from 'siteState/shared'
import { useFetchYesObjects } from 'yesObject/shared'
import useYesObject from 'yesObject/shared/hooks/useYesObject'

export interface SitemodelObjectState {
  sitemodel: YesObject
  isLoading: boolean
}

export default function useSitemodelFromUrl() {
  const { sitemodelUuid: sitemodelUuidInUrl } = useParams<SitemodelUuid>()

  const { token } = useSelector(
    (state: AuthenticationType) => state.authentication
  )

  const [sitemodelObject, setSitemodelObject] =
    useSiteState<SitemodelObjectState>('sitemodel-object')

  const {
    yesObjects: homeSitemodels,
    isLoading: isLoadingHomeSitemodels,
    fetchYesObjects: refetchHomeSitemodels
  } = useFetchYesObjects({
    filteractionUuid: window.defaultSitemodelFilteractionUuid,
    showLoading: true,
    initialSortOptions: [{ priority: 'desc' }],
    enabled: !sitemodelUuidInUrl
  })

  const { yesObject: sitemodelFromUrl, isLoading: isLoadingSitemodelFromUrl } =
    useYesObject({
      objectUUID: sitemodelUuidInUrl || '',
      objectclassUUID: window.idObjectclassSitemodel,
      showLoading: true,
      displayMessageOnNoItemsFound: false
    })

  useEffect(() => {
    setSitemodelObject({
      sitemodel: sitemodelFromUrl || homeSitemodels?.[0],
      isLoading: isLoadingSitemodelFromUrl || isLoadingHomeSitemodels
    })
  }, [
    homeSitemodels,
    isLoadingHomeSitemodels,
    isLoadingSitemodelFromUrl,
    setSitemodelObject,
    sitemodelFromUrl
  ])

  /*
    Buscar sitemodels novamente quando o token mudar
    para garantir que o sitemodel seja atualizado corretamente
    após o logout
  */
  useEffect(() => {
    refetchHomeSitemodels()
  }, [token, refetchHomeSitemodels])

  return {
    sitemodel: sitemodelObject?.sitemodel,
    isLoading: sitemodelObject?.isLoading
  }
}
