import { Field, Unifree, YesObject } from '@yes.technology/react-toolkit'
import { MODIFIABLE_FIELDS_DENYLIST } from 'shared/constants'
import formatDate from 'shared/utils/date'

export interface FieldWithColumns extends Field {
  columns?: string[]
}

export type FieldsProps = {
  fields: FieldWithColumns[]
  fieldValues?: Record<string, string | YesObject[]>
  informational: boolean
  onChange: (fieldColumn: string, value: string) => void
}

const isUnmodifiableField = (field: Field) =>
  MODIFIABLE_FIELDS_DENYLIST.includes(field.column_json)

const Fields = ({
  fields,
  fieldValues = {},
  informational,
  onChange
}: FieldsProps) => {
  return (
    <div className='row mt-2 mb-2'>
      {fields.map((field) => {
        let value = fieldValues[field.column_json] || ''
        if (field.type === 'DATETIME' && fieldValues[field.column_json]) {
          value = formatDate(fieldValues[field.column_json] as string)
        }
        return (
          <div
            key={field.uuid}
            className={`${field.columns ? field?.columns?.join(' ') : 'col-md-3'}`}
          >
            <Unifree
              label={field.display_text.des}
              value={value as string}
              required
              onChange={(value) => onChange(field.column_json, value)}
              informational={informational || isUnmodifiableField(field)}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Fields
