import { memo, useCallback, useEffect, useState } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api'

import { MarkerProps } from 'site/shared/types'

type Props = {
  markers: MarkerProps[]
  googleMapsApiKey: string
}

const MapView: React.FunctionComponent<Props> = ({
  markers,
  googleMapsApiKey
}): JSX.Element => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey
  })

  const [activeMarker, setActiveMarker] = useState<string | null>(null)

  const [map, setMap] = useState<google.maps.Map | null>(null)

  const onLoad = useCallback((map: google.maps.Map) => setMap(map), [])

  const handleActiveMarker = (marker: string) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      markers.map((marker: MarkerProps) => {
        bounds.extend({
          lat: Number(marker.lat),
          lng: Number(marker.lng)
        })
        return null
      })
      map.fitBounds(bounds)
    }
  }, [map, markers])

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: '100%', height: '100vh' }}
    >
      {markers.map(
        ({ uuid, des, complement, total, lat, lng }: MarkerProps) => (
          <Marker
            key={uuid}
            position={{ lat: Number(lat), lng: Number(lng) }}
            onClick={() => handleActiveMarker(uuid)}
            animation={google.maps.Animation.DROP}
          >
            {activeMarker === uuid ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <div>
                  <p className='fs-6 text-uppercase fw-bold'>{des}</p>
                  <p className='fs-6'>
                    {complement}: <span className='fw-bold'>{total}</span>
                  </p>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        )
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default memo(MapView)
