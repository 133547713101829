import { useEffect, useMemo, useState } from 'react'

import { ErrorMessage } from 'infrastructure/shared/types'
import { postObjectsFilter } from 'sitemodel/shared/api'
import { ObjectType } from 'sitemodel/shared/types'
import { useTranslation } from 'react-i18n-lite'

type UseFetchSitemodelProps = {
  objectUuid: string | undefined
  objectclassUuid: string
  showLoading: boolean
  onError?: (message: string) => void
}

/**
 * @deprecated
 * use import useYesObject from 'yesObject/shared/hooks/useYesObject'
 */
const useFetchObject = ({
  objectUuid,
  objectclassUuid,
  showLoading,
  onError
}: UseFetchSitemodelProps): ObjectType | undefined => {
  const [object, setObject] = useState<ObjectType>()

  const { t } = useTranslation()

  const payload = useMemo(
    () => ({
      query: {
        $and: [
          {
            'uuid.des': {
              $eq: objectUuid
            }
          }
        ]
      },
      objectclass: objectclassUuid
    }),
    [objectUuid, objectclassUuid]
  )

  useEffect(() => {
    if (objectUuid) {
      postObjectsFilter<ObjectType>(payload, showLoading)
        .then((response) => {
          setObject(response?.data?.objects?.[0])
          if (response?.data?.objects?.length === 0) {
            onError?.(t('error-message.object-not-found'))
          }
        })
        .catch((error: ErrorMessage) => {
          console.log('error', error.message)
          onError?.(error.message)
        })
    }
  }, [payload, setObject, showLoading, onError, t, objectUuid])

  return object
}

export default useFetchObject
