import { View } from '@react-pdf/renderer'
import {
  Field,
  Objectclass,
  PDFAreaLine,
  YesObject
} from '@yes.technology/react-toolkit'
import React from 'react'
import ObjectCard from './components/ObjectCard/ObjectCard'

interface CardsAreaProps {
  objectclass: Objectclass
  objects: YesObject[]
  fields: Field[]
  relationClasses: Field[]
}

function CardsArea({
  objectclass,
  fields,
  objects,
  relationClasses
}: CardsAreaProps) {
  return (
    <View>
      <PDFAreaLine
        des={objectclass.des}
        heightClass='doubleXSmall'
        colorScheme='blue'
      />

      {objects?.map((object, index, arr) => (
        <ObjectCard
          key={object.uuid}
          fields={fields}
          object={object}
          relationClasses={relationClasses}
          title={`Objeto ${index + 1}/${arr.length}`}
        />
      ))}
    </View>
  )
}

export default CardsArea
