import { useIsMobile } from '@yes.technology/react-toolkit'
import { Breakpoint } from 'shared/style/grid'

export type StyleMap = Partial<{
  [K in Breakpoint]: React.CSSProperties
}>

export type UseStyleMapsProps = {
  styleMap: StyleMap
}

const useStyleMaps = (styleMap?: StyleMap) => {
  const isMobile = useIsMobile()

  if (!styleMap) return

  return isMobile ? styleMap.sm : styleMap.md
}

export default useStyleMaps
