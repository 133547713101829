import { Document, Page } from '@react-pdf/renderer'
import FilterArea from './components/FilterArea/FilterArea'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { GroupedFieldValues } from '../site/Renderer/components/FilterFields/types'
import CardsArea from './components/CardsArea/CardsArea'
import { Field, Objectclass, YesObject } from '@yes.technology/react-toolkit'

interface ReportProps {
  fields?: FilterFieldsResponse
  filterComposition: string
  objectFields: Field[]
  relationClasses: Field[]
  values: GroupedFieldValues
  objects: YesObject[]
  title: string
  objectclass?: Objectclass
}

function CardsReport({
  fields,
  filterComposition,
  values,
  title,
  objects,
  objectFields,
  relationClasses,
  objectclass
}: ReportProps) {
  return (
    <Document>
      <Page size='A4' dpi={150}>
        {fields && (
          <FilterArea
            values={values}
            fields={fields}
            title={title}
            composition={filterComposition}
          />
        )}
        {objectclass && (
          <CardsArea
            objectclass={objectclass}
            objects={objects}
            fields={objectFields}
            relationClasses={relationClasses}
          />
        )}
      </Page>
    </Document>
  )
}

export default CardsReport
