import { stripHtml } from 'shared/utils'

export type Mapping = {
  [key: string]: string
}

export type InputObject = {
  [key: string]: unknown
}

export default function createQueryString(
  object: InputObject,
  mappings: Mapping[]
): string {
  const queryStringParts: string[] = []
  mappings.forEach((mapping) => {
    Object.entries(mapping).forEach(([key, mappedKey]) => {
      if (object[key]) {
        const value = object[key]
        const isDirectValue = typeof value === 'string'
        const isArrayWithValue =
          Array.isArray(value) && value.length > 0 && value[0].des

        const valueToEncode = isDirectValue
          ? value
          : isArrayWithValue
            ? value[0].des
            : null

        if (valueToEncode !== null) {
          queryStringParts.push(
            `${mappedKey}=${encodeURIComponent(stripHtml(valueToEncode))}`
          )
        }
      }
    })
  })

  return queryStringParts.join('&')
}
