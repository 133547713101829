import { Classification, Text } from '@yes.technology/react-toolkit'

import { ClassificationCardProps } from '../DataCards.types'
import { CollapsibleObjectCard, CollapsibleObjectCardList } from './styles'
import { useMemo } from 'react'
import ClassifiedCollabsibleObjectCardContent from './ClassifiedCollapsibleObjectCardContent'

const ClassifiedCollapsibleObjectCard = ({
  classification,
  objects,
  cardProps
}: ClassificationCardProps) => {
  const object = useMemo(
    () =>
      objects.find(
        (object) =>
          object.uuid.toLowerCase() === classification.UUID_OBJECT.toLowerCase()
      ),
    [objects, classification]
  )

  if (!object) {
    return null
  }

  const Header = (
    <Text variant='content-emphasis-03' as='h2'>
      {classification.HIERARCHYCODE}. {object?.des}
    </Text>
  )

  return (
    <CollapsibleObjectCard
      headerColor='blue'
      heightClass='xSmall'
      leftHeaderElement={Header}
      defaultOpenState
    >
      <CollapsibleObjectCardList>
        {classification.CHILDREN?.map((child: Classification) => (
          <ClassifiedCollabsibleObjectCardContent
            key={child.UUID_OBJECT}
            objects={objects}
            classification={child}
            cardProps={cardProps}
          />
        ))}
      </CollapsibleObjectCardList>
    </CollapsibleObjectCard>
  )
}

export default ClassifiedCollapsibleObjectCard
