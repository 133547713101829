import { Field, YesObject } from '@yes.technology/react-toolkit'
import { useEffect, useReducer, useState } from 'react'

type UseEditableFieldsProps = {
  fields: Pick<Field, 'column_json'>[]
  yesObject?: YesObject
  onSave: (values: YesObject) => void
}

const pickRelatedFieldValues = (
  fields: Pick<Field, 'column_json'>[],
  yesObject?: YesObject
): YesObject | undefined => {
  if (!yesObject) {
    return yesObject
  }

  return Object.fromEntries(
    fields.flatMap((field) =>
      field.column_json in yesObject
        ? [[field.column_json, yesObject[field.column_json]]]
        : []
    )
  ) as YesObject
}

export default function useEditableFields({
  fields,
  yesObject,
  onSave
}: UseEditableFieldsProps) {
  const [fieldValues, setFieldValues] = useState(() =>
    pickRelatedFieldValues(fields, yesObject)
  )
  const [isEditing, toggleEditing] = useReducer(
    (state: boolean) => !state,
    false
  )

  const onChange = (fieldColumn: string, value: string) => {
    setFieldValues(
      (previousFieldValues) =>
        ({
          ...previousFieldValues,
          [fieldColumn]: value
        }) as YesObject
    )
  }

  const onToggleEditing = () => {
    if (isEditing && fieldValues) {
      onSave(fieldValues)
    }

    toggleEditing()
  }

  useEffect(() => {
    setFieldValues(pickRelatedFieldValues(fields, yesObject))
  }, [fields, yesObject])

  return {
    fieldValues,
    isEditing,
    onChange,
    onToggleEditing
  }
}
