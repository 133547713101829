import { defaultTheme } from '@yes.technology/react-toolkit'
import { TabsComponentType } from './Tabs.types'

export enum TextClassesEnum {
  doubleXSmall = 'content-01',
  xSmall = 'content-02',
  medium = 'content-04',
  xLarge = 'content-06',
  doubleXLarge = 'content-07',
  tripleXLarge = 'content-08',

  /** v1.5 */
  extraSmall = 'content-01',
  small = 'content-02',
  semiSmall = 'content-03',
  regular = 'content-04',
  semiLarge = 'content-05',
  large = 'content-06',
  extraLarge = 'content-07',
  semiGiant = 'content-08'
}

export const textColorMap = {
  white: (defaultTheme as any).colors.whiteText as string,
  black: (defaultTheme as any).colors.blackText as string
}

export enum TextClassesEnumFocused {
  doubleXSmall = 'content-emphasis-01',
  xSmall = 'content-emphasis-02',
  medium = 'content-emphasis-04',
  xLarge = 'content-emphasis-06',
  doubleXLarge = 'content-emphasis-07',
  tripleXLarge = 'content-emphasis-08',

  /** v1.5 */
  extraSmall = 'content-emphasis-01',
  small = 'content-emphasis-02',
  semiSmall = 'content-emphasis-03',
  regular = 'content-emphasis-04',
  semiLarge = 'content-emphasis-05',
  large = 'content-emphasis-06',
  extraLarge = 'content-emphasis-07',
  semiGiant = 'content-emphasis-08'
}

export const tabsComponentSizes: TabsComponentType = {
  doubleXSmall: {
    height: 20,
    spaceBetween: 16,
    navigationButtonSize: 20,
    separatorHeight: 12,
    separatorThickness: 0.5
  },
  xSmall: {
    height: 24,
    spaceBetween: 16,
    navigationButtonSize: 24,
    separatorHeight: 14,
    separatorThickness: 1
  },
  medium: {
    height: 40,
    spaceBetween: 24,
    navigationButtonSize: 40,
    separatorHeight: 20,
    separatorThickness: 1
  },
  xLarge: {
    height: 48,
    spaceBetween: 32,
    navigationButtonSize: 48,
    separatorHeight: 24,
    separatorThickness: 2
  },
  doubleXLarge: {
    height: 64,
    spaceBetween: 24,
    navigationButtonSize: 64,
    separatorHeight: 48,
    separatorThickness: 2
  },
  tripleXLarge: {
    height: 128,
    spaceBetween: 32,
    navigationButtonSize: 128,
    separatorHeight: 56,
    separatorThickness: 2
  },

  /** v1.5 */
  extraSmall: {
    height: 20,
    spaceBetween: 16,
    navigationButtonSize: 20,
    separatorHeight: 12,
    separatorThickness: 0.5
  },
  small: {
    height: 24,
    spaceBetween: 16,
    navigationButtonSize: 24,
    separatorHeight: 14,
    separatorThickness: 1
  },
  semiSmall: {
    height: 32,
    spaceBetween: 24,
    navigationButtonSize: 32,
    separatorHeight: 16,
    separatorThickness: 1
  },
  regular: {
    height: 40,
    spaceBetween: 24,
    navigationButtonSize: 40,
    separatorHeight: 20,
    separatorThickness: 1
  },
  semiLarge: {
    height: 48,
    spaceBetween: 32,
    navigationButtonSize: 48,
    separatorHeight: 24,
    separatorThickness: 2
  },
  large: {
    height: 64,
    spaceBetween: 32,
    navigationButtonSize: 64,
    separatorHeight: 32,
    separatorThickness: 2
  },
  extraLarge: {
    height: 64,
    spaceBetween: 48,
    navigationButtonSize: 64,
    separatorHeight: 48,
    separatorThickness: 2
  },
  semiGiant: {
    height: 128,
    spaceBetween: 32,
    navigationButtonSize: 128,
    separatorHeight: 56,
    separatorThickness: 2
  }
}
