import { ObjectType, MetadataType } from 'sitemodel/shared/types'

type Props = {
  object: ObjectType
  metadata: MetadataType
}

const ItemView: React.FunctionComponent<Props> = ({
  object,
  metadata
}): JSX.Element => {
  return (
    <div className='col-md-12'>
      {Object.keys(object).map((key, index) => {
        return (
          <div key={index}>
            {renderField(metadata?.fields[key]?.display_text?.des, object[key])}
          </div>
        )
      })}
    </div>
  )
}

const renderField = (des: string, value: string | ObjectType[] | undefined) => {
  return (
    <>
      {value && (
        <div>
          <p className='text-uppercase'>{des}</p>
          {Array.isArray(value) ? (
            renderRelation(value)
          ) : (
            <p className='lead mb-4'>{value}</p>
          )}
        </div>
      )}
    </>
  )
}

const renderRelation = (relation: ObjectType[]) => {
  return (
    <div
      className='shadow-sm bg-body rounded mb-4'
      style={{ maxHeight: '206px', overflow: 'auto' }}
    >
      <ul className='list-group'>
        {relation.map((object: { des: string }, index) => (
          <li key={index} className='list-group-item'>
            {object?.des}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ItemView
