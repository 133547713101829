import { Optional, Record, String, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type AuthenticationProps = {
  props: {
    redirectOrigin: string
    styleMap?: StyleMap
  }
}

export const AuthenticationTypeGuard = Record({
  redirectOrigin: String,
  styleMap: Optional(Unknown)
})
