import { SituationObject } from '@yes.technology/react-toolkit'
import { SituationActionObject } from '..'
import { useFetchYesObjects } from 'yesObject/shared'

export default function useSituationActions({
  situations
}: {
  situations: SituationObject[]
}) {
  const { yesObjects, isLoading } = useFetchYesObjects({
    filterBody: {
      query: {
        $and: [
          {
            'situation.des': {
              $eq: '40'
            },
            'destination_situation.uuid': {
              $in: situations.map((situation) => situation.uuid)
            }
          }
        ]
      },
      objectclass: 'UUID_Objectclass_Situationaction'
    },
    initialPagination: { offset: 0, limit: 100 },
    initialSortOptions: []
  })

  return {
    situationActions: yesObjects as SituationActionObject[],
    isFetchingActions: isLoading
  }
}
