import { useQuery } from '@tanstack/react-query'
import { fetchDocumentLink } from '../api'

type UseDocumentLinkArgs = {
  uuid?: string
}

export default function useDocumentLink({ uuid }: UseDocumentLinkArgs) {
  const { data, error, isLoading } = useQuery<string, Error>({
    queryKey: ['DocumentLink', uuid],
    queryFn: async () => fetchDocumentLink(uuid as string),
    enabled: !!uuid
  })

  return {
    data,
    error,
    isLoading
  }
}
