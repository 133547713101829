export type ErrorMessageProps = {
  message: string
}
const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  message
}): JSX.Element => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='alert alert-light'>{message}</div>
      </div>
    </div>
  )
}

export default ErrorMessage
