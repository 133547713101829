import {
  Button,
  Text,
  HierarchyCard,
  CollapsibleCard
} from '@yes.technology/react-toolkit'
import { StatusCategory } from 'component/TransportOrderCard/types'
import styled from 'styled-components'

export const COLOR_MAP = {
  todo: '#F6EAAA',
  doing: '#C4F5C9',
  done: '#E2E2E2'
}

export const CardHead = styled.div<{ $bgColor?: string }>`
  height: 40px;
  width: calc(100% + 16px);
  position: relative;
  left: -8px;
  top: -8px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: ${(props) => props.$bgColor};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`
export const CardTitle = styled(Text).attrs({
  variant: 'content-emphasis-04'
})<{ $color?: string }>`
  margin: auto;
  color: ${(props) => props.$color};
`

export const TransferOrder = styled(HierarchyCard)`
  margin-top: 8px;
  border-radius: 6px;
`

export const ColoredCard = styled(CollapsibleCard)<{
  statusCategory: StatusCategory
}>`
  background-color: ${(props) => COLOR_MAP[props.statusCategory]};
  border-radius: 6px;
`

export const TransferOrdersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
