import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button, Text } from '@yes.technology/react-toolkit'

import { ClassificationCardProps } from '../DataCards.types'
import {
  CollabsibleObjectCardItem,
  CollapsibleObjectCardActions
} from './styles'
import { interpolateUrl } from '../utils/interpolateUrl'

const ClassifiedCollabsibleObjectCardContent = ({
  classification,
  objects,
  cardProps
}: ClassificationCardProps) => {
  const object = useMemo(
    () =>
      objects.find(
        (objectclass) =>
          objectclass.uuid.toLowerCase() ===
          classification.UUID_OBJECT.toLowerCase()
      ),
    [objects, classification]
  )

  if (!object) {
    return null
  }

  const actions = (cardProps?.actions || []).map((prop) => ({
    ...prop,
    url: interpolateUrl(prop.url, object)
  }))

  return (
    <CollabsibleObjectCardItem>
      <Text variant='content-03' as='h3'>
        {classification.HIERARCHYCODE} {object?.des}
      </Text>
      <CollapsibleObjectCardActions>
        {actions.map((action, index) => (
          <Link
            to={action.url}
            style={{ width: '100%', textDecoration: 'none' }}
            key={index}
          >
            <Button heightClass='small' variant='secondary'>
              {action.des}
            </Button>
          </Link>
        ))}
      </CollapsibleObjectCardActions>
    </CollabsibleObjectCardItem>
  )
}

export default ClassifiedCollabsibleObjectCardContent
