import { Container, ContentWrapper, Link, StyledButton, Text } from './styles'
import { SupportBannerProps } from './SupportBannerContainer.types'

const SupportBanner = ({
  des,
  button,
  leftBackgroundImageUrl,
  rightBackgroundImageUrl,
  style
}: SupportBannerProps) => {
  return (
    <Container
      {...{ leftBackgroundImageUrl, rightBackgroundImageUrl }}
      style={style}
    >
      <ContentWrapper>
        <Text>{des}</Text>
        <Link href={button.href}>
          <StyledButton variant='secondary' heightClass='large'>
            {button.label}
          </StyledButton>
        </Link>
      </ContentWrapper>
    </Container>
  )
}

export default SupportBanner
