const CLASSES_MAP: Record<string, string> = {
  GenericObjectCard: 'col-md-4 col-xl-3 mt-2 mb-2',
  TaskObjectCard: 'col-md-6 col-lg-4 col-xl-3 ',
  ContactObjectCard: 'col-md-6 col-xl-3 '
}

type CardContainerProps = {
  children: JSX.Element
  cardType: string
}

function CardContainer({ children, cardType }: CardContainerProps) {
  return <div className={CLASSES_MAP[cardType]}>{children}</div>
}

export default CardContainer
