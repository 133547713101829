import { useEffect, useState } from 'react'

import { HTTPClient } from 'infrastructure/shared/api'
import { MarkerProps } from 'site/shared/types'

type Props = {
  endpoint: string | undefined
}

const useMapEndpoint = ({ endpoint }: Props) => {
  const [objects, setObjects] = useState<MarkerProps[]>([])

  useEffect(() => {
    if (endpoint) {
      HTTPClient.get(endpoint)
        .then((response) => {
          setObjects(response?.data?.data?.objects)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [endpoint])
  return objects
}

export default useMapEndpoint
