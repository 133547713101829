export type SortDirection = 'asc' | 'desc'

export function sortByKey<T>(
  list: T[] | null | undefined,
  key: keyof T,
  direction: SortDirection = 'asc'
): T[] {
  if (!list || list.length === 0) {
    return []
  }

  return list.slice().sort((a, b) => {
    const valueA = a[key]
    const valueB = b[key]

    const numA = parseInt(valueA as string)
    const numB = parseInt(valueB as string)

    if (isNaN(numA) || isNaN(numB)) return 0

    if (numA < numB) {
      return direction === 'asc' ? -1 : 1
    }

    if (numA > numB) {
      return direction === 'asc' ? 1 : -1
    }

    return 0
  })
}
