import { useQueries } from '@tanstack/react-query'
import { YesObject } from '@yes.technology/react-toolkit'

import fetchYesObjects from 'yesObject/shared/api/fetchYesObjects'

type UsePreloadingImagesProps = {
  documentUUIDs: string[]
}

const usePreloadingImages = ({ documentUUIDs }: UsePreloadingImagesProps) => {
  const documents = useQueries({
    queries: (documentUUIDs ?? []).map((uuid) => ({
      queryKey: ['document-result', uuid],
      queryFn: async () => {
        return fetchYesObjects({
          filterBody: {
            query: { $and: [{ 'uuid.des': { $eq: uuid || '' } }] },
            objectclass: window.idObjectclassDocument,
            orderby: [],
            skip_metadata: true,
            project_fields: { uuid: 1, url: 1 },
            pagination: { limit: 1, offset: 0 }
          }
        })
      }
    })),
    combine: (results) =>
      results?.map((result) => {
        const url = result.data?.objects?.[0]?.url?.[0] as YesObject
        const documentUuid = result.data?.objects?.[0]?.uuid || ''
        return {
          [documentUuid]: url?.des
        }
      })
  })

  // pre loading images
  documents?.forEach((document) => {
    const img = new Image()
    img.src = Object.values(document)[0]
  })

  return documents ?? []
}

export default usePreloadingImages
