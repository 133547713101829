import {
  Button,
  SyntheticButton,
  YesObject
} from '@yes.technology/react-toolkit'
import { To } from 'react-router-dom'
import usePersistentSelection from '../../FilterFields/hooks/usePersistentSelection'
import { useDocumentLink } from 'document/shared/hooks'
import { useTranslation } from 'react-i18n-lite'

export type ActionsProps = {
  object: YesObject
  target?: '_self' | '_blank'
  getOptions: (to: To) => Array<{ children: string; onClick: () => void }>
}

const DocumentActions = ({
  object,
  target = '_self',
  getOptions
}: ActionsProps) => {
  const { t } = useTranslation()

  const { shouldRenderSelectAndLink } = usePersistentSelection()

  const { data: documentLink } = useDocumentLink({ uuid: object.uuid })

  if (!documentLink) return <></>

  if (shouldRenderSelectAndLink()) {
    return (
      <SyntheticButton
        options={getOptions(documentLink)}
        heightClass='small'
        dropdownLabel='more'
      />
    )
  }

  return (
    <a href={documentLink} target={target} rel='noopener noreferrer'>
      <Button variant='secondary' heightClass='small'>
        {t('data-tables.open')}
      </Button>
    </a>
  )
}

export default DocumentActions
