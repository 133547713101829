import { useMemo } from 'react'
import { SitemodelManifestStateGrouped } from 'sitemodel/shared/types/sitemodel.type'

export default function useSitemodelStateGrouping(
  state: Record<string, object>
) {
  return useMemo(() => {
    const result: SitemodelManifestStateGrouped = {
      environment: {},
      querystring: {},
      filteraction: {},
      credential: {},
      applicationsystem: {}
    }

    Object.keys(state).forEach((key) => {
      if ('filteraction' in state[key]) {
        result.filteraction[key] = state[
          key
        ] as SitemodelManifestStateGrouped['filteraction']['']
      }

      if ('querystring' in state[key]) {
        result.querystring[key] = state[
          key
        ] as SitemodelManifestStateGrouped['querystring']['']
      }

      if ('environment' in state[key]) {
        result.environment[key] = state[
          key
        ] as SitemodelManifestStateGrouped['environment']['']
      }

      if ('credential' in state[key]) {
        result.credential[key] = state[
          key
        ] as SitemodelManifestStateGrouped['credential']['']
      }

      if ('applicationsystem' in state[key]) {
        result.applicationsystem[key] = state[
          key
        ] as SitemodelManifestStateGrouped['applicationsystem']['']
      }
    })

    return result
  }, [state])
}
