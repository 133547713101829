import styled from 'styled-components'

export const Fields = styled.div`
  display: flex;
  flex-direction: row;
`

export const SIDField = styled.div`
  flex-basis: 20%;

  @media (min-width: 768px) {
    flex-basis: 60px;
  }
`

export const AutoField = styled.div`
  flex-grow: 1;
  margin-left: 4px;
`

export const MapContainer = styled.div`
  height: 260px;
`
