export enum AlignEnum {
  Left = 'left',
  Right = 'right'
}

export enum RepresentationEnum {
  LogoArea = 'logo_area',
  Icon = 'icon',
  Des = 'des',
  ActionButton = 'action_button',
  Component = 'component',
  Link = 'link',
  Breadcrumbs = 'breadcrumbs',
  NavigationMenu = 'navigation_menu',
  ManagementDashboard = 'management_dashboard'
}
