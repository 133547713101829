import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18n-lite'

import * as api from 'authentication/shared/api'
import { loginSucceeded } from 'authentication/Login/ducks/authentication'
import useAuth from '../hooks/useAuth'
import { isEmbedded } from 'shared/utils/iframe'
import { useQuery } from '@tanstack/react-query'

const SYNCHRONIZATION_ERROR_CORRECTION = 300
const SHOW_ALERT_FROM = 300

const AuthenticationRenewal: React.FunctionComponent =
  (): JSX.Element | null => {
    const [timeLeft, setTimeLeft] = useState<number>(0)
    const [hasStickyAlert, setHasStickyAlert] = useState<boolean>(false)
    const { expirationDate, token } = useAuth()

    const { refetch: authRefetch } = useQuery<api.ReloadTokenResponse, Error>({
      queryKey: ['authentication', token],
      queryFn: () => {
        return api.postReloadToken({ token })
      },
      enabled: false
    })

    const dispatch = useDispatch()

    const handleScroll = useCallback(() => {
      const offset = window.scrollY
      let isSticky = false

      if (offset > 100) {
        isSticky = true
      }

      setHasStickyAlert(isSticky)
    }, [])

    const updateTimeLeft = useCallback(() => {
      const now = Math.round(Date.now() / 1000)
      const newTimeLeft =
        expirationDate - now - SYNCHRONIZATION_ERROR_CORRECTION
      setTimeLeft(newTimeLeft)
    }, [expirationDate])

    const logoutOnExpire = () => {
      if (timeLeft < 0) {
        dispatch({ type: 'login/LOGOUT' })
      }
    }

    const handleTokenReload = () => {
      authRefetch().then(({ data, error }) => {
        if (data) {
          const decoded = jwtDecode<{ email: string }>(token)
          dispatch(
            loginSucceeded(decoded.email, {
              token: data.data.token,
              expiration_date: data.data.expiration_date,
              tokenType: 'private'
            })
          )
        }

        if (error) {
          console.log(error)
        }
      })
    }

    useEffect(() => {
      document.addEventListener('scroll', handleScroll)

      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }, [handleScroll])

    useEffect(() => {
      const interval = setInterval(updateTimeLeft, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [updateTimeLeft])

    useEffect(logoutOnExpire, [timeLeft, dispatch])

    const { t } = useTranslation()

    /* 5 minutes less to avoid clock synchronization problems */
    if (!isEmbedded() && timeLeft > 0 && timeLeft < SHOW_ALERT_FROM) {
      return (
        <div
          className={`${hasStickyAlert ? 'sticky-alert' : ''}`}
          style={{
            width: '100%',
            background: '#a31a1a',
            zIndex: 1000
          }}
        >
          <div
            style={{ padding: '10px' }}
            className='text-white d-flex justify-content-center align-items-center'
          >
            {t('auth-renewal.session-ends', { timeLeft: timeLeft.toString() })}
            <button
              type='button'
              className='btn btn-light btn-sm ms-2'
              onClick={handleTokenReload}
            >
              {t('auth-renewal.reload-session')}
            </button>
          </div>
        </div>
      )
    }
    return null
  }

export default AuthenticationRenewal
