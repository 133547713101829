import DOMPurify from 'dompurify'

interface HtmlContentProps {
  html: string
}

const SafeHtmlContent = ({ html }: HtmlContentProps) => {
  const safeHtml = DOMPurify.sanitize(html)
  return <span dangerouslySetInnerHTML={{ __html: safeHtml }} />
}

export default SafeHtmlContent
