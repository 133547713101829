import { Icon, SyntheticButton } from '@yes.technology/react-toolkit'
import { Actions, Container } from './Field.styles'
import { GroupedFieldValues } from '../../types'
import usePersistentSelection from '../../hooks/usePersistentSelection'
import { useTranslation } from 'react-i18n-lite'

type RelationDataFieldProps = {
  objectclassUuid: string
  name?: string
  children: React.ReactNode
  groupedFieldValues: GroupedFieldValues
  displayActions?: boolean
}

const RelationDataField = ({
  name,
  objectclassUuid,
  groupedFieldValues,
  displayActions,
  children
}: RelationDataFieldProps) => {
  const { getRedirectToObjectclassCallback } = usePersistentSelection()

  const selectAndLink = getRedirectToObjectclassCallback(
    name,
    objectclassUuid,
    groupedFieldValues
  )

  const { t } = useTranslation()

  const actionButtons = [
    {
      children: <Icon iconName='SelectAndRelateSign' />,
      title: t('select-and-link.action'),
      onClick: selectAndLink
    }
  ]

  return (
    <Container {...{ displayActions }}>
      {children}
      {displayActions && (
        <Actions>
          <SyntheticButton
            options={actionButtons}
            heightClass='regular'
            dropdownLabel='options'
            variant='secondary'
          />
        </Actions>
      )}
    </Container>
  )
}

export default RelationDataField
