import { FieldButton } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import useTableContext from '../../hooks/useTableContext'
import { useState } from 'react'

export default function InsertButton() {
  const { t } = useTranslation()
  const {
    setChangedFieldValues,
    setMode,
    relationCounterpartField = {}
  } = useTableContext()
  const [insertCount, setInsertCount] = useState('')

  const onClick = () => {
    const insertCountNumber = parseInt(insertCount, 10) || 0

    if (insertCountNumber <= 0) {
      return
    }

    const changedFieldPlaceholders = Object.fromEntries(
      [...Array(insertCountNumber)].map((_, index) => [
        index,
        {
          ...relationCounterpartField
        }
      ])
    )
    setChangedFieldValues(changedFieldPlaceholders)

    setMode('insert')
  }

  return (
    <FieldButton
      heightClass='semiSmall'
      value={insertCount}
      onChange={setInsertCount}
      variant='secondary'
      {...{ onClick }}
    >
      {t('data-tables.insert')}
    </FieldButton>
  )
}
