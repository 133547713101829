import { StatusAction } from '../situation.type'
import { useFetchYesObjects } from 'yesObject/shared'

type UseStatusActionsProps = {
  statuses: { uuid: string }[]
}

export default function useStatusActions({ statuses }: UseStatusActionsProps) {
  const pagination = { limit: 100, offset: 0 }

  const { yesObjects, isLoading } = useFetchYesObjects({
    filterBody: {
      query: {
        $and: [
          {
            'situation.des': {
              $eq: '40'
            },
            'allowed_origin_status.uuid': {
              $in: statuses.map((status) => status.uuid)
            }
          }
        ]
      },
      objectclass: 'UUID_OBJECTCLASS_STATUSACTION',
      visualization: ''
    },
    initialPagination: pagination,
    enabled: statuses.length > 0
  })

  return { statusActions: yesObjects as StatusAction[], isLoading }
}
