import styled from 'styled-components'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'

const ComponentStructure = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
`

const Content = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    width: 50%;
  }
`

export const NoDataFound = ({ message }: { message: string }) => {
  return (
    <ComponentStructure>
      <Content>
        <SafeHtmlContent html={message} />
      </Content>
    </ComponentStructure>
  )
}
