import { YesObject } from '@yes.technology/react-toolkit'
import { useCallback, useEffect, useState } from 'react'
import { useFetchYesObjects } from 'yesObject/shared'

const usePlace = () => {
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null)

  const [city, setCity] = useState<string | null>(null)
  const [state, setState] = useState<string | null>(null)

  const [cityUuid, setCityUuid] = useState<string>('')

  const handlePlaceSelect = useCallback(() => {
    if (
      !selectedPlace ||
      !selectedPlace.name ||
      !selectedPlace.address_components
    )
      return

    const state = selectedPlace?.address_components?.find((item) =>
      item.types.includes('administrative_area_level_1')
    )?.short_name

    setCity(selectedPlace?.name)
    setState(state || null)
  }, [selectedPlace])

  useEffect(handlePlaceSelect, [handlePlaceSelect])

  const { yesObjects, isLoading, fetchYesObjects } = useFetchYesObjects({
    filterBody: {
      query: {
        $and: [
          {
            'des.des': {
              $eq: city || ''
            },
            'situation.des': {
              $eq: '40'
            }
          }
        ]
      },
      objectclass: window.cityObjectclassUuid,
      pagination: {
        limit: 99999,
        offset: 0
      },
      project_fields: {
        uuid: 1,
        state: 1,
        des: 1
      }
    },
    enabled: false
  })

  useEffect(() => {
    if (city && state) fetchYesObjects()
  }, [city, state, fetchYesObjects])

  useEffect(() => {
    if (yesObjects.length > 0 && state) {
      const city = yesObjects.find((object: YesObject) =>
        (object.state as YesObject[])?.filter((s) => s.des === state)
      )

      if (!city?.uuid) return

      setCityUuid(city.uuid)
    }
  }, [yesObjects, state])

  return {
    setSelectedPlace,
    selectedPlace,
    cityUuid,
    isLoading
  }
}

export default usePlace
