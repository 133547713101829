import React from 'react'
import { ErrorHTTPStatusCode } from '@yes.technology/react-toolkit'
import { MainContainer } from './style'
import { useTranslation } from 'react-i18n-lite'

function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <>
      <MainContainer>
        <ErrorHTTPStatusCode
          des={t('error-message.page-not-found')}
          complement={t('error-message.not-found-complement')}
        />
      </MainContainer>
    </>
  )
}

export default NotFoundPage
