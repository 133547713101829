import { Link } from 'react-router-dom'

import { ResponsiveImageObjectType } from 'site/shared/types'

type Props = {
  des?: string
  link?: string
  image?: string | ResponsiveImageObjectType
}

const LogoArea: React.FunctionComponent<Props> = ({
  des,
  link,
  image
}): JSX.Element | null => {
  if (link && image && typeof image === 'string') {
    return (
      <Link to={link} className='navbar-brand ms-2'>
        <img
          src={image}
          alt={des}
          title={des}
          style={{ width: '100%', maxWidth: '90px' }}
        />
      </Link>
    )
  }
  return null
}
export default LogoArea
