import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { TextExpressionContainerProps } from './TextExpressionContainer.types'
import { StyledSkeletonDots, StyledText } from './TextExpressionContainer.style'
import { useSiteState } from 'siteState/shared'
import { SkeletonDots } from '@yes.technology/react-toolkit'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const TextExpressionContainer = ({ props }: TextExpressionContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)

  const [allResultsFetched] = useSiteState('allResultsFetched')
  const isLoading = !allResultsFetched
  return (
    <div style={styleProps}>
      {!isLoading && (
        <StyledText>{<SafeHtmlContent html={props.des} />}</StyledText>
      )}
      {isLoading && (
        <StyledSkeletonDots>
          <SkeletonDots />
        </StyledSkeletonDots>
      )}
    </div>
  )
}

export default TextExpressionContainer
