import { normalizeFilterRegex } from '@yes.technology/react-toolkit'
import { FilterQuery } from '@yes.technology/react-toolkit'
import { useCallback } from 'react'
import { isStatusObjectclass } from 'shared/utils/specialRelationFields/specialRelationFields'
import fetchYesObjects from 'yesObject/shared/api/fetchYesObjects'

type UseFetchSuggestions = {
  objectclassUuid: string
  fetchYesObjects: typeof fetchYesObjects
  uuidCurrentSitemodelObjectclass?: string
  skipRelations?: boolean
  skipMetadata?: boolean
  interdependentFilteringQuery?: FilterQuery
}

export default function useFetchSuggestions({
  objectclassUuid,
  fetchYesObjects: fetchYesObjectsCallback,
  uuidCurrentSitemodelObjectclass,
  skipRelations = false,
  skipMetadata = false,
  interdependentFilteringQuery
}: UseFetchSuggestions) {
  const fetchSuggestions = useCallback(
    async (query: string) => {
      const isObjectclassStatus: object = isStatusObjectclass(objectclassUuid)
        ? {
            'objectclass.uuid': {
              $in: [uuidCurrentSitemodelObjectclass]
            }
          }
        : {}

      const filterQuery = {
        'situation.des': {
          $eq: '40'
        },
        'des.des': {
          $regex: `.*${normalizeFilterRegex(query)}.*`
        },
        ...isObjectclassStatus,
        ...interdependentFilteringQuery
      }

      const yesResponse = await fetchYesObjectsCallback({
        filterBody: {
          query: {
            $and: [filterQuery]
          },
          objectclass: objectclassUuid,
          orderby: [
            {
              des: 'asc'
            }
          ],
          pagination: {
            offset: 0,
            limit: 100
          },
          skip_relations: skipRelations,
          skip_metadata: skipMetadata
        }
      })

      return yesResponse.objects || []
    },
    [
      objectclassUuid,
      uuidCurrentSitemodelObjectclass,
      interdependentFilteringQuery,
      fetchYesObjectsCallback,
      skipRelations,
      skipMetadata
    ]
  )

  return fetchSuggestions
}
