import { Classification } from '@yes.technology/react-toolkit'

import useYesObject from 'yesObject/shared/hooks/useYesObject'

type UseClassificationArgs = {
  classificationUuid?: string
}

export default function useClassification({
  classificationUuid
}: UseClassificationArgs) {
  const { yesObject, isLoading } = useYesObject({
    objectclassUUID: 'UUID_Objectclass_classification',
    objectUUID: classificationUuid || '',
    enabled: !!classificationUuid,
    showLoading: false,
    skipRelations: true,
    skipMetadata: true
  })

  const classificationContent = yesObject?.classification_content

  const classification =
    typeof classificationContent === 'string'
      ? (JSON.parse(classificationContent) as Classification[])
      : undefined

  return {
    classification,
    isLoading
  }
}
