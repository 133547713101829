import { useSelector } from 'react-redux'

import { AuthenticationType } from 'authentication/shared/types'
import { useLogout } from 'shared/hooks'

const Login: React.FunctionComponent = (): JSX.Element | null => {
  const { token } = useSelector(
    ({ authentication }: AuthenticationType) => authentication
  )

  const { handleLogout } = useLogout()

  return (
    <form>
      {token && (
        <>
          <button className='btn btn-light me-2 disabled' type='button'>
            Configure
          </button>
          <button
            onClick={handleLogout}
            className='btn btn-light'
            type='button'
          >
            Logout
          </button>
        </>
      )}
      {!token && (
        <button className='btn btn-light' type='button'>
          Login
        </button>
      )}
    </form>
  )
}

export default Login
