import { Objectclass } from '@yes.technology/react-toolkit'
import { useFieldsWithRelations } from 'shared/hooks/useFilterFields/useFilterFields'
import { useFetchYesObjects } from 'yesObject/shared'

type UseFieldValuesProps = {
  field_column: string
  objectclass_uuid: string
  objectclass: Objectclass | undefined
}

export default function useRelationObjects({
  field_column,
  objectclass,
  objectclass_uuid
}: UseFieldValuesProps) {
  const fields = useFieldsWithRelations({ objectclass })

  const field_uuid =
    fields.find((field) => field.column_json === field_column)
      ?.relation_objectclass_uuid || ''

  const { yesObjects: relationObjects, isLoading } = useFetchYesObjects({
    initialPagination: { limit: 40, offset: 0 },
    filterBody: {
      query: {
        $and: [
          {
            'objectclass.uuid': {
              $in: [objectclass_uuid]
            },
            'situation.des': {
              $eq: '40'
            }
          }
        ]
      },
      objectclass: field_uuid,
      skip_metadata: true,
      skip_relations: true
    },
    enabled: !!field_uuid
  })

  return { relationObjects, isLoading }
}
