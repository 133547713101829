import { useEffect } from 'react'
import DocumentDownloader from './components/DocumentDownloader/DocumentDownloader'
import useSplitReportComponents from './hooks/useSplitReportComponents/useSplitReportComponents'
import useReportPdfs from './hooks/useReportPdfs/useReportPdfs'
import useReportData from './hooks/useReportData/useReportData'
import useReportZip from './hooks/useReportZip/useReportZip'
import { YesObject } from '@yes.technology/react-toolkit'

type ReportProps = {
  reportType: 'list' | 'card'
  downloadType: 'single-pdf' | 'multiple-pdf-zip'
  yesObject?: YesObject
  layoutClassificationUuid?: string
  filteractionUuid?: string
  splitThreshold?: number
  onUrlReady?: (url: string) => void
}

export default function Report({
  splitThreshold = 10,
  layoutClassificationUuid,
  filteractionUuid,
  reportType,
  downloadType,
  yesObject,
  onUrlReady
}: ReportProps) {
  const {
    objectFields,
    fieldValues,
    filterComposition,
    filterFields,
    yesObjects: filterYesObjects,
    requestLoading
  } = useReportData({
    filteractionUuid
  })

  const yesObjects = yesObject ? [yesObject] : filterYesObjects

  const reportComponents = useSplitReportComponents({
    ...objectFields,
    fieldValues,
    filterComposition,
    filterFields,
    reportType,
    layoutClassificationUuid,
    splitThreshold,
    yesObjects
  })

  const shouldMerge = downloadType !== 'multiple-pdf-zip'

  const { urls, blobs, isRendering } = useReportPdfs({
    components: reportComponents,
    sourceObjects: yesObjects,
    shouldMerge
  })

  const { url: zipUrl, isPacking } = useReportZip({
    blobs,
    sourceObjects: yesObjects,
    enabled: downloadType !== 'single-pdf'
  })

  const url = zipUrl || (downloadType === 'single-pdf' && urls[0]) || null

  useEffect(() => {
    if (url) {
      onUrlReady?.(url)
    }
  }, [url, onUrlReady])

  const filename = downloadType === 'single-pdf' ? 'report.pdf' : 'report.zip'

  return (
    <DocumentDownloader
      {...{ url, filename }}
      loading={isRendering || isPacking || requestLoading}
    />
  )
}
