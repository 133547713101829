import { useCallback, useEffect, useState } from 'react'

import { IframeAttributes } from 'shared/components/Iframe'

type EventDataMessage = {
  height?: string
}

type Props = {
  origin: string
  src: string
  title?: string
  allow?: string
  initialHeight?: string
}

const useIframe = ({
  origin,
  src,
  title = 'Masterdata',
  allow = 'camera;geolocation',
  initialHeight = '800px' //  TODO: Alterar para 100vh quando a workflow engine passar a enviar o height por mensagem
}: Props) => {
  const [height, setHeight] = useState(initialHeight)
  const [iframeAttributes, setIframeAttributes] = useState<
    IframeAttributes | undefined
  >()

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      // TODO: Improve iframe recognition in case of two different iframes with same origin (URL =/= Origin)
      if (!origin.startsWith(event.origin)) return false
      const { height }: EventDataMessage = event.data
      if (height) {
        setHeight(`${Math.ceil(Number(height) + 50)}`)
      }
    },
    [origin]
  )

  useEffect(() => {
    setIframeAttributes({
      src,
      height,
      title,
      allow
    })
  }, [allow, height, src, title])

  return {
    iframeAttributes,
    receiveMessage
  }
}

export default useIframe
