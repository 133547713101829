import { NavigationSlider, useIsMobile } from '@yes.technology/react-toolkit'
import {
  CenterWrapper,
  FirstElement,
  StyledCardWithAreaLine,
  StyledCollapsibleCard,
  StyledFilterButton
} from './GroupControls.styles'
import React from 'react'
import { useSiteState } from 'siteState/shared'
import { FilterFieldsDisplayOptions } from '../../FilterFields'

export type GroupControlsProp = {
  elementUuid: string
  sectionPosition: number
  sectionsLength: number
  setSectionPosition: (section: number) => void
  groupPosition: number
  groupsLength: number
  setGroupPosition: (group: number) => void
  handleClearCurrentGroup: () => void
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  children: React.ReactNode
  shouldHideFilters: boolean
}

export default function GroupControls({
  sectionsLength,
  sectionPosition,
  setSectionPosition,
  groupPosition,
  groupsLength,
  setGroupPosition,
  handleClearCurrentGroup,
  navigationSliderBackgroundColor,
  navigationSliderColor,
  children,
  shouldHideFilters,
  elementUuid
}: GroupControlsProp) {
  const isMobile = useIsMobile()

  const areaLineTitle = (
    <FirstElement color={navigationSliderColor}>Grupo de Filtros</FirstElement>
  )

  const leftHeaderElement = isMobile ? undefined : areaLineTitle

  const centerHeaderElement = shouldHideFilters ? (
    isMobile ? (
      areaLineTitle
    ) : undefined
  ) : (
    <CenterWrapper>
      <NavigationSlider
        heightClass='small'
        currentPosition={sectionPosition}
        first={1}
        total={sectionsLength}
        afterClick={setSectionPosition}
        textForCurrent='Seção'
        hierarchyLevel='level_3'
        backgroundColor={navigationSliderBackgroundColor}
        color={navigationSliderColor}
      />
      {isMobile && (
        <>
          {/* <NavigationSlider
            heightClass='small'
            hierarchyLevel='level_3'
            currentPosition={groupPosition}
            first={1}
            total={groupsLength}
            afterClick={setGroupPosition}
            textForCurrent='Grupo'
            arrowOrientationButtons='diagonalArrows'
            backgroundColor={navigationSliderBackgroundColor}
            color={navigationSliderColor}
          />
          <StyledFilterButton
            heightClass='regular'
            className='button-limpar'
            onClick={handleClearCurrentGroup}
            aria-label='Limpar grupo'
          >
            Limpar
          </StyledFilterButton> */}
        </>
      )}
    </CenterWrapper>
  )

  const rightHeaderElement =
    isMobile || shouldHideFilters ? undefined : (
      <>
        {/* <NavigationSlider
          heightClass='small'
          hierarchyLevel='level_3'
          currentPosition={groupPosition}
          first={1}
          total={groupsLength}
          afterClick={setGroupPosition}
          textForCurrent='Grupo'
          arrowOrientationButtons='diagonalArrows'
          backgroundColor={navigationSliderBackgroundColor}
          color={navigationSliderColor}
        />
        <StyledFilterButton
          heightClass='regular'
          className='button-limpar'
          onClick={handleClearCurrentGroup}
          aria-label='Limpar grupo'
        >
          Limpar
        </StyledFilterButton> */}
      </>
    )

  const CardComponent = shouldHideFilters
    ? StyledCardWithAreaLine
    : StyledCollapsibleCard

  const [displayOptions] = useSiteState<FilterFieldsDisplayOptions>(
    'filter-display-options'
  )

  const displayOptionsElement = displayOptions?.[elementUuid]

  if (displayOptionsElement?.hideFieldsCollapsibleContainer)
    return <>{children}</>

  return (
    <CardComponent
      data-testid='group-controls'
      leftHeaderElement={leftHeaderElement}
      centerHeaderElement={centerHeaderElement}
      rightHeaderElement={rightHeaderElement}
      heightClass='xSmall'
      headerColor='blue'
      alignSecondPosition='center'
      backgroundColor={navigationSliderBackgroundColor}
      color={navigationSliderColor}
      {...(!shouldHideFilters && {
        defaultOpenState: true
      })}
    >
      {children}
    </CardComponent>
  )
}
