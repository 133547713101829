import styled from 'styled-components'

export const StyledText = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #303032;
  text-align: center;
  font-family: Barlow;
  font-weight: 400;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 1.25rem 3.75rem;
    font-size: 32px;
    line-height: 48px;
  }
`
export const StyledSkeletonDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  padding: 1rem 1rem 0 1rem;

  @media (min-width: 768px) {
    padding: 1.25rem 3.75rem 0 3.75rem;
    min-height: 48px;
  }
`
