import Iframe from 'shared/components/Iframe'
import { ObjectType } from 'sitemodel/shared/types'

const YoutubeVideos: React.FunctionComponent<ObjectType> = ({
  des,
  complement,
  url
}): JSX.Element => {
  return (
    <div>
      <h2>{des}</h2>
      {complement && <p>{complement}</p>}
      {Array.isArray(url) && typeof url[0].des === 'string' && (
        <div className='ratio ratio-16x9'>
          <Iframe
            data-testid='iframe-video'
            src={url[0].des}
            title={des}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          />
        </div>
      )}
    </div>
  )
}

export default YoutubeVideos
