import { Grid } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const StyledGrid = styled(Grid)`
  width: 100%;

  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 1920px;
  }
`

export const StyledContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`
