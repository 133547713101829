import { useEffect, useState } from 'react'

import { TopAreaLayoutType, TopAreaPropsType } from 'site/shared/types'
import Position from './Position'

const TopArea: React.FunctionComponent<TopAreaPropsType> = ({
  layout
}): JSX.Element | null => {
  const [state, setState] = useState<TopAreaLayoutType>()

  useEffect(() => {
    if (layout) {
      setState({
        position_1: layout?.position_1,
        position_2: layout?.position_2,
        position_3: layout?.position_3
      })
    }
  }, [layout])

  if (!layout) return null

  return (
    <nav className='navbar navbar-expand-lg navbar-light shadow-sm'>
      <div className='container-fluid'>
        {state?.position_1 &&
          state.position_1.map((props, index) => (
            <Position key={index} {...props} />
          ))}

        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#topAreaToggler'
          aria-controls='topAreaToggler'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='topAreaToggler'>
          <div className='navbar-nav' style={{ width: '100%' }}>
            <div
              className='navbar-nav d-flex justify-content-start'
              style={{ width: '50%' }}
            >
              {state?.position_2 &&
                state.position_2.map((props, index) => (
                  <Position key={index} {...props} />
                ))}
            </div>
            <div
              className='navbar-nav d-flex justify-content-end'
              style={{ width: '50%' }}
            >
              {state?.position_3 &&
                state.position_3.map((props, index) => (
                  <Position key={index} {...props} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TopArea
