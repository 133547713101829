import { Text } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const TabSubtitle = styled(Text)`
  color: #3d4e66;
  margin: 16px 0;

  @media (min-width: 768px) {
    margin: 32px 0 48px;
  }
`

export const TextContainer = styled.div`
  text-align: justify;

  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 32px;
  }
`
