import { useEffect } from 'react'
import { FilteractionObject } from '@yes.technology/react-toolkit'

import { useQuery } from '@tanstack/react-query'
import { fetchFilteraction } from 'filteraction/FilteractionResolver'

type UseFilteractionArgs = {
  filteractionUuid?: string
}

export default function useFilteraction({
  filteractionUuid
}: UseFilteractionArgs) {
  const { data: filteraction, error } = useQuery<
    FilteractionObject | null,
    Error
  >({
    queryKey: ['filteraction', filteractionUuid],
    queryFn: () => {
      return fetchFilteraction(filteractionUuid, false)
    },
    enabled: !!filteractionUuid
  })

  useEffect(() => {
    if (error) console.log(error.message)
  }, [error])

  return filteraction || undefined
}
