import { useMemo } from 'react'
import useSiteState from './useSiteState'

const useBoundFilterQueryElementUuid = (elementUuid: string) => {
  const [boundFilterQueryElements] = useSiteState<string[]>(
    'bound-filter-query-elements'
  )

  return useMemo(() => {
    return boundFilterQueryElements?.includes(elementUuid)
      ? elementUuid
      : undefined
  }, [elementUuid, boundFilterQueryElements])
}

export default useBoundFilterQueryElementUuid
