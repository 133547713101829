import { useEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector'

const ResizeDetector = ({ children }: { children: JSX.Element }) => {
  const { height, ref } = useResizeDetector()

  useEffect(() => {
    window.parent.postMessage(
      {
        height
      },
      '*'
    )
  }, [height])

  return <div ref={ref}>{children}</div>
}

export default ResizeDetector
