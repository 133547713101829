import { useEffect, useRef, useState } from 'react'
import {
  ControlPosition,
  MapControl,
  useMapsLibrary
} from '@vis.gl/react-google-maps'

import { Button, Icon } from '@yes.technology/react-toolkit'
import { PlaceAutocomplete, MapFilter } from './MapContainer.styles'

type CustomAutocompleteControlProps = {
  controlPosition: ControlPosition
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
}

export const CustomMapControl = ({
  controlPosition,
  onPlaceSelect
}: CustomAutocompleteControlProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const places = useMapsLibrary('places')

  useEffect(() => {
    if (!places || !inputRef.current) return

    const options = {
      types: ['(cities)'],
      componentRestrictions: { country: 'BR' },
      fields: ['geometry', 'name', 'formatted_address', 'address_components']
    }

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete) return

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace())
    })
  }, [onPlaceSelect, placeAutocomplete])

  const onClear = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <MapControl position={controlPosition}>
      <MapFilter>
        <PlaceAutocomplete>
          <input ref={inputRef} placeholder='Digite aqui sua cidade' />
          {/* <Icon iconName='DropdownArrowDownCircle' /> */}
        </PlaceAutocomplete>
        <Button variant='secondary' onClick={onClear}>
          <Icon iconName='Rubber' />
        </Button>
      </MapFilter>
    </MapControl>
  )
}
