import styled from 'styled-components'
import { Col, GenericObjectCard } from '@yes.technology/react-toolkit'

export const Container = styled.div``

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;

  @media (min-width: 768px) {
    > div {
      max-width: 432px;
    }
  }
`

export const DetailsContainer = styled.div``

export const StyledGenericObjectCard = styled(GenericObjectCard)`
  height: 100%;

  & > div > div > div {
    border: 0;
  }
`

export const NavigationSliderSecondaryContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledCol = styled(Col)`
  & > div {
    height: 100%;
  }
`
