import { HeightClass } from 'shared/types/toolkit'
import styled from 'styled-components'
import { tabsComponentSizes } from './Tabs.enum'

export const ItemContainer = styled.div<{ heightClass: HeightClass }>`
  display: inline-flex;
  gap: ${({ heightClass }) => tabsComponentSizes[heightClass].spaceBetween}px;
  height: ${({ heightClass }) => tabsComponentSizes[heightClass].height}px;
  align-items: center;
`

export const ItemButton = styled.button`
  all: unset;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    text-decoration: underline;
  }
`

export const VerticalSeparator = styled.div<{ heightClass: HeightClass }>`
  height: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].separatorHeight}px;

  border: 0;
  border-left: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].separatorThickness}px;
  border-color: #000;
  border-style: solid;
`
