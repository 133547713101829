import { Record, String, Array, Optional, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

type Links = {
  label: string
  url: string
}

export type SupportBannerSecondaryContainerProps = {
  props: {
    des: string
    complement: string
    links: Links[]
    styleMap?: StyleMap
  }
}

const LinksTypeGuard = Record({
  label: String,
  url: String
})

export const SupportBannerSecondaryContainerTypeGuard = Record({
  des: String,
  complement: Optional(String),
  links: Optional(Array(LinksTypeGuard)),
  styleMap: Optional(Unknown)
})
