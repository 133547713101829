import { useEffect, useState } from 'react'
import { YesObject } from '@yes.technology/react-toolkit'

import { useFetchYesObjects } from 'yesObject/shared'

type ImageDocumentProps = {
  document: YesObject[] | string
}

const useImageDocument = ({ document }: ImageDocumentProps) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const isDocumentObject = typeof document === 'object'
  const documentUuid = isDocumentObject && document?.[0]?.uuid

  const { yesObjects, isLoading, fetchYesObjects } = useFetchYesObjects({
    filterBody: {
      query: { $and: [{ 'uuid.des': { $eq: documentUuid || '' } }] },
      objectclass: window.idObjectclassDocument,
      orderby: [{ date_creation_row: 'desc' }],
      skip_metadata: true,
      project_fields: { uuid: 1, url: 1 }
    }
  })

  useEffect(() => {
    if (isDocumentObject) {
      fetchYesObjects()
      return
    }
    setImageUrl(document)
  }, [document, fetchYesObjects, isDocumentObject])

  useEffect(() => {
    if (!yesObjects.length) return
    const storageUrl = (yesObjects[0]?.url?.[0] as YesObject)?.des
    setImageUrl(storageUrl || '')
  }, [yesObjects])

  return {
    imageUrl,
    isLoading
  }
}

export default useImageDocument
