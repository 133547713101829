import apiAsync from 'infrastructure/shared/api/api-async'

export default async function fetchDocumentLink(uuid: string): Promise<string> {
  const response = await apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: `documents/${uuid}/download`,
    method: 'GET',
    showLoading: false
  })

  if (!response?.success) {
    throw new Error(response?.message)
  }

  return response?.message || ''
}
