import Chart from 'react-google-charts'

import { StyledGoogleChart } from '../Cockpit.styles'
import { ChartWrapperProps } from '../Cockpit.types'
import { SkeletonDots } from '@yes.technology/react-toolkit'
import { useState } from 'react'

const GoogleChart = ({
  chartModel,
  data,
  options,
  chartUuid,
  title
}: ChartWrapperProps) => {
  const [loaded, setLoaded] = useState(false)

  const handleLoad = () => {
    setLoaded(true)
  }

  return (
    <StyledGoogleChart {...{ chartModel }}>
      {loaded && <h2>{title}</h2>}
      <Chart
        chartType={chartModel}
        width={'100%'}
        height='400px'
        legendToggle
        data={data}
        options={options}
        mapsApiKey={window.googleCloudApikey}
        graphID={chartUuid}
        loader={<SkeletonDots />}
        onLoad={handleLoad}
        aria-label={title}
      />
    </StyledGoogleChart>
  )
}

export default GoogleChart
