import {
  FilterQuery,
  SkeletonDots,
  YesObject
} from '@yes.technology/react-toolkit'
import { useEffect } from 'react'

import { useSiteState } from 'siteState/shared'
import { useFetchYesObjects } from 'yesObject/shared'
import { useObjectclass } from 'objectclass/shared'
import Pagination from '../Pagination'
import GenericObjectCard from 'component/GenericObjectCard'
import ContactObjectCard from 'component/ContactObjectCard'
import TaskObjectCard from 'component/TaskObjectCard'
import { DataCardsProps } from './DataCards.types'
import CardContainer from './CardContainer'
import useClassification from './hooks/useClassification'
import ClassifiedList from './ClassifiedList'
import SectionDivider from '../SectionDivider'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const validCardModels: { [key: string]: React.FC<YesObject> } = {
  GenericObjectCard,
  TaskObjectCard,
  ContactObjectCard,
  CollapsibleObjectCard: GenericObjectCard
}

const DataCards = ({ props }: DataCardsProps) => {
  const {
    uuid_objectclass: objectclassUuid = '',
    uuid_filteraction: filteractionUuid,
    uuid_classification: classificationUuid,
    card_model: cardModel,
    show_pagination: showPagination = true,
    pagination_limit: paginationLimit = 12,
    card_props: cardProps,
    styleMap
  } = props

  const CardComponent = validCardModels[cardModel]

  const { classification, isLoading: isLoadingClassification } =
    useClassification({
      classificationUuid
    })

  const { objectclass, isLoading: isLoadingObjectClass } = useObjectclass({
    objectclassUuid
  })

  const [filterQuery] = useSiteState<FilterQuery>('filter-query')

  const styleProps = useStyleMaps(styleMap)

  const shouldRenderClassifiedList =
    classificationUuid && cardModel === 'CollapsibleObjectCard'

  const {
    yesObjects,
    isLoading: isLoadingObjects,
    pagination,
    fetchYesObjects,
    setPage
  } = useFetchYesObjects({
    initialPagination: { limit: paginationLimit, offset: 0 },
    filterBody: {
      query: filterQuery,
      objectclass: objectclassUuid
    },
    filteractionUuid,
    enabled: false
  })

  useEffect(() => {
    if (filterQuery || filteractionUuid) fetchYesObjects()
  }, [filterQuery, filteractionUuid, fetchYesObjects, pagination])

  const isLoading =
    isLoadingObjects || isLoadingObjectClass || isLoadingClassification

  const renderCardList = () => {
    if (isLoading && yesObjects?.length === 0) {
      return null
    }

    if (shouldRenderClassifiedList) {
      return (
        <ClassifiedList
          classifications={classification}
          objects={yesObjects}
          cardProps={cardProps}
          cardModel={cardModel}
        />
      )
    }

    return (
      <div className='row'>
        {yesObjects?.map((object: YesObject) => {
          return (
            <CardContainer key={object.uuid} cardType={cardModel}>
              <CardComponent {...object} />
            </CardContainer>
          )
        })}
      </div>
    )
  }

  const paginationElement = showPagination && (
    <Pagination pagination={pagination} setCurrentPage={setPage} />
  )

  const shouldDisableSection =
    yesObjects?.length === 0 && !filterQuery && !filteractionUuid

  return (
    <>
      <SectionDivider
        title={objectclass?.des || ''}
        centerHeaderElement={paginationElement || undefined}
        backgroundColor='#004099'
        disabled={shouldDisableSection}
        style={styleProps}
      >
        <div>
          {isLoading && (
            <div className='mt-3 d-flex justify-content-center'>
              <SkeletonDots />
            </div>
          )}

          {renderCardList()}
        </div>
      </SectionDivider>
    </>
  )
}

export default DataCards
