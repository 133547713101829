import { GridContainer } from 'shared/style/grid'
import styled from 'styled-components'

export const MapFilter = styled(GridContainer)`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 520px;
  padding-top: 16px;

  @media (max-width: 768px) {
    min-width: 100vw;
  }

  > div {
    flex-grow: 1;
  }

  button {
    width: 40px;
  }
`

export const PlaceAutocomplete = styled.div`
  width: 100%;
  height: 40px;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 2px solid #abbed1;
  background: #fff;

  input {
    color: rgb(48, 48, 50);
    outline: none;
    border: none;
    line-height: 20px;
    font-size: 14px;
    padding: 0px;
    width: 100%;
    margin-right: 6px;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: #abbed1;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
`

export const CardContent = styled.div`
  display: flex;
  padding-left: 6px;
  gap: 16px;
`

export const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #303032;
`

export const Grupoalvo = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #006bff;
`

export const Address = styled.address`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #303032;
  margin-bottom: 1rem;
`

export const Content = styled.div``

export const ImageContainer = styled.div`
  display: flex;
`

export const WorkingSchedule = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #303032;
  margin-bottom: 1rem;
`
