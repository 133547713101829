import { Button } from '@yes.technology/react-toolkit'
import { getBackgroundImage } from 'component/HeroBannerSecondary/HeroBannerSecondary.utils'
import styled from 'styled-components'

export const Container = styled.div<{
  leftBackgroundImageUrl?: string
  rightBackgroundImageUrl?: string
}>`
  background-color: #f7f8fa;
  padding: 0.5rem 1rem;

  @media (min-width: 768px) {
    padding: 1.25rem 0;
  }

  @media (min-width: 768px) {
    ${({ leftBackgroundImageUrl, rightBackgroundImageUrl }) =>
      getBackgroundImage(leftBackgroundImageUrl, rightBackgroundImageUrl)}
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
  }
`

export const ContentWrapper = styled.div`
  padding: 1.5rem 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    max-width: 1220px;
    flex-direction: column;
    margin: 0 auto;
    gap: 1.5rem;
    padding: 1rem 0;
  }
`

export const Text = styled.h2`
  color: #303032;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 32px;
    margin: auto;
  }
`

export const StyledButton = styled(Button)`
  margin-inline: auto;

  @media (min-width: 768px) {
    max-width: 584px;
  }
`

export const Link = styled.a`
  :hover {
    text-decoration: none;
  }

  :focus-visible {
    outline: none;
  }
`

export const LeftImage = styled.img`
  object-fit: contain;
  object-position: right;
`

export const RightImage = styled.img`
  object-fit: contain;
  object-position: left;
  margin-left: auto;
`
