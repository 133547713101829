import { PDFAreaLine, PDFCard } from '@yes.technology/react-toolkit'
import { View } from '@react-pdf/renderer'
import FilterHead from './components/FilterHead/FilterHead'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { GroupedFieldValues } from 'site/Renderer/components/FilterFields/types'
import FilterBody from './components/FilterBody/FilterBody'
import getStyles from './styles'

interface FilterAreaProps {
  fields: FilterFieldsResponse
  values: GroupedFieldValues
  title: string
  composition: string
}

function FilterArea({ fields, values, title, composition }: FilterAreaProps) {
  const styles = getStyles()

  const fieldByKey = Object.fromEntries(
    fields.classifiedFields.flat().map((field) => [field?.column_json, field])
  )

  return (
    <View>
      <PDFAreaLine des={title} heightClass='doubleXSmall' colorScheme='blue' />
      <View style={styles.mainContainer}>
        <PDFCard style={styles.mainCard}>
          <View>
            <FilterHead isSingleGroup={true} composition={composition} />
          </View>
          {Object.entries(values).map(([key, value]) => (
            <FilterBody
              key={`filterbody-${key}`}
              fields={fieldByKey}
              values={value}
              filterName={`Grupo de Filtros ${key}`}
            />
          ))}
        </PDFCard>
      </View>
    </View>
  )
}

export default FilterArea
