import apiAsync from 'infrastructure/shared/api/api-async'
import { FilterResponse } from 'sitemodel/shared/types'

export async function postObjectsFilter<ResponseType>(
  payload: unknown,
  showLoading: boolean
): Promise<FilterResponse<ResponseType>> {
  return await apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: 'objects/_filter',
    method: 'POST',
    obj: payload,
    showLoading
  })
}
