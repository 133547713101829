import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;

  @media (min-width: 768px) {
    background: #f7f8fa;
    padding: 1.25rem 0;
    gap: 1.25rem;
  }
`

export const Navigation = styled.nav`
  background: #ffffff;
  padding: 0.5rem 0;

  @media (min-width: 768px) {
    border: 1px solid #c9c9cb;
    padding: 0rem 3.75rem;
  }
`

export const NavigationWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;

  h2 {
    display: none;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 4fr 1fr;

    h2 {
      display: block;
    }
  }

  @media (min-width: 1921px) {
    width: 1920px;
    margin: 0 auto;
  }
`

export const NavigationSliders = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    > :nth-child(2) {
      margin-top: -1px;
    }
  }

  @media (min-width: 768px) {
    justify-content: center;
    flex-direction: row;
    gap: 24px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  margin: 0 1rem 0;
  border: 1px solid #c9c9cb;
  border-radius: 0.5rem;
  background: #ffffff;

  h2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
  }

  > a {
    max-width: 584px;
    margin: auto;
  }

  @media (min-width: 768px) {
    gap: 1.25rem;
    padding: 1.25rem 4rem;

    h2 {
      font-size: 48px;
      line-height: 56px;
    }
  }

  @media (min-width: 768px) and (max-width: 1920px) {
    width: calc(100% - 120px);
    margin: 0 60px;
  }

  @media (min-width: 1921px) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1279px) {
    h2 > span > span span:last-of-type {
      white-space: normal !important;
      display: block;
    }
  }
`

export const ContentMiddle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
`

export const ImageWrapper = styled.div``

export const Image = styled.div`
  height: 160px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  @media (min-width: 768px) {
    height: 320px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`

export const RightText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  @media (min-width: 768px) {
    gap: 1rem;
  }
`

export const BulletPoints = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  margin-bottom: 0;

  li {
    list-style: none;
    display: flex;
    gap: 16px;
  }

  @media (min-width: 768px) {
    gap: 1rem;

    & li > figure:first-child {
      margin-top: 0.5rem;
    }
  }
`

export const ContentText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Complement = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
`
