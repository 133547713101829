import {
  Field,
  Objectclass,
  RelationClass
} from '@yes.technology/react-toolkit'
import { useObjectclass } from 'objectclass/shared'
import { useMemo } from 'react'
import {
  FieldValues,
  FilterAreaLayout
} from 'site/Renderer/components/FilterFields/types'
import useYesObject from 'yesObject/shared/hooks/useYesObject'
import useClassifiedFields from './useClassifiedFields'
import {
  isSituationField,
  isSpecialRelationFieldByColumnJson,
  situationInitialValue,
  specialRelationFields
} from 'shared/utils/specialRelationFields/specialRelationFields'
import { updateDisplayText } from 'shared/utils'
import { FieldSettings } from 'shared/types'

type UseFieldsWithRelationsProps = {
  objectclass?: Objectclass
}

export const useFieldsWithRelations = ({
  objectclass
}: UseFieldsWithRelationsProps): Field[] => {
  const fields = useMemo(() => {
    const relationClasses = Object.values(
      objectclass?.relation_classes || {}
    ) as RelationClass[]
    return Object.values(objectclass?.fields || {}).map((field) => {
      if (
        field?.column_json &&
        isSpecialRelationFieldByColumnJson(field?.column_json)
      ) {
        return {
          ...field,
          type: 'RELATION',
          relation_objectclass_uuid: specialRelationFields.filter(
            (specialField) => specialField.columnJson === field.column_json
          )?.[0]?.objectclassUuid
        }
      }
      if (field?.type === 'RELATION') {
        return {
          ...field,
          relation_objectclass_uuid: relationClasses?.filter(
            (relation) => relation.column_json === field?.column_json
          )?.[0]?.uuid_objectclass
        }
      }
      return field
    }) as Field[]
  }, [objectclass])

  return fields
}

type UseInitialFieldValuesProps = {
  classifiedFields: (Field | undefined)[][]
}

const useInitialFieldValues = ({
  classifiedFields
}: UseInitialFieldValuesProps) => {
  const initialFieldValues: FieldValues = useMemo(() => {
    const values: FieldValues = {}

    for (const field of classifiedFields.flat()) {
      if (field && field.uuid) {
        values[field.column_json] = {
          operator: field.type === 'RELATION' ? 'in' : 'eq',
          value: isSituationField(field?.column_json)
            ? [situationInitialValue]
            : '',
          type: field.type
        }
      }
    }

    return values
  }, [classifiedFields])

  return initialFieldValues
}

type UseFilterFieldsProps = {
  objectclassUuid: string
  classificationUuid?: string
  filterAreaLayout?: FilterAreaLayout
  fieldSettings?: FieldSettings
}

export type FilterFieldsResponse = {
  initialFieldValues: FieldValues
  classifiedFields: Field[][]
  objectclass: Objectclass | undefined
  isLoading: boolean
}

const useFilterFields = ({
  objectclassUuid,
  classificationUuid = '',
  filterAreaLayout,
  fieldSettings
}: UseFilterFieldsProps): FilterFieldsResponse => {
  const { objectclass, isLoading: isLoadingObjectclass } = useObjectclass({
    objectclassUuid
  })

  const updatedObjectClass = useMemo(() => {
    if (!objectclass) {
      return undefined
    }

    const updatedFields = updateDisplayText(
      objectclass?.fields || {},
      fieldSettings
    )
    return {
      ...objectclass,
      fields: updatedFields
    }
  }, [fieldSettings, objectclass])

  const fields = useFieldsWithRelations({
    objectclass: updatedObjectClass
  })

  const { yesObject: classification, isLoading: isLoadingClassification } =
    useYesObject({
      objectUUID: classificationUuid,
      objectclassUUID: 'UUID_Objectclass_classification',
      skipRelations: false
    })

  const classificationContent = classification?.classification_content as
    | string
    | undefined

  const classifiedFields = useClassifiedFields({
    fields,
    classificationContent,
    sectionsLayout: filterAreaLayout?.filter_sections
  })

  const initialFieldValues = useInitialFieldValues({ classifiedFields })

  const response: FilterFieldsResponse = useMemo(
    () => ({
      initialFieldValues,
      classifiedFields,
      objectclass,
      isLoading: isLoadingObjectclass || isLoadingClassification
    }),
    [
      initialFieldValues,
      classifiedFields,
      objectclass,
      isLoadingObjectclass,
      isLoadingClassification
    ]
  )

  return response
}

export default useFilterFields
