export function getInitials(name: string): string {
  const parts = name.split(' ')
  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase()
  }

  const first = parts[0][0].toUpperCase()
  const last = parts[parts.length - 1][0].toUpperCase()

  return `${first}${last}`
}
