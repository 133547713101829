import { TopAreaPositionType } from 'site/shared/types'
import { Breadcrumbs } from '@yes.technology/react-toolkit'
import { Link } from 'react-router-dom'

const BreadcrumbsArea = ({ routes }: Pick<TopAreaPositionType, 'routes'>) => {
  const linkWrapper = ({
    children,
    url
  }: {
    children?: React.ReactNode
    url: string
  }) => (
    <Link to={url} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  )

  const breadcrumbs = (routes || []).map((route) => ({
    description: route.des,
    url: route.link
  }))

  return <Breadcrumbs routes={breadcrumbs} linkWrapper={linkWrapper} />
}

export default BreadcrumbsArea
