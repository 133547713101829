import { useMemo } from 'react'

import { componentsPaths, componentsTypeGuard } from './components'
import { Component } from 'site/shared/types'
import { sortByKey } from 'shared/utils/array/sort'
import useInterpolateProps from '../../sitemodel/shared/hooks/useInterpolateProps/useInterpolateProps'
import TypeGuard from './wrappers/TypeGuard'
import { applyStylesToProps } from 'shared/utils/applyStylesToProps'
import { cloneDeep, merge } from 'lodash'

type LoadComponentProps = {
  components: Component[]
}

const LoadComponent = ({ components }: LoadComponentProps) => {
  const { getProps } = useInterpolateProps()

  const memoizedPropsArray = useMemo(() => {
    const sortedComponents = sortByKey(components, 'order')

    return sortedComponents.map((component: Component) => {
      let props = getProps({
        props: cloneDeep(component.props),
        props_params: component.props_params || {},
        callbacks: component.callbacks || {}
      })

      if (component?.props_styles) {
        props = applyStylesToProps(props, component.props_styles)
      }

      props = merge(
        props,
        { elementUuid: component.uuid },
        props.components
          ? {
              children: (
                <LoadComponent components={props.components as Component[]} />
              )
            }
          : {}
      )

      return {
        key: component.uuid,
        LazyComponent: componentsPaths[component.des],
        typeGuard: componentsTypeGuard[component.des],
        props
      }
    })
  }, [components, getProps])

  if (!components?.length) return null

  return (
    <>
      {memoizedPropsArray.map(({ key, LazyComponent, typeGuard, props }) => {
        if (!LazyComponent || props?.hidden === 'true') return null

        return (
          <TypeGuard props={props} type={typeGuard} key={key}>
            <LazyComponent props={props} />
          </TypeGuard>
        )
      })}
    </>
  )
}

export default LoadComponent

// todo
export const Loading = () => <div>loading...</div>
