import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c9c9cb;

  @media (min-width: 768px) {
    max-width: 608px;
    border: unset;
  }
`

export const SliderButton = styled.button<{ $color?: string }>`
  background-color: transparent;
  border: none;
  padding: 0;
`

export const CenterText = styled.div<{ $color?: string }>`
  text-align: center;
  color: ${(props) => props.$color || '#303032'};
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
`
