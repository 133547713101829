import { createContext, useContext } from 'react'

type KanbanContextValue = {
  onStatusChange: (taskUuid: string, newStatusColumn: string) => void
}

const defaultValue = {
  onStatusChange: () => undefined
}

export const KanbanContext = createContext<KanbanContextValue>(defaultValue)

export default function useKanbanContext() {
  const contextValue = useContext(KanbanContext)

  return contextValue
}
