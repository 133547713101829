export function generateSummary(
  htmlText: string,
  maxLength: number | undefined
): string {
  const plainText = htmlText.replace(/<[^>]*>/g, '')

  if (!maxLength) {
    return plainText
  }

  return plainText.length <= maxLength
    ? plainText
    : `${plainText.substring(0, maxLength).trim()}...`
}
