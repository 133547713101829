import { useCallback } from 'react'
import { useSiteState } from 'siteState/shared'
import { ComponentPropsType } from 'sitemodel/shared/types/sitemodel.type'

export default function useSitemodelState() {
  const [sitemodelState, setSitemodelState] =
    useSiteState<ComponentPropsType>('sitemodel')

  const mergeSitemodelState = useCallback(
    (sitemodelStateValue?: ComponentPropsType) => {
      setSitemodelState((prevState: ComponentPropsType) => ({
        ...prevState,
        ...sitemodelStateValue
      }))
    },
    [setSitemodelState]
  )

  return { sitemodelState, mergeSitemodelState }
}
