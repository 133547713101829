import { FilteractionObject } from '@yes.technology/react-toolkit'
import { UseQueryResult, useQueries } from '@tanstack/react-query'
import { fetchFilteraction as defaultFetchFilteraction } from 'filteraction/FilteractionResolver'
import { useMemo } from 'react'
import { SitemodelManifestStateGrouped } from 'sitemodel/shared/types/sitemodel.type'

type UseFetchFilteractionDescriptorsProps = {
  stateFilteractions: SitemodelManifestStateGrouped['filteraction']
  fetchFilteraction?: (
    filteractionId: string,
    showSystemLoader: boolean
  ) => Promise<FilteractionObject>
}

export default function useFetchFilteractionDescriptors({
  stateFilteractions,
  fetchFilteraction = defaultFetchFilteraction
}: UseFetchFilteractionDescriptorsProps): Record<
  string,
  UseQueryResult<FilteractionObject>
> {
  const filteractionIds = useMemo(
    () =>
      Object.values(stateFilteractions).map(
        (stateFilteraction) => stateFilteraction.filteraction
      ),
    [stateFilteractions]
  )

  const resultsById = useQueries({
    queries: filteractionIds.map((id) => ({
      queryKey: ['filteraction-descriptor', id],
      queryFn: () => fetchFilteraction(id, false),
      enabled: !!id
    })),
    combine: (results) =>
      Object.fromEntries(
        results.map((result, index) => [filteractionIds[index], result])
      )
  })

  return resultsById
}
