import { Style } from '@react-pdf/types'

const mainContainer: Style = {
  width: '100%',
  flexDirection: 'row'
}

const firstItem: Style = {
  width: '50%',
  marginRight: 4
}

const midItem: Style = {
  // flexGrow: 1,
  marginRight: 4
}

const compositionField: Style = {
  height: 34
}

const compositionLabel: Style = {
  fontFamily: 'Barlow',
  fontSize: 8,
  fontWeight: 600,
  marginBottom: 8,
  color: '#303032',
  fontStyle: 'normal'
}

const compositionGroupContent: Style = {
  height: 20,
  borderTopWidth: 2,
  borderColor: '#ABBED1',
  alignItems: 'center',
  flexDirection: 'row',
  fontFamily: 'Barlow',
  fontSize: 8
}

const spacingFilterGroup: Style = {
  marginRight: 2
}

export default function getStyles() {
  return {
    mainContainer,
    firstItem,
    midItem,
    compositionLabel,
    compositionField,
    compositionGroupContent,
    spacingFilterGroup
  }
}
