import { LinkButton, useIsMobile } from '@yes.technology/react-toolkit'
import {
  Container,
  Content,
  Title,
  Subtitle,
  ButtonsWrapper,
  HeroImage
} from './HeroBanner.styles'
import { HeroBannerProps } from './HeroBanner.types'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'

const HeroBanner = ({
  des,
  complement,
  buttons,
  backgroundImageUrl,
  backgroundImageUrlMobile,
  imageAlt,
  backgroundPositionDesktop = 'center bottom',
  backgroundPositionMobile = 'top center',
  desColor = '#646E82',
  complementColor = '#646E82'
}: HeroBannerProps) => {
  const hasContent = !!des || !!complement || !!buttons

  const isMobile = useIsMobile()

  const breakpoint = useBreakpoint()

  if (!hasContent) {
    return (
      <HeroImage
        src={
          isMobile && backgroundImageUrlMobile
            ? backgroundImageUrlMobile
            : backgroundImageUrl
        }
        alt={imageAlt}
        breakpoint={breakpoint}
      />
    )
  }

  return (
    <Container
      bg={backgroundImageUrl}
      bgPosition={{ backgroundPositionDesktop, backgroundPositionMobile }}
      breakpoint={breakpoint}
    >
      <Content>
        <Title color={desColor}>{des}</Title>
        <Subtitle color={complementColor}>{complement}</Subtitle>
        {buttons && (
          <ButtonsWrapper>
            {buttons.map(({ label, href, target = '_self' }, index) => (
              <LinkButton
                key={`ButtonHeroBanner-${index}`}
                href={href}
                variant='secondary'
                heightClass='large'
                rel='noopener noreferrer'
                {...{ target }}
              >
                {label}
              </LinkButton>
            ))}
          </ButtonsWrapper>
        )}
      </Content>
    </Container>
  )
}

export default HeroBanner
