export default function ArrowsUpAndDown() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 48 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.73 0.640137C14.4111 0.640137 15.0615 0.919381 15.525 1.41083L26.1395 12.666C27.06 13.642 27.0026 15.1681 26.0112 16.0744C25.0199 16.9808 23.47 16.9243 22.5495 15.9482L16.1795 9.19372L16.1795 30.3858C16.1795 31.7178 15.0828 32.7976 13.73 32.7976C12.3772 32.7976 11.2805 31.7178 11.2805 30.3858L11.2805 9.19372L4.9105 15.9482C3.98997 16.9243 2.4401 16.9808 1.44876 16.0744C0.457423 15.1681 0.40002 13.642 1.32055 12.666L11.935 1.41083C12.3985 0.91938 13.0489 0.640137 13.73 0.640137Z'
        fill='#303032'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.268 45.3594C33.5869 45.3594 32.9365 45.0845 32.4731 44.6007L21.8586 33.5215C20.9381 32.5606 20.9955 31.0584 21.9868 30.1662C22.9781 29.274 24.528 29.3297 25.4485 30.2905L31.8186 36.9394L31.8186 16.0785C31.8186 14.7673 32.9152 13.7043 34.2681 13.7043C35.6209 13.7043 36.7175 14.7673 36.7175 16.0785L36.7175 36.9394L43.0876 30.2905C44.0081 29.3297 45.558 29.274 46.5493 30.1662C47.5406 31.0584 47.598 32.5606 46.6775 33.5215L36.063 44.6007C35.5995 45.0845 34.9492 45.3594 34.268 45.3594Z'
        fill='#303032'
      />
    </svg>
  )
}
