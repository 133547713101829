import React from 'react'
import { Link } from 'react-router-dom'

import { RepresentationEnum } from 'site/shared/enums'
import { TopAreaPositionType } from 'site/shared/types'
import LogoArea from './LogoArea'

declare const require: any

const Position: React.FunctionComponent<TopAreaPositionType> = (
  props
): JSX.Element | null => {
  const { representation, des, link, component } = props

  const handleRepresentation = () => {
    switch (representation) {
      case RepresentationEnum.LogoArea:
        return <LogoArea {...props} />
      case RepresentationEnum.Component:
        if (component) {
          const TheComponent = require(`./${component}`).default
          return <TheComponent {...props} />
        }
        return null
      case RepresentationEnum.Des:
        return <span className='nav-link h6 mb-0 text-dark'>{des}</span>
      case RepresentationEnum.Link:
        if (link) {
          return (
            <Link to={link} className='nav-link h6 mb-0'>
              {des}
            </Link>
          )
        }
        return null
      default:
        return null
    }
  }

  return handleRepresentation()
}

export default Position
