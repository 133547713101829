import { Taskmodel } from 'task/shared'

type ActionHandlerType = {
  object_uuid_source_container_property_name: string
}

type DataContainerType = {
  [key: string]: string
}

export type ActionHandlerProps = {
  taskmodel?: Taskmodel
  workflowUUID?: string
  dataContainer: DataContainerType
}

const getInteractionModelUuid = (taskmodel: Taskmodel) =>
  taskmodel.interactionmodel_actionhandler?.interactionmodel_uuid

const getObjectUuid = (
  actionhandler: ActionHandlerType,
  dataContainer: DataContainerType
) => {
  return actionhandler !== undefined
    ? dataContainer[actionhandler.object_uuid_source_container_property_name]
    : null
}

const useUrlByActionHandler = ({
  taskmodel,
  workflowUUID,
  dataContainer
}: ActionHandlerProps): string => {
  if (!taskmodel) return ''

  if (taskmodel.interactionmodel_actionhandler) {
    return `interaction/${workflowUUID}/${getInteractionModelUuid(taskmodel)}`
  } else if (taskmodel.waitforsituation_actionhandler) {
    return `masterdata/${workflowUUID}/${getObjectUuid(
      taskmodel.waitforsituation_actionhandler,
      dataContainer
    )}`
  } else if (taskmodel.waitforstatus_actionhandler) {
    return `masterdata/${workflowUUID}/${getObjectUuid(
      taskmodel.waitforstatus_actionhandler,
      dataContainer
    )}`
  }

  return ''
}

export default useUrlByActionHandler
