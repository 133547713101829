// TODO convert to Typescript
// TODO compare this toYesFilter to the one in app/src/site/Renderer/components/FilterFields/shared/FilterLanguage.ts and update if needed

import { isSpecialRelationFieldByObjectclass } from 'shared/utils/specialRelationFields/specialRelationFields'
import { makeDesFilter } from '../FilterLanguage'
import { datepickerToISOFormat } from '@yes.technology/react-toolkit'
import XRegExp from 'xregexp'
import cloneDeep from 'lodash/cloneDeep'

const operatorsMapping = {
  gt: '$gt',
  gte: '$gte',
  lt: '$lt',
  lte: '$lte',
  in: '$in',
  nin: '$nin',
  eq: '$eq',
  ne: '$ne',
  REGEX: '$regex'
}

// Validar se o operador é compatível com os valores selecionados
//
// Não podemos utilizar o operador 'in' com uma 'string', por exemplo
function areOperatorAndValuesCompatibles(operator, values) {
  let validOperators = {}
  if (typeof values === 'string') {
    validOperators = {
      gt: true,
      gte: true,
      lt: true,
      lte: true,
      eq: true,
      ne: true,
      REGEX: true,
      filteraction: true
    }
  } else if (Array.isArray(values)) {
    validOperators = {
      in: true,
      nin: true
    }
  }

  if (operator in validOperators) {
    return true
  }
  return false
}

// O parâmetro items é um array contendo objetos com
// uuid e des. Precisamos apenas retornar o array contendo os ids
function makeIdsFilter(items) {
  return items.map((i) => i.uuid)
}

export function makeFilter(values) {
  const group = {}

  const isValid = Object.keys(values).every((key) => {
    // Filtro cru proveniente do componente visual
    const raw = cloneDeep(values[key])

    // campos válidos dos filtros e não vazios
    if (key === 'groupType' || !raw.value || raw.value?.length === 0) {
      return true
    }

    // A propriedade "values" pode ser uma string no caso de busca
    // por descrição, ou um array no caso de busca por ids
    let filterContent
    let property

    if (!areOperatorAndValuesCompatibles(raw.operator, raw.value)) {
      return false
    }

    if (raw.operator === 'filteraction') {
      property = 'uuid'
      filterContent = makeDesFilter(raw.operator, raw.value)
    } else if (typeof raw.value === 'string') {
      const formattedValue =
        raw.type === 'DATETIME'
          ? datepickerToISOFormat(raw.value, 'pt-BR')
          : raw.value
      property = 'des'
      filterContent = makeDesFilter(raw.operator, formattedValue || '')
    } else if (
      typeof raw.value?.[0]?.uuid_Objectclass === 'string' &&
      isSpecialRelationFieldByObjectclass(raw.value?.[0]?.uuid_Objectclass)
    ) {
      if (raw.value.length === 1) {
        raw.operator = raw.operator === 'in' ? 'eq' : 'ne'
        raw.type = 'STRING'
        raw.value = raw.value[0].code
      } else {
        raw.value = raw.value.map((v) => v.code)
      }
      property = 'des'
      filterContent = makeDesFilter(raw.operator, raw.value)
    } else {
      property = 'uuid'
      filterContent = makeIdsFilter(raw.value)
    }

    const filterType = {}
    filterType[operatorsMapping[raw.operator]] = filterContent
    group[key + '.' + property] = filterType

    return true
  })

  if (!isValid) {
    return false
  }

  return {
    $and: [group]
  }
}

// Retorna uma matriz de strings de correspondência entre os delimitadores
// mais externos à esquerda e à direita ou uma matriz de objetos com partes
// de correspondência detalhadas e dados de posição.
function splitComposition(composition) {
  return XRegExp.matchRecursive(composition, '\\(', '\\)', 'gi', {
    valueNames: ['unmatch', null, 'match', null]
  })
}

function validParentheses(composition) {
  let depth = 0
  for (const i in composition) {
    if (composition[i] === '(') {
      depth++
    } else if (composition[i] === ')') {
      depth--
    }
    if (depth < 0) return false
  }
  if (depth > 0) return false
  return true
}

// TODO: Comentários e refatoração
function validateLogicalStructure(composition) {
  const parts = splitComposition(composition)
  const len = parts.length

  if (len === 0) {
    // validar 1 OR 2 OR 3
    // validar: 1
    if (
      !/^(\s*(([1-9]\d*)+[\s+]+[a-z]+[\s+]+([1-9]\d*)|[1-9]\d*)(\s+|$))+$/i.test(
        composition.trim()
      )
    ) {
      return {
        success: false,
        message: `Composição inválida em: ${composition}`
      }
    }
  } else if (len === 1) {
    return validateLogicalStructure(parts[0].value.trim())
  } else if (len === 2) {
    if (parts[1].name === 'unmatch') {
      if (!/^(\s*([a-z]+[\s+]+[1-9]\d*))$/i.test(parts[1].value.trim())) {
        return {
          success: false,
          message: `Composição inválida em: ${parts[1].value.trim()}`
        }
      }
      return validateLogicalStructure(parts[0].value.trim())
    }

    if (parts[0].name === 'unmatch' && parts[0].value !== '') {
      if (!/^(\s*(([1-9]\d*)+[\s+]+[a-z]+))$/i.test(parts[0].value.trim())) {
        return {
          success: false,
          message: `Composição inválida em: ${parts[0].value.trim()}`
        }
      }
      return validateLogicalStructure(parts[1].value.trim())
    }
  } else if (len > 2) {
    // não permitir mais de 2 operadores por nível
    return {
      success: false,
      message: 'Permitido apenas 2 operadores por nível'
    }
  }

  return {
    success: true
  }
}

// exemplo de validação: (3 OR (((1) OR 4) AND 2))
export function validateComposition(composition, totalGroup) {
  if (composition === '') {
    return { success: false, message: 'Composição Filtro é obrigatório' }
  }

  if (!validParentheses(composition)) {
    return { success: false, message: 'Parenteses inválidos' }
  }

  const compositionWithoutParentheses = composition.replace(/[()]/g, '')

  if (!/^(\s*(OR|AND|\d+|\s+)(\s+|$))+$/i.test(compositionWithoutParentheses)) {
    return { success: false, message: 'Operador não permitido' }
  }

  const onlyNumbers = compositionWithoutParentheses
    .replace(/\s/g, ';')
    .replace(/[^0-9]/g, ';')
  const listGroups = onlyNumbers.split(';').filter((el) => el !== '')
  const listUniqueGroups = new Set(listGroups)

  if (listUniqueGroups.size !== listGroups.length) {
    return { success: false, message: 'Não é permitido repetir grupo' }
  }

  const doNotExist = []
  for (const group of listUniqueGroups) {
    if (Number(group) > totalGroup) {
      doNotExist.push(Number(group))
    }
  }
  if (doNotExist.length) {
    return {
      success: false,
      message: `Grupo${doNotExist.length > 1 ? 's' : ''} ${doNotExist.join(
        ', '
      )} não existe${doNotExist.length > 1 ? 'm' : ''}`
    }
  }

  return validateLogicalStructure(composition)
}

// Interpreta o campo "Composição Filtro" e converte para linguagem de
// filtros de API (baseada em MongoDB)
export function interpretFiltersComposition(groups, composition) {
  let query = {}

  // Matriz de correspondências ou uma matriz vazia.
  const parts = splitComposition(composition)
  const len = parts.length

  if (len === 0) {
    const values = composition.trim().split(' ')

    if (values.length === 1) {
      query = makeFilter(groups[composition])
    } else {
      query[`$${values[1].toLowerCase()}`] = [makeFilter(groups[values[0]])]
      query[`$${values[1].toLowerCase()}`].push(makeFilter(groups[values[2]]))
    }
  } else if (len === 1) {
    return interpretFiltersComposition(groups, parts[0].value)
  } else if (len > 1) {
    if (parts[0].name === 'match') {
      const [operator, group] = parts[1].value.trim().split(' ')
      query[`$${operator.toLowerCase()}`] = [makeFilter(groups[group])]
      query[`$${operator.toLowerCase()}`].push(
        interpretFiltersComposition(groups, parts[0].value)
      )
    }

    if (parts[0].name === 'unmatch') {
      const [group, operator] = parts[0].value.trim().split(' ')
      query[`$${operator.toLowerCase()}`] = [makeFilter(groups[group])]
      query[`$${operator.toLowerCase()}`].push(
        interpretFiltersComposition(groups, parts[1].value)
      )
    }
  }

  return query
}

export function toYesFilter(groups, composition) {
  const { success } = validateComposition(
    composition,
    Object.keys(groups).length
  )
  if (!success) {
    return false
  }
  return interpretFiltersComposition(groups, composition)
}
