import { DynamicTextHighlight } from '@yes.technology/react-toolkit'

import { DynamicTextHighlightContainerProps } from './DynamicTextHighlightContainer.types'
import { useSitemodelState } from 'sitemodel/shared/hooks'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const DynamicTextHighlightContainer = ({
  props
}: DynamicTextHighlightContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)
  const style = { marginBottom: '80px', ...styleProps }

  const { sitemodelState } = useSitemodelState()

  const complement = {
    baseText: props.baseText,
    interpolationItems: [
      {
        value: String(sitemodelState?.[props.getState]),
        color: '#006BFF'
      }
    ]
  }

  return (
    <div {...{ style }}>
      <DynamicTextHighlight {...complement} />
    </div>
  )
}

export default DynamicTextHighlightContainer
