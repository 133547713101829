import { Button } from '@yes.technology/react-toolkit'

interface DirectionsButtonProps {
  origin: google.maps.LatLngLiteral
  destination: google.maps.LatLngLiteral
}

export const DirectionsButton = ({
  origin,
  destination
}: DirectionsButtonProps) => {
  const openMaps = () => {
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}`
    const appleMapsUrl = `http://maps.apple.com/?saddr=${origin.lat},${origin.lng}&daddr=${destination.lat},${destination.lng}`

    const userAgent = navigator.userAgent || window.opera

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open(appleMapsUrl, '_blank')
    } else {
      window.open(googleMapsUrl, '_blank')
    }
  }

  return (
    <Button variant='secondary' onClick={openMaps}>
      Ver Rota
    </Button>
  )
}
