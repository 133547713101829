import { useIsMobile } from '@yes.technology/react-toolkit'
import { Link } from 'react-router-dom'

import { ResponsiveImageObjectType } from 'site/shared/types'

type LogoAreaProps = {
  uuid?: string
  des?: string
  link?: string
  image?: string | ResponsiveImageObjectType
  alt?: string
  title?: string
}

const LogoArea = ({ link, image, alt, title }: LogoAreaProps) => {
  const isMobile = useIsMobile()

  if (!link || !image) return null

  const isResponsiveImageObject = typeof image === 'object'

  const imageUrl = isResponsiveImageObject
    ? isMobile
      ? image.mobile?.src
      : image.desktop?.src
    : image

  const imageAlt = isResponsiveImageObject
    ? isMobile
      ? image.mobile?.alt
      : image.desktop?.alt
    : alt

  return (
    <Link to={link} className='navbar-brand d-flex align-items-center'>
      <img
        src={imageUrl}
        alt={imageAlt}
        title={title}
        style={{ height: '24px' }}
      />
    </Link>
  )
}
export default LogoArea
