import styled from 'styled-components'

export const StyledContainer = styled.div<{ $isLoading: boolean }>`
  ${({ $isLoading }) =>
    $isLoading
      ? `
        div > div {
          display: flex;
          align-items: center;

          a > div > div {
            background-color: #fff;
          }
        }
`
      : ``}
`
