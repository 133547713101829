import { Icon, YesObject } from '@yes.technology/react-toolkit'
import { useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import Report from 'report'

export type UseObjectViewReportsProps = {
  isFetching: boolean
  yesObject?: YesObject
  filterQuery?: object
  informational?: boolean
}

export default function useObjectViewReports({
  isFetching,
  yesObject,
  filterQuery,
  informational = false
}: UseObjectViewReportsProps) {
  const [singleReportTriggered, setSingleReportTriggered] = useState(false)
  const [singleReportUrl, setSingleReportUrl] = useState('')
  const [batchReportTriggered, setBatchReportTriggered] = useState(false)
  const [batchReportUrl, setBatchReportUrl] = useState('')
  const [mergedReportTriggered, setMergedReportTriggered] = useState(false)
  const [mergedReportUrl, setMergedReportUrl] = useState('')

  if (isFetching) {
    singleReportTriggered && setSingleReportTriggered(false)
    singleReportUrl && setSingleReportUrl('')
    batchReportTriggered && setBatchReportTriggered(false)
    batchReportUrl && setBatchReportUrl('')
    mergedReportTriggered && setMergedReportTriggered(false)
    mergedReportUrl && setMergedReportUrl('')
  }

  const singleIcon = <Icon iconName='PDFFile' icSize='large' icColor='white' />
  const batchIcon = (
    <Icon iconName='PDFFileBatch' icSize='large' icColor='white' />
  )
  const mergedIcon = (
    <Icon iconName='PDFFileCompiled' icSize='large' icColor='white' />
  )

  const { t } = useTranslation()
  const singleReportTitle = t('object-view.download-single-report')
  const batchReportTitle = t('object-view.download-batch-report')
  const mergedReportTitle = t('object-view.download-merged-report')

  const buttonClasses = 'btn btn-link p-0'

  const reportButtons =
    !informational && !isFetching && filterQuery ? (
      <div className='d-flex gap-3 p-3'>
        {singleReportUrl ? (
          <a
            title={singleReportTitle}
            className={buttonClasses}
            href={singleReportUrl}
            download='report.pdf'
          >
            {singleIcon}
          </a>
        ) : (
          <button
            title={singleReportTitle}
            className={buttonClasses}
            onClick={() => setSingleReportTriggered(true)}
          >
            {singleIcon}
          </button>
        )}
        {batchReportUrl ? (
          <a
            title={batchReportTitle}
            className={buttonClasses}
            href={batchReportUrl}
            download='report.zip'
          >
            {batchIcon}
          </a>
        ) : (
          <button
            title={batchReportTitle}
            className={buttonClasses}
            onClick={() => setBatchReportTriggered(true)}
          >
            {batchIcon}
          </button>
        )}
        {mergedReportUrl ? (
          <a
            title={mergedReportTitle}
            className={buttonClasses}
            href={mergedReportUrl}
            download='report.pdf'
          >
            {mergedIcon}
          </a>
        ) : (
          <button
            title={mergedReportTitle}
            className={buttonClasses}
            onClick={() => setMergedReportTriggered(true)}
          >
            {mergedIcon}
          </button>
        )}
      </div>
    ) : undefined

  const singleReport = singleReportTriggered && (
    <Report
      reportType='card'
      downloadType='single-pdf'
      splitThreshold={1}
      onUrlReady={setSingleReportUrl}
      {...{ yesObject }}
    />
  )

  const batchReport = batchReportTriggered && (
    <Report
      reportType='card'
      downloadType='multiple-pdf-zip'
      splitThreshold={1}
      onUrlReady={setBatchReportUrl}
    />
  )

  const mergedReport = mergedReportTriggered && (
    <Report
      reportType='card'
      downloadType='single-pdf'
      onUrlReady={setMergedReportUrl}
    />
  )

  return {
    singleReport,
    batchReport,
    mergedReport,
    reportButtons
  }
}
