import { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import {
  ChartWrapperOptions,
  GoogleChartWrapperChartType
} from 'react-google-charts'

import { StyledGoogleChart } from '../Cockpit.styles'
import { DataChart } from './ChartWrapper'
import { getContrastColor } from '../utils/color'

interface BarChartProps {
  chartModel: GoogleChartWrapperChartType
  data: DataChart
  options: ChartWrapperOptions['options']
  title: string
}

interface RowProps {
  item: string
  barColor: string
  percentage: number
  value: number
  contrastColor: string
}

const BarChartArea = styled.div`
  padding-top: 65px;
  padding-bottom: 25px;
  font-size: 12px;
  height: 100%;
`

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
`

const TBody = styled.tbody``

const TRow = styled.tr``

const VerticalAxisCell = styled.td`
  width: 50%;
  height: 20px;
  text-align: right;
  font-weight: 600;
`

const HorizontalAxisCell = styled.td`
  width: 50%;
  height: 20px;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ccc;
`

const BarContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const fillAnimation = (width: number) => keyframes`
  from {
    width: 0%;
  }
  to {
    width: ${width}%;
  }
`

const Bar = styled.div<{ width: number; backgroundColor: string }>`
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.width}%;
  animation: ${(props) => css`
    ${fillAnimation(props.width)} 1s ease-out forwards
  `};
`

const Value = styled.span<{ contrastColor: string; leftPosition: number }>`
  position: absolute;
  left: ${(props) => props.leftPosition}px;
  /*transform: translateX(5px);*/
  z-index: 1;
  color: ${(props) => props.contrastColor};
  font-weight: 600;
  white-space: nowrap;
`

const BarChart = ({ chartModel, data, options, title }: BarChartProps) => {
  const { colors } = options || {}

  const barColor = colors?.[0] || '#006BFF'

  const contrastColor = getContrastColor(barColor)

  const labels = data.slice(1).map((item) => item[0])
  const values = data.slice(1).map((item) => Number(item[1]))

  const verticalAxis = {
    title: data[0][0],
    values: labels
  }

  const horizontalAxis = {
    title: data[0][1],
    values: values
  }

  const minValue = Math.min(...values)
  const maxValue = Math.max(...values)

  const setPercentage = (value: number) => {
    return ((value - minValue) / (maxValue - minValue)) * 100
  }

  return (
    <StyledGoogleChart {...{ chartModel }}>
      <h2>{title}</h2>

      <BarChartArea>
        <Table>
          <TBody>
            {verticalAxis.values.map((item, index) => {
              const percentage = setPercentage(horizontalAxis.values[index])
              return (
                <Row
                  key={index}
                  {...{ item, barColor, percentage, contrastColor }}
                  value={horizontalAxis.values[index] as number}
                />
              )
            })}
          </TBody>
        </Table>
      </BarChartArea>
    </StyledGoogleChart>
  )
}

function Row({ item, barColor, percentage, value, contrastColor }: RowProps) {
  const valueRef = useRef<HTMLSpanElement>(null)

  const [leftPosition, setLeftPosition] = useState<number>(0)
  const [isOverflow, setIsOverflow] = useState<boolean>(false)

  useEffect(() => {
    if (valueRef.current) {
      const parentElementWidth =
        valueRef.current.parentElement?.offsetWidth || 0
      const barWidth = percentage * (parentElementWidth / 100)
      const valueWidth = valueRef.current.offsetWidth + 5

      if (barWidth > valueWidth) {
        setIsOverflow(true)
      } else {
        setIsOverflow(false)
      }

      setLeftPosition(barWidth > valueWidth ? 2 : barWidth + 2)
    }
  }, [percentage])

  return (
    <TRow>
      <VerticalAxisCell>{item}</VerticalAxisCell>
      <HorizontalAxisCell>
        <BarContainer>
          <Bar backgroundColor={barColor} width={percentage} />
        </BarContainer>
        <Value
          ref={valueRef}
          contrastColor={isOverflow ? contrastColor : '#000'}
          leftPosition={leftPosition}
        >
          {value}
        </Value>
      </HorizontalAxisCell>
    </TRow>
  )
}

export default BarChart
