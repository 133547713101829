import { useIsMobile } from '@yes.technology/react-toolkit'
import { StyledLink } from './styles'

type LinkAreaProps = {
  des?: string
  link?: string
  isApplicationSystemName?: boolean
}

const LinkArea = ({ des, link, isApplicationSystemName }: LinkAreaProps) => {
  const isMobile = useIsMobile()

  if (!link) return null

  const displayNone = 'd-none d-md-block'
  const className = `nav-link h6 mb-0${isMobile && isApplicationSystemName ? '' : ` ${displayNone}`}`

  return (
    <StyledLink to={link} className={className}>
      {des}
    </StyledLink>
  )
}

export default LinkArea
