import { DataArea } from 'site/Render/components'
import { useMapEndpoint } from 'site/shared/hooks'
import Map from './components/DataArea/Map'
import Masterdata from './components/DataArea/Masterdata'
import ChartHigh from './components/DataArea/ChartHigh'
import { SitemodelContextValue } from 'sitemodel/shared/types'
import { SortOption } from '@yes.technology/react-toolkit'
import TopLine from 'site/Renderer/components/TopLine'
import { useFetchYesObjects } from 'yesObject/shared'

const DEFAULT_ORDER: SortOption[] = [{ des: 'asc' }]

const Render = ({ content }: { content?: SitemodelContextValue }) => {
  const filteractionResponse = useFetchYesObjects({
    filteractionUuid: content?.filteraction,
    showLoading: true,
    initialSortOptions: DEFAULT_ORDER
  })

  const markers = useMapEndpoint({ endpoint: content?.map_endpoint })

  const shouldRenderDataArea = Boolean(
    content?.filteraction && content?.data_area_layout
  )

  return (
    <>
      <TopLine props={{ layout: content?.top_area_layout }} />
      {shouldRenderDataArea && (
        <DataArea
          dataAreaLayout={content?.data_area_layout}
          sitemodels={filteractionResponse.yesObjects}
          isLoading={filteractionResponse.isLoading}
          metadata={filteractionResponse.metadata}
        />
      )}
      {content?.render_within_masterdata && (
        <Masterdata
          objectclassUuid={content?.render_within_masterdata}
          urlMasterdata={window.waMasterdataUrl}
          filteraction={content?.filteraction_within_masterdata}
        />
      )}
      {content?.map_endpoint && (
        <Map markers={markers} googleMapsApiKey={window.googleMapsApiKey} />
      )}
      {content?.chart_endpoint && content?.chart_type && (
        <ChartHigh endpoint={content?.chart_endpoint} />
      )}
    </>
  )
}

export default Render
