import React from 'react'
import {
  CardHead,
  CardTitle,
  ColoredCard,
  TransferOrder,
  TransferOrdersList
} from './styles'
import { CheckpointCardProps } from './types'
import { useTranslation } from 'react-i18n-lite'
import {
  useIsMobile,
  Text,
  LinkButton,
  StatusObject
} from '@yes.technology/react-toolkit'
import TransferOrderCard from 'component/TransferOrderCard'
import { useFetchYesObjects } from 'yesObject/shared'
import { StatusCategory } from 'component/TransportOrderCard/types'

const getCategoryFromStatusCode = (statusCode: number): StatusCategory => {
  const numberExecutionCode = Number(window.checkpointExecutionStatusCode)

  if (statusCode < numberExecutionCode) {
    return 'todo'
  } else if (statusCode === numberExecutionCode) {
    return 'doing'
  }

  return 'done'
}

function CheckpointCard({
  checkpoint,
  status,
  transferOrders
}: CheckpointCardProps) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const { uuid_status: statusCode, address } = checkpoint

  const statusCategory = getCategoryFromStatusCode(Number(statusCode))

  return (
    <ColoredCard
      hierarchyCardLevel={2}
      heightClass='doubleXSmall'
      statusCategory={statusCategory}
      centerHeaderElement={
        <CardTitle>{`${statusCode} | ${status?.des}`}</CardTitle>
      }
    >
      <>
        <Text variant={isMobile ? 'content-04' : 'content-03'}>
          {checkpoint.des}
        </Text>

        <LinkButton
          href={`/${window.objectDetailsSitemodelUuid}?uuid_objectclass=${window.uuidObjectclassCheckpoint}&object-uuid=${checkpoint.uuid}`}
          variant='secondary'
        >
          {t('checkpoint-section.view')}
        </LinkButton>

        <LinkButton
          style={{ marginTop: 8 }}
          href={`geo:${address?.[0]?.latitude}, ${address?.[0]?.longitude};`}
          variant='secondary'
        >
          {t('checkpoint-section.map-button')}
        </LinkButton>
      </>
      <TransferOrder spacingClass={isMobile ? 'xSmall' : 'doubleXSmall'}>
        <CardHead $bgColor='#0056CC'>
          <CardTitle $color='#FFF'>
            {t('checkpoint-section.transfer-order')}
          </CardTitle>
        </CardHead>

        <TransferOrdersList>
          {transferOrders.map((transferOrder) => (
            <TransferOrderCard
              key={transferOrder.uuid}
              transferOrder={transferOrder}
            />
          ))}
        </TransferOrdersList>
      </TransferOrder>
    </ColoredCard>
  )
}

export default CheckpointCard
