import { useContext, createContext } from 'react'

import {
  ActionColumn,
  ActionColumnsPosition,
  ColumnLayout,
  SelectionOption
} from '../types'
import {
  Objectclass,
  Pagination,
  SortOption,
  YesObject
} from '@yes.technology/react-toolkit'
import { DataTableConfigProps } from '../DataTable'
import { FieldValuesValue } from '../../FilterFields/types'
import { ConcatFields } from 'shared/utils/object/concatenateFields'

type Mode = 'edit' | 'insert' | 'view'

type ChangedFieldValues = {
  [uuidOrIndex: string]: {
    [columnName: string]: FieldValuesValue
  }
}

export type TableContextValue = DataTableConfigProps & {
  idObjectClass?: string
  objectclass?: Objectclass
  fields?: Objectclass['fields']
  actionColumns: ActionColumn[]
  actionColumnsPosition: ActionColumnsPosition
  columnsNames: string[]
  objects: YesObject[]
  refetchObjects: () => void
  changedFieldValues: ChangedFieldValues
  setChangedFieldValues: (values: ChangedFieldValues) => void
  columnsLayouts: ColumnLayout[]
  onSort: (newSortOptions: SortOption[]) => void
  sortOptions: SortOption[]
  displayPreviousColumns: () => void
  displayNextColumns: () => void
  currentColumnIndex: number
  unpinnedColumns: string[]
  pagination?: Pagination
  totalObjects?: number
  selectedRows: Record<string, SelectionOption>
  setSelectedRows: (rows: Record<string, SelectionOption>) => void
  mode: Mode
  setMode: (mode: Mode) => void
  relationCounterpartField?: {
    [columnName: string]: [
      {
        uuid: string
        des: string
      }
    ]
  }
  reportDownloadTriggered?: boolean
  triggerReportDownload?: () => void
  reportUrl?: string
  objectclassQueryString?: string
  displayLayoutSelection: boolean
  informational?: boolean
  embedded?: boolean
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  concatFields?: ConcatFields
  pinnedColumns?: string[]
  hideRowActionButtons?: boolean
}

export const defaultContextValue: TableContextValue = {
  actionColumns: [],
  actionColumnsPosition: 'last',
  columnsNames: [],
  objects: [],
  refetchObjects: () => '',
  changedFieldValues: {},
  setChangedFieldValues: () => '',
  columnsLayouts: [],
  onSort: () => undefined,
  sortOptions: [],
  displayPreviousColumns: () => undefined,
  displayNextColumns: () => undefined,
  currentColumnIndex: 0,
  unpinnedColumns: [],
  pagination: {
    offset: 0,
    limit: 0
  },
  totalObjects: 0,
  layoutClassificationUuid: '',
  hierarchyLevel: 'level_2',
  actionButton: {
    type: 'view',
    target: '_self'
  },
  selectedRows: {},
  setSelectedRows: () => undefined,
  mode: 'view',
  setMode: () => '',
  objectclassQueryString: 'uuid_objectclass',
  displayLayoutSelection: false,
  informational: false,
  embedded: false,
  navigationSliderBackgroundColor: undefined,
  navigationSliderColor: undefined,
  concatFields: undefined,
  pinnedColumns: [],
  hideRowActionButtons: false
}

const TableContext = createContext<TableContextValue>(defaultContextValue)

export { TableContext }

export default function useTableContext() {
  return useContext(TableContext)
}
