import styled from 'styled-components'

export const Content = styled.div`
  text-align: justify;

  /* Text Class/Content/content-04 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
