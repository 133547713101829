import { useEffect, useState } from 'react'

import { HTTPClient } from 'infrastructure/shared/api'
import { ChartWrapperOptions } from 'react-google-charts'

type Props = {
  endpoint: string | undefined
}

type State = {
  objects: []
  options: ChartWrapperOptions['options']
  loading: boolean
  errorMessage: string
}

const initialState: State = {
  objects: [],
  options: {},
  loading: false,
  errorMessage: ''
}

const useChartEndpoint = ({ endpoint }: Props) => {
  const [state, setState] = useState<State>(initialState)

  useEffect(() => {
    if (endpoint) {
      setState((prevState) => ({
        ...prevState,
        loading: true
      }))
      HTTPClient.get(`${endpoint}?${Date.now()}`)
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            objects: response?.data?.data?.objects,
            options: response?.data?.data?.options,
            loading: false
          }))
        })
        .catch((error) => {
          console.log(error)
          setState({
            ...initialState,
            errorMessage: error.message
          })
        })
    }
  }, [endpoint])

  return {
    objects: state?.objects,
    options: state?.options,
    loading: state?.loading,
    error: state?.errorMessage
  }
}

export default useChartEndpoint
