const NavigationSliderTitle = ({
  title,
  color
}: {
  title: string
  color?: string
}) => {
  return (
    <div
      className='navigation-slider-title'
      style={color ? { color } : undefined}
    >
      {title}
    </div>
  )
}

export default NavigationSliderTitle
