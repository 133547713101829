import { YesObject } from '@yes.technology/react-toolkit'

const PARAMS_MATCHER = /\{\{([^}]+)\}\}/g
const OBJECT_KEY_MATCHER = /[{}]/g

export const interpolateUrl = (url: string, object: YesObject) => {
  const params = url.match(PARAMS_MATCHER)

  if (!params) {
    return url
  }

  return params.reduce((urlAccumulator, param) => {
    const objectKey = param.replace(OBJECT_KEY_MATCHER, '')
    const value = object[objectKey]

    if (value && typeof value === 'string') {
      return urlAccumulator.replace(param, value)
    }

    return urlAccumulator
  }, url)
}
