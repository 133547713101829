import { Record, String, Array, Number, Optional, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

type OptionType = {
  value: string
  color: string
}

export type NavigationSliderSecondaryContainerProps = {
  props: {
    options: OptionType[]
    currentIndex: number
    styleMap?: StyleMap
  }
}

const OptionTypeGuard = Record({
  value: String,
  color: String
})

export const NavigationSliderSecondaryContainerTypeGuard = Record({
  options: Array(OptionTypeGuard),
  currentIndex: Number,
  styleMap: Optional(Unknown)
})
