import { useQuery } from '@tanstack/react-query'
import { downloadZip as clientZipDownloadZip } from 'client-zip'
import { useMemo } from 'react'

type DownloadZipCallback = (files: { name: string; input: Blob }[]) => {
  blob: () => Promise<Blob>
}

export type UseReportZipProps = {
  blobs: Readonly<Blob[]>
  sourceObjects: { uuid: string }[]
  enabled?: boolean
  downloadZip?: DownloadZipCallback
}

export default function useReportZip({
  blobs,
  sourceObjects,
  enabled,
  downloadZip = clientZipDownloadZip
}: UseReportZipProps) {
  const files = useMemo(
    () =>
      blobs.map((blob, index) => ({
        name: `${(index + 1).toString().padStart(blobs.length.toString().length, '0')}_${sourceObjects[index].uuid}.pdf`,
        input: blob
      })),
    [blobs, sourceObjects]
  )

  const { data: url = null, isLoading: isPacking } = useQuery({
    queryKey: ['useReportZip', files],
    queryFn: async () => URL.createObjectURL(await downloadZip(files).blob()),
    enabled: files.length > 0 && enabled
  })

  return {
    url,
    isPacking
  }
}
