import { Button } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const Title = styled.h2`
  color: #303032;
  text-align: center;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 2px 8px;

  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
`

export const Line = styled.hr`
  margin: 8px 0;
`

export const SectionHead = styled.div`
  background-color: #004099;
  border-radius: 6px;
  height: 56px;
  width: calc(100% + 16px);
  position: relative;
  left: -8px;
  top: -8px;
  display: flex;
  align-items: center;
`

export const SectionTitle = styled.h3`
  color: #fff;
  text-align: center;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: auto;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const ActionButton = styled(Button)`
  margin-bottom: 8px;
`
