import { SituationObject } from '@yes.technology/react-toolkit'
import apiAsync from 'infrastructure/shared/api/api-async'

export default async function fetchSituations(
  showLoading: boolean
): Promise<SituationObject[] | null> {
  const response = await apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: 'objects/_filter',
    method: 'POST',
    obj: {
      query: {
        'situation.des': {
          $eq: '40'
        }
      },
      objectclass: 'UUID_OBJECTCLASS_SITUATION',
      orderby: [],
      pagination: {
        offset: 0,
        limit: 100
      }
    },
    showLoading
  })

  return response.data.objects as SituationObject[] | null
}
