// Mapeias os operadores conforme utilizado no componente visual de filtros
// para símbolo utilizado na linguagem de filtros de API (baseada em MongoDB)

import { v4 as uuidv4 } from 'uuid'
import {
  FilterQuery,
  datepickerToISOFormat,
  normalizeFilterRegex
} from '@yes.technology/react-toolkit'
import { FieldValues } from '../types'
import { Operator } from '../types/operators'
import { isSpecialRelationFieldByObjectclass } from 'shared/utils/specialRelationFields/specialRelationFields'
import { FieldValuesValue } from '../types/fields'

// Ver https://wiki.lb1.tecnologiafox.com.br/index.php/RFC_Y5_-_Linguagem_de_filtros
const operatorsMapping = {
  gt: '$gt',
  gte: '$gte',
  lt: '$lt',
  lte: '$lte',
  in: '$in',
  nin: '$nin',
  eq: '$eq',
  ne: '$ne',
  REGEX: '$regex'
}

export function makeDesFilter(operator: Operator, str: string) {
  if (operator === 'REGEX') {
    // No caso do operador regex, precisamos adicionar os wildcards
    // do regex no início e fim da string e normalizar a busca de caracteres
    return '.*' + normalizeFilterRegex(str) + '.*'
  }

  return str
}

// O parâmetro items é um array contendo objetos com
// uuid e des. Precisamos apenas retornar o array contendo os ids
function makeIdsFilter(items: { uuid: string; des: string }[]) {
  return items.map((i) => i.uuid)
}

export function toYesFilter(values: FieldValues) {
  const group: FilterQuery[''][0] = {}

  let isValid = true

  if (!Object.keys(values).length) return false

  Object.keys(values).forEach((key) => {
    // Filtro cru proveniente do componente visual
    const raw = JSON.parse(JSON.stringify(values[key]))

    // A propriedade "values" pode ser uma string no caso de busca
    // por descrição, ou um array no caso de busca por ids
    let filterContent
    let property
    if (!areOperatorAndValuesCompatibles(raw.operator, raw.value)) {
      isValid = false
      return false
    }

    if (typeof raw.value === 'string') {
      const formattedValue =
        raw.type === 'DATETIME' ? datepickerToISOFormat(raw.value) : raw.value
      property = 'des'
      filterContent = makeDesFilter(raw.operator, formattedValue || '')
    } else if (
      typeof raw.value?.[0]?.objectclassUuid === 'string' &&
      isSpecialRelationFieldByObjectclass(raw.value?.[0]?.objectclassUuid)
    ) {
      raw.operator = raw.operator === 'in' ? 'eq' : 'ne'
      raw.type = 'STRING'
      raw.value = raw.value[0].code
      property = 'des'
      filterContent = makeDesFilter(raw.operator, raw.value)
    } else {
      property = 'uuid'
      filterContent = makeIdsFilter(raw.value)
    }
    const filterType: Record<string, string | string[]> = {}
    const operator =
      operatorsMapping[raw.operator as keyof typeof operatorsMapping]
    filterType[operator] = filterContent
    group[key + '.' + property] = filterType
  })

  if (!isValid) {
    return false
  }

  return {
    $and: [group]
  }
}

// Validar se o operador é compatível com os valores selecionados
//
// Não podemos utilizar o operador 'in' com uma 'string', por exemplo
function areOperatorAndValuesCompatibles(
  operator: Operator,
  value: FieldValuesValue
) {
  let validOperators = {}
  if (typeof value === 'string') {
    validOperators = {
      gt: true,
      gte: true,
      lt: true,
      lte: true,
      eq: true,
      ne: true,
      REGEX: true
    }
  } else if (Array.isArray(value)) {
    validOperators = {
      in: true,
      nin: true
    }
  }

  if (operator in validOperators) {
    return true
  }
  return false
}

// formatArrayValue recebe um array de IDs
// provenientes de uma query no formato YesQueryLanguage
// e converte para um array de objetos esperados
// pelo componente local
function formatArrayValue(values: any) {
  const newValues: any = []
  values.forEach((val: any) => {
    newValues.push({
      id: val,
      uuid: val,
      des: val /* Pela query não temos como receber a DES real do objeto */
    })
  })
  return newValues
}

const valueFormatters = {
  $in: formatArrayValue,
  DEFAULT: (value: any) => value
}

function formatValue(rawOperatorId: string, value: any) {
  const formatter =
    valueFormatters[rawOperatorId as keyof typeof valueFormatters] ||
    valueFormatters.DEFAULT
  return formatter(value)
}

function getFieldKey(rawKey: string) {
  return rawKey.replace('.des', '').replace('.uuid', '')
}

function compositionBooleanOperator(operatorKey: string) {
  if (operatorKey === '$and') {
    return 'AND'
  } else if (operatorKey === '$or') {
    return 'OR'
  }
  console.log('Operador inválido')
  return '?'
}

const rawToOperator = {
  $gt: 'gt',
  $gte: 'gte',
  $lt: 'lt',
  $lte: 'lte',
  $in: 'in',
  $nin: 'nin',
  $eq: 'eq',
  $ne: 'ne',
  $regex: 'REGEX',
  $filteraction: 'filteraction'
}

// TODO: improve types
export function decodeQuery(
  query: Record<string, any> | string,
  isQueryRoot = true
) {
  let groupValues: Record<string, any> = {}
  let composition = ''

  let queryObject: Record<string, any> = {}
  try {
    queryObject = typeof query === 'string' ? JSON.parse(query) : query
  } catch (e) {
    return {
      values: groupValues,
      composition
    }
  }

  let isGroup = false
  let isBoolean = false
  Object.keys(queryObject).forEach((key) => {
    if (key === '$and' || key === '$or') {
      isBoolean = true
    } else {
      isGroup = true
    }
  })

  // Se for grupo, podemos devolver a
  // estrutura dos values
  if (isGroup) {
    const groupContent: Record<string, any> = {}
    Object.entries(queryObject).forEach(([rawFieldKey, rawFieldValue]) => {
      let rawOperatorId: keyof typeof rawToOperator = '$eq'
      let value = rawFieldValue

      if (rawFieldValue && typeof rawFieldValue === 'object') {
        // TODO: Em caso de mais de 1 entry, retornar erro não suportado
        ;[rawOperatorId, value] = Object.entries(rawFieldValue)[0] as [
          keyof typeof rawToOperator,
          any
        ]
      }

      value = formatValue(rawOperatorId, value)

      const fieldKey = getFieldKey(rawFieldKey)
      groupContent[fieldKey] = {
        operator: rawToOperator[rawOperatorId],
        value
      }
    })
    const myUuid = uuidv4()
    groupValues[myUuid] = groupContent
    composition = myUuid
  }

  // Se for lógica booleana, parseamos seu filho
  // recursivamente
  if (isBoolean) {
    Object.keys(queryObject).forEach((key) => {
      const compositionIds: string[] = []
      queryObject[key].forEach((block: any) => {
        const decoded = decodeQuery(block, false)
        groupValues = Object.assign(groupValues, decoded.values)
        compositionIds.push(decoded.composition)
      })
      let newComposition = compositionIds
        .reverse()
        .join(' ' + compositionBooleanOperator(key) + ' ')
      if (compositionIds.length > 1) {
        newComposition = '(' + newComposition + ')'
      }
      composition = composition + newComposition
    })
  }

  const rootGroupValues: Record<string, any> = {}

  if (isQueryRoot) {
    const groupKeys = Object.keys(groupValues)

    groupKeys.forEach((key, index) => {
      const groupIndex = (groupKeys.length - index).toString()
      composition = composition.replace(key, groupIndex)
      rootGroupValues[groupIndex] = groupValues[key]
    })
  }

  return {
    values: isQueryRoot ? rootGroupValues : groupValues,
    composition
  }
}
