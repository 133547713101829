import { useLocation, useSearchParams } from 'react-router-dom'
import { GroupedFieldValues } from '../../types'
import useLocationState from '../useLocationState'
import { YesObject } from '@yes.technology/react-toolkit'
import useNavigateMergingState from 'shared/hooks/useNavigateMergingState/useNavigateMergingState'

export const SELECT_AND_LINK_PARAM = 'select_and_link'

export const SELECT_AND_LINK_POST_MESSAGE_TYPE = 'select_and_link'

export const usePersistentSelection = () => {
  const navigateMergingState = useNavigateMergingState()
  const { locationState } = useLocationState()

  const getPersistentSelection = (
    target: string | undefined,
    groupedFieldValues: GroupedFieldValues,
    values: YesObject[]
  ) => {
    if (!target) {
      return groupedFieldValues
    }

    const groupedFieds = { ...groupedFieldValues }

    Object.keys(groupedFieds).forEach((groupKey) => {
      if (groupedFieds[groupKey][target]) {
        groupedFieds[groupKey][target].value = values
      }
    })

    return groupedFieds
  }

  const [searchParams] = useSearchParams()

  const shouldRenderSelectAndLink = () => {
    const enabledInUrl = searchParams.has(SELECT_AND_LINK_PARAM)

    return !!(locationState?.sitemodelHistory?.length || enabledInUrl)
  }

  const location = useLocation()

  const getRedirectToObjectclassCallback = (
    targetField: string | undefined,
    objectclassUuid: string,
    filterState: GroupedFieldValues
  ) => {
    const { sitemodelHistory = [] } = locationState || {}
    const targetPath = `/${window.selectAndLinkFilterSitemodel}?uuid_objectclass=${objectclassUuid}`
    const currentPath = {
      url: location.pathname + location.search,
      targetField,
      filterState
    }

    return () => {
      navigateMergingState(targetPath, {
        state: {
          sitemodelHistory: [...sitemodelHistory, currentPath],
          shouldResetData: true,
          shouldPersistData: false
        }
      })
    }
  }

  const selectAndLink = (values: YesObject[]) => {
    if (!shouldRenderSelectAndLink()) {
      return
    }

    const { sitemodelHistory = [] } = locationState
    const path = sitemodelHistory.pop()

    if (!path) {
      window.parent.postMessage(
        {
          type: SELECT_AND_LINK_POST_MESSAGE_TYPE,
          values
        },
        '*'
      )

      return
    }

    const persistentSelection = getPersistentSelection(
      path.targetField,
      path.filterState,
      values
    )

    navigateMergingState(path.url, {
      state: {
        sitemodelHistory,
        persistentSelection,
        shouldPersistData: true,
        shouldResetData: false
      }
    })
  }

  return {
    selectAndLink,
    getPersistentSelection,
    getRedirectToObjectclassCallback,
    shouldRenderSelectAndLink
  }
}
