import { Grid } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const

export type Breakpoint = (typeof breakpoints)[number]

export const BreakpointRange = {
  xs: { max: 575 },
  sm: { min: 576, max: 767 },
  md: { min: 768, max: 1279 },
  lg: { min: 1280, max: 1399 },
  xl: { min: 1400 }
} satisfies Record<
  Breakpoint,
  { min: number } | { min: number; max: number } | { max: number }
>

export const GridContainer = styled.div`
  padding: 0 1rem;

  @media (min-width: ${BreakpointRange.lg.min}px) {
    padding: 0 1.5rem;
  }

  @media (min-width: ${BreakpointRange.xl.min}px) {
    padding: 0 2rem;
  }
`

export const StyledGrid = styled(Grid)`
  width: 100%;
  margin: 16px auto 32px auto;

  @media (min-width: 768px) {
    max-width: 1920px;
    margin: 24px auto 60px auto;
  }
`
