import { Field } from '@yes.technology/react-toolkit'
import { FilterField, Operator } from '../types'

export type FieldType = 'DATETIME' | 'RELATION' | 'STRING' | 'UUID'

export function formatFields(fields: Field[]): FilterField[] {
  return (
    (fields?.length > 0 &&
      fields.map((field) => {
        return {
          uuid: field.uuid,
          id: field.uuid,
          name: field.column_json,
          readOnly: field.read_only,
          type: field.type,
          label: field.display_text.des,
          objectclassUuid: field.relation_objectclass_uuid
        }
      })) ||
    []
  )
}

type NormalizeOperatorProps = {
  originalOperator: Operator
  fieldType: FieldType
}

export function normalizeOperator({
  originalOperator,
  fieldType
}: NormalizeOperatorProps): Operator {
  if (fieldType !== 'RELATION') {
    return originalOperator
  }

  return ['ne', 'nin'].includes(originalOperator) ? 'nin' : 'in'
}
