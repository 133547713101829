import { GoogleChartWrapperChartType } from 'react-google-charts'
import { DataChart, DataChartValue } from './ChartWrapper'
import styled from 'styled-components'
import { Button, Icon, InformationCard } from '@yes.technology/react-toolkit'
import { StyledGoogleChart } from '../Cockpit.styles'
import { useIsOpen } from 'shared/hooks'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { CustomChart } from '../Cockpit.types'

interface CardChartProps {
  chartModel: GoogleChartWrapperChartType | CustomChart
  data: DataChart
  title: string
}

interface ItemProps {
  title: string
  count: DataChartValue
  image?: string
  backgroundColor?: string
  textForInformationCard?: string
}

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 45px;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Card = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#006BFF'};
  color: white;
  width: 100%;
  height: 128px;
  border: 1px solid #bcbcbe;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
`

const Title = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

const Count = styled.div`
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  margin-top: 1.5rem;
`

const InfoButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

const ImageIcon = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
`

const CardChart = ({ chartModel, data, title }: CardChartProps) => {
  const chartData = data.slice(1)

  return (
    <StyledGoogleChart {...{ chartModel }}>
      <h2>{title}</h2>

      <CardContainer>
        {chartData.map((item, index) => (
          <Item
            key={`card-container-${index}`}
            backgroundColor={item?.[2]}
            title={item[0]}
            image={item?.[3]}
            count={item[1]}
            textForInformationCard={item?.[4]}
          />
        ))}
      </CardContainer>
    </StyledGoogleChart>
  )
}

const Item = ({
  backgroundColor,
  title,
  image,
  count,
  textForInformationCard
}: ItemProps) => {
  const { isOpen, open, close } = useIsOpen(false)
  return (
    <>
      <Card {...{ backgroundColor }}>
        <Title>{title}</Title>
        {image && (
          <ImageIcon>
            <img src={image} alt={`Ícone sobre ${title}`} />
          </ImageIcon>
        )}
        <Count>{count}</Count>
        <InfoButtonContainer>
          <Button
            variant='secondary'
            heightClass='semiSmall'
            disabled={!textForInformationCard}
            onClick={open}
            data-testid='info-button'
          >
            <Icon iconName='Information' icSize={'semiSmall'} />
          </Button>
        </InfoButtonContainer>
      </Card>
      {textForInformationCard && (
        <InformationCard {...{ title, isOpen, close }}>
          <SafeHtmlContent html={textForInformationCard} />
        </InformationCard>
      )}
    </>
  )
}

export default CardChart
