import { useEffect, useState } from 'react'
import { SituationObject } from '@yes.technology/react-toolkit'
import { SituationActionObject, useSituations } from 'situation/shared'
import useSituationActions from 'situation/shared/hooks/useSituationActions'

export default function useSituationWithActions({
  situationCode
}: {
  situationCode?: string
}) {
  const [objectSituation, setObjectSituation] = useState<SituationObject>()
  const situations = useSituations()
  const { situationActions, isFetchingActions } = useSituationActions({
    situations
  })
  const [visibleSituationActions, setVisibleSituationActions] = useState<
    SituationActionObject[]
  >([])
  const [isLoading, setIsLoading] = useState(true)

  const updateObjectSituation = () => {
    const initialSituationAction = situationActions.find(
      (situationAction) => !situationAction.allowed_origin_situation
    )
    const initialSituation = initialSituationAction?.destination_situation?.[0]

    const objectSituationCode = situationCode || initialSituation?.code
    const newObjectSituation = situations.find(
      ({ code }) => code === objectSituationCode
    )

    objectSituationCode && setObjectSituation(newObjectSituation)
  }

  const updateVisibleSituationActions = () => {
    if (objectSituation && !isFetchingActions) {
      const getVisibleActions = (situationAction: SituationActionObject) =>
        situationAction.allowed_origin_situation?.[0]?.code ===
        objectSituation.code
      const newVisibleSituationActions =
        situationActions.filter(getVisibleActions)
      setVisibleSituationActions(newVisibleSituationActions)
      setIsLoading(false)
    }
  }

  useEffect(updateObjectSituation, [
    situationActions,
    situations,
    situationCode,
    setObjectSituation
  ])

  useEffect(updateVisibleSituationActions, [
    situationActions,
    objectSituation,
    isFetchingActions,
    setIsLoading,
    setVisibleSituationActions
  ])

  return { objectSituation, visibleSituationActions, isLoading }
}
