import { useEffect, useMemo, useState } from 'react'

import { DataAreaPropsType, AreasType } from 'site/shared/types'
import { useDataArea } from 'site/Render/hooks'
import { GridContainer } from 'shared/style/grid'

const DataArea: React.FunctionComponent<DataAreaPropsType> = ({
  dataAreaLayout,
  sitemodels,
  isLoading,
  metadata
}): JSX.Element => {
  const [areas, setAreas] = useState<AreasType[] | null | undefined>()

  const { configureLayout, chooseComponent } = useDataArea()

  useEffect(() => {
    if (sitemodels && dataAreaLayout) {
      setAreas(configureLayout(sitemodels, dataAreaLayout.orderby))
    }
  }, [dataAreaLayout, sitemodels, configureLayout, metadata])

  const component = useMemo(
    () => chooseComponent(isLoading, areas, metadata),
    [chooseComponent, isLoading, areas, metadata]
  )

  return (
    <div className='container-fluid mt-3'>
      <GridContainer className='row'>{component}</GridContainer>
    </div>
  )
}

export default DataArea
