import useWindowDimensions from './useWindowDimensions'

export type Breakpoint = 'xl' | 'lg' | 'md' | 'sm'

export const SIZE_SM = 'sm'
export const SIZE_MD = 'md'
export const SIZE_LG = 'lg'
export const SIZE_XL = 'xl'

// TODO: Update this breakpoint func or move it to toolkit

export const useBreakpoint = (): Breakpoint => {
  const { windowWidth } = useWindowDimensions()

  if (windowWidth < 768) {
    return SIZE_SM
  } else if (windowWidth >= 768 && windowWidth < 992) {
    return SIZE_MD
  } else if (windowWidth >= 992 && windowWidth < 1200) {
    return SIZE_LG
  } else if (windowWidth >= 1200) {
    return SIZE_XL
  }

  return SIZE_SM
}
