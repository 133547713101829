import {
  Record as RTRecord,
  String,
  Dictionary,
  Unknown,
  Optional
} from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type Tab = {
  uuid: string
  des: string
  complement: string
}

export type TabsContentProps = {
  props: {
    items: Record<string, Tab>
    styleMap?: StyleMap
  }
}

const Tab = RTRecord({
  uuid: String,
  des: String,
  complement: String
})

export const TabsContentTypeGuard = RTRecord({
  items: Dictionary(Tab, String),
  styleMap: Optional(Unknown)
})
