import TagManager from 'react-gtm-module'

interface TagManagerArgs {
  gtmId: string
  auth: string
  preview: string
}

export function initialize(tagManagerArgs: TagManagerArgs) {
  TagManager.initialize(tagManagerArgs)
}

export function setDataLayer(dataLayer: Record<string, unknown>) {
  window.dataLayer.push(dataLayer)
}
