import { useEffect } from 'react'

function setDocumentTitle(title: string) {
  document.title = title
}

export const useDocumentTitle = ({ title }: { title: string | undefined }) => {
  useEffect(() => {
    if (title) {
      setDocumentTitle(title)
    }
  }, [title])
  return null
}

export default useDocumentTitle
