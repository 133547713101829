import { Classification, YesObject } from '@yes.technology/react-toolkit'

import {
  CardModel,
  CardProps,
  ClassificationCardProps
} from '../DataCards.types'
import { ClassifiedListGrid } from './ClassifiedList.styles'
import { ClassifiedCollapsibleObjectCard } from '../CollapsibleObjectCard'

const validClassifiedCardModels: {
  [key: string]: React.FC<ClassificationCardProps>
} = {
  CollapsibleObjectCard: ClassifiedCollapsibleObjectCard
}

type ClassifiedListProps = {
  classifications?: Classification[]
  objects?: YesObject[]
  cardModel: CardModel
  cardProps?: CardProps
}

const ClassifiedList = ({
  classifications,
  objects,
  cardModel,
  cardProps
}: ClassifiedListProps) => {
  const CardComponent = validClassifiedCardModels[cardModel]

  if (!objects || !classifications) {
    return null
  }

  return (
    <ClassifiedListGrid>
      {classifications.map((classification, index) => (
        <CardComponent
          key={index}
          classification={classification}
          objects={objects}
          cardProps={cardProps}
        />
      ))}
    </ClassifiedListGrid>
  )
}

export default ClassifiedList
