import { Document, Page } from '@react-pdf/renderer'
import { DataTablesArea, FilterArea } from './components'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import { GroupedFieldValues } from '../site/Renderer/components/FilterFields/types'
import { Objectclass, YesObject } from '@yes.technology/react-toolkit'
import { ColumnLayout } from 'site/Renderer/components/DataTables/types'

interface ListReportProps {
  filterFields?: FilterFieldsResponse
  filterComposition: string
  values: GroupedFieldValues
  title: string
  objects: YesObject[]
  columns: string[]
  columnsLayouts: ColumnLayout[]
  objectclass?: Objectclass
}

function ListReport({
  filterFields,
  filterComposition,
  values,
  title,
  objects,
  columns,
  columnsLayouts,
  objectclass
}: ListReportProps) {
  return (
    <Document>
      <Page size='A4' dpi={150}>
        {filterFields && (
          <FilterArea
            values={values}
            title={title}
            fields={filterFields}
            composition={filterComposition}
          />
        )}
        {objectclass && (
          <DataTablesArea
            objectclass={objectclass}
            objects={objects}
            columns={columns}
            columnsLayouts={columnsLayouts}
          />
        )}
      </Page>
    </Document>
  )
}

export default ListReport
