import { DocumentListCard } from '@yes.technology/react-toolkit'
import styled, { css, keyframes } from 'styled-components'

const fadeOut = keyframes`
  from {
    opacity: 100%;
  }
  
  to {
    opacity: 0%;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0%;
    transform: scaleY(0);
    display: none;
  }

  to {
    opacity: 100%;
    transform: scaleY(1);
    display: block;
  }
`

export const CardContainer = styled.div<{
  $isFadingIn: boolean
  $isFadingOut: boolean
}>`
  margin-top: 8px;
  opacity: 100%;

  ${({ $isFadingOut }) =>
    $isFadingOut &&
    css`
      animation: ${fadeOut} 300ms ease-out forwards;
      animation-delay: 500ms;
    `}

  ${({ $isFadingIn }) =>
    $isFadingIn &&
    css`
      opacity: 0%;
      animation: ${fadeIn} 300ms ease-in forwards;
      animation-delay: 500ms;
    `}
`

export const ColumnsContainer = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-grow: 1;
  transition: all 0.2s linear;
  padding: 0px 8px;

  @media (min-width: 768px) {
    padding: 0px 56px;
  }
`

export const ColumnCard = styled(DocumentListCard)`
  background-color: #ffffff;
`

export const ColumnContainer = styled.div<{ $basis: number }>`
  flex-grow: 0;
  flex-basis: ${(props) => props.$basis}%;
`

export const KanbanColumnTitle = styled.h2`
  font-family: Barlow;
  color: #303032;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
