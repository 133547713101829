import { useCallback, useState } from 'react'

type Updater<T> = (prevState: T) => T
type SetStateAction<T> = T | Updater<T>

const currentDate = new Date()

const defaultSiteStateItems = {
  sitemodel: {
    CurrentMonthStart: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ).toISOString(),
    CurrentMonthEnd: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).toISOString()
  }
}

export default function useSiteStateProvider() {
  const [items, setItems] = useState<Record<string, any>>(defaultSiteStateItems)

  const setSiteStateItem = useCallback(
    <T>(key: string, setStateAction: SetStateAction<T>) => {
      const isUpdater = (input: unknown): input is Updater<T> =>
        typeof input === 'function'

      setItems((oldItems) => ({
        ...oldItems,
        [key]: isUpdater(setStateAction)
          ? setStateAction(oldItems[key])
          : setStateAction
      }))
    },
    [setItems]
  )

  return {
    items,
    setSiteStateItem
  }
}
