import { Classification, Field } from '@yes.technology/react-toolkit'

export function classifyFields(
  allFields: Field[],
  classifications: Classification[]
) {
  return classifications.map(classifyChildren(allFields))
}

function classifyChildren(allFields: Field[]) {
  return ({ CHILDREN }: Classification): Field[] => {
    if (!CHILDREN) {
      return []
    }

    return CHILDREN?.map((field) =>
      findFieldByUUID(allFields, field.UUID_OBJECT)
    ).filter((field) => field) as Field[]
  }
}

export function findFieldByUUID(
  allFields: Field[],
  uuid: string
): Field | undefined {
  return allFields.find(({ uuid: fieldUUID }) => fieldUUID === uuid)
}
