import styled from 'styled-components'
import { AreaLine, Text } from '@yes.technology/react-toolkit'

export const StyledAreaLine = styled(AreaLine)`
  outline: solid 1px #c9c9cb;

  margin-top: 16px;

  > div {
    gap: 0;
  }
`

export const Title = styled(Text)`
  color: #303032;
  text-align: center;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`

export const SliderButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`
