import { Optional, Record, String, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type DynamicTextHighlightContainerProps = {
  props: {
    baseText: string
    getState: string
    styleMap?: StyleMap
  }
}

export const DynamicTextHighlightContainerTypeGuard = Record({
  baseText: String,
  getState: String,
  styleMap: Optional(Unknown)
})
