import { useState } from 'react'
import { TableContextValue } from './useTableContext'

export default function useChangedFieldValues() {
  const [changedFieldValues, setChangedFieldValues] = useState<
    TableContextValue['changedFieldValues']
  >({})

  return { changedFieldValues, setChangedFieldValues }
}
