import { HeightClass } from 'shared/types/toolkit'
import styled from 'styled-components'
import { tabsComponentSizes } from './Tabs.enum'

export const Container = styled.div<{ heightClass: HeightClass }>`
  display: inline-flex;
  height: ${({ heightClass }) => tabsComponentSizes[heightClass].height}px;
  gap: ${({ heightClass }) => tabsComponentSizes[heightClass].spaceBetween}px;
  align-items: center;
  max-width: 100%;
`

export const InnerContainer = styled.div<{ heightClass: HeightClass }>`
  display: inline-flex;
  height: ${({ heightClass }) => tabsComponentSizes[heightClass].height}px;
  column-gap: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].spaceBetween}px;
  align-items: center;

  flex-wrap: wrap;
  position: relative;

  max-width: 100%;
  overflow: hidden;
`

export const SliderButton = styled.button<{ heightClass: HeightClass }>`
  background-color: transparent;
  height: ${({ heightClass }) =>
    tabsComponentSizes[heightClass].navigationButtonSize}px;
  border: none;
  padding: 0;
`
