import { Optional, Record, String, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type TextExpressionContainerProps = {
  props: {
    des: string
    styleMap?: StyleMap
  }
}

export const TextExpressionContainerTypeGuard = Record({
  des: String,
  styleMap: Optional(Unknown)
})
