import { ComponentProps, useEffect } from 'react'
import {
  AutocompleteUniselection,
  YesObject
} from '@yes.technology/react-toolkit'

import { isSituationObjectclass } from 'shared/utils/specialRelationFields/specialRelationFields'
import useSuggestionsOnChange from '../../hooks/useSuggestionsOnChange'
import { ArrayFieldValue } from '../../types'

const formatObjectsForSelection = (
  objects: Pick<YesObject, 'uuid' | 'des'>[]
) =>
  objects.map((object) => ({
    id: object?.uuid,
    value: object?.des,
    label: object?.des
  }))

type AutocompleteUniselectionProps = ComponentProps<
  typeof AutocompleteUniselection
>

type GetOptionsParams = {
  selectedOptions?: ArrayFieldValue
  suggestions: YesObject[]
}

const getOptions = ({ selectedOptions, suggestions }: GetOptionsParams) => {
  const mappedSuggestions: ArrayFieldValue = suggestions.map(
    ({ uuid, des, code, uuid_Objectclass }) => ({
      uuid: uuid,
      des: des,
      code: code as string,
      uuid_Objectclass
    })
  )

  const uniqueSelectedOptions =
    selectedOptions?.filter(
      (selectedOption) =>
        !suggestions.find((suggestion) => suggestion.uuid === selectedOption.id)
    ) || []

  return [...uniqueSelectedOptions, ...mappedSuggestions]
}

type AutocompleteUniselectionFieldProps = {
  objectclassUuid: string
  onChangeValues: (values: ArrayFieldValue) => void
  selectedOptions: ArrayFieldValue
  inputRef?: React.Ref<HTMLInputElement>
  uuidCurrentSitemodelObjectclass?: string
} & Partial<AutocompleteUniselectionProps>

const AutocompleteUniselectionField = ({
  name,
  inputRef,
  objectclassUuid,
  onChangeValues,
  selectedOptions,
  uuidCurrentSitemodelObjectclass,
  ...props
}: AutocompleteUniselectionFieldProps) => {
  const { value, onChange, isLoading, suggestions, resetValue } =
    useSuggestionsOnChange({
      objectclassUuid,
      initialValue: selectedOptions?.[0]?.des,
      uuidCurrentSitemodelObjectclass,
      skipRelations: false,
      skipMetadata: true
    })

  useEffect(() => {
    if (selectedOptions?.length === 0) {
      resetValue()
    } else if (
      selectedOptions?.[0]?.objectclassUuid &&
      isSituationObjectclass(selectedOptions?.[0]?.uuid_Objectclass)
    ) {
      resetValue(selectedOptions?.[0]?.des)
    }
  }, [selectedOptions, resetValue])

  const options = getOptions({ selectedOptions, suggestions })

  const onSelect = (
    targetSelection: string | { uuid: string; des: string }
  ) => {
    const targetSelectionValue =
      typeof targetSelection === 'string'
        ? targetSelection
        : targetSelection.des
    onChange(targetSelectionValue)
    if (targetSelectionValue === '') {
      return onChangeValues([])
    }

    const option = options.find(
      (option) => option.value === targetSelectionValue
    )

    if (!option) return

    onChangeValues([option])
  }

  const formattedOptions = formatObjectsForSelection(options)

  return (
    <AutocompleteUniselection
      {...props}
      ref={inputRef}
      statusClass={isLoading ? 'processing' : 'default'}
      options={formattedOptions}
      {...{ value, onChange: onSelect }}
    />
  )
}

export default AutocompleteUniselectionField
