import { View } from '@react-pdf/renderer'
import {
  Objectclass,
  PDFAreaLine,
  PDFDataTable,
  YesObject
} from '@yes.technology/react-toolkit'
import { ColumnLayout } from 'site/Renderer/components/DataTables/types'

interface DataTablesAreaProps {
  objectclass: Objectclass
  objects: YesObject[]
  columns: string[]
  columnsLayouts: ColumnLayout[]
}

const DEFAULT_ITEMS_PER_PAGE = 10

function DataTablesArea({
  objectclass,
  objects,
  columns,
  columnsLayouts
}: DataTablesAreaProps) {
  return (
    <View>
      <PDFAreaLine
        des={objectclass.des}
        heightClass='doubleXSmall'
        colorScheme='blue'
      />

      <PDFDataTable
        objects={objects}
        fields={objectclass.fields}
        columns={columns}
        columnsLayouts={columnsLayouts}
        itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
      />
    </View>
  )
}

export default DataTablesArea
