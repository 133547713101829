import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import getStyles from './styles'

interface FilterGroupChipProps {
  text: string
  style?: Style
}

function FilterGroupChip({ text, style }: FilterGroupChipProps) {
  const styles = getStyles()

  return (
    <View
      style={{
        ...styles.chipContainer,
        ...style
      }}
    >
      <Text style={styles.chipText}>{text}</Text>
    </View>
  )
}

export default FilterGroupChip
