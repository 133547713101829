import { Routes, Route } from 'react-router-dom'

import SiteRoutes from 'site/Routes'

const ApplicationRouter: React.FunctionComponent = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/*' element={<SiteRoutes />} />
    </Routes>
  )
}

export default ApplicationRouter
