import { YesObject } from '@yes.technology/react-toolkit'
import { cloneDeep } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import splitIntoChunks from 'shared/utils/array/splitIntoChunks'
import { ColumnProps, LayoutProps } from './Kanban.types'

export function filterSectionsByLayout(
  groupedYesObjects: Record<string, YesObject[]>,
  layoutSections: LayoutProps['sections']
) {
  const sectionsGroupedByOverrideAttributes =
    layoutSections?.reduce((acc: Record<string, ColumnProps>, section) => {
      section.columns.forEach((record) => {
        acc[record.grouped_by_value as string] = record
      })

      return acc
    }, {}) ?? {}

  return {
    filteredSections: isEmpty(sectionsGroupedByOverrideAttributes)
      ? groupedYesObjects
      : Object.fromEntries(
          Object.entries(groupedYesObjects).filter(([columnName]) =>
            Object.keys(sectionsGroupedByOverrideAttributes).includes(
              columnName
            )
          )
        ),
    columnsOverrideAttributes: sectionsGroupedByOverrideAttributes
  }
}

export function getNewTaskColumnIndex({
  filteredSections,
  pageColumns,
  newStatusColumn
}: {
  filteredSections: Record<string, YesObject[]>
  pageColumns: number
  newStatusColumn: string
}) {
  const splittedColumns = splitIntoChunks(
    Object.keys(filteredSections),
    pageColumns
  )
  const columnSectionIndex = splittedColumns.findIndex(
    (columns) => !!columns.find((column) => column === newStatusColumn)
  )

  return columnSectionIndex
}

export function updateTaskColumn({
  objects,
  taskUuid,
  newStatusColumn
}: {
  objects: YesObject[]
  taskUuid: string
  newStatusColumn: string
}) {
  const clonedObjects = cloneDeep(objects)

  const taskIndex = clonedObjects.findIndex(({ uuid }) => uuid === taskUuid)

  if (taskIndex >= 0) {
    clonedObjects.splice(taskIndex, 1, {
      ...clonedObjects[taskIndex],
      uuid_status: newStatusColumn
    })
  }

  return clonedObjects
}
