import { forwardRef } from 'react'
import { AvatarContainer, AvatarText, StyledButton } from './Avatar.styles'

interface AvatarProps {
  initials: string
  title?: string
  onClick?: () => void
}

const Avatar = forwardRef<HTMLButtonElement, AvatarProps>(function avatar(
  { initials, title, onClick },
  ref
) {
  return (
    <StyledButton title={title} onClick={onClick} ref={ref} type='button'>
      <AvatarContainer>
        <AvatarText>{initials}</AvatarText>
      </AvatarContainer>
    </StyledButton>
  )
})

export default Avatar
