import { Ref, useRef } from 'react'
import { Unifree, DatePicker, FilterQuery } from '@yes.technology/react-toolkit'
import {
  DataFieldLayout,
  FieldValues,
  FilterFieldLayout,
  FilterField,
  GroupedFieldValues
} from '../../types'
import Operators from '../Operators/Operators'
import AutocompleteMultiselectionField from './AutocompleteMultiselectionField'
import AutocompleteUniselectionField from './AutocompleteUniselectionField'
import RelationDataField from './RelationDataField'
import { FieldType } from '../../shared/fields'
import {
  isSituationField,
  isSpecialRelationFieldByColumnJson
} from 'shared/utils/specialRelationFields/specialRelationFields'
import StandardUniselectionField from './StandardUniselectionField'
import StandardMultiselectionField from './StandardMultiselectionField'
import { StyledField } from './styles'
import { AllowedDateFormats } from 'shared/types/fieldSettings.types'

export function getDefaultDataFieldLayout(
  type?: FieldType,
  columnJson?: string
): DataFieldLayout {
  if (columnJson && isSituationField(columnJson)) {
    return {
      component: 'StandardMultiselection',
      filteraction: window.situationFilterFilteractionUuid
    }
  }

  if (columnJson && isSpecialRelationFieldByColumnJson(columnJson)) {
    return {
      component: 'AutocompleteUniselection'
    }
  }

  switch (type) {
    case 'DATETIME':
      return { component: 'DatePicker' }
    case 'RELATION':
      return { component: 'AutocompleteMultiselection' }
    case 'UUID':
      return { component: 'Unifree' }
    default:
      return { component: 'Unifree' }
  }
}

type DataFieldProps = {
  id?: string
  name?: string
  label?: string
  disabled?: boolean
  type?: FieldType
  inputRef: Ref<HTMLInputElement>
  data: FieldValues['']
  objectclassUuid: string
  onChangeValues: (values: FieldValues['']['value']) => void
  uuidCurrentSitemodelObjectclass?: string
  groupedFieldValues: GroupedFieldValues
  fieldLayout?: FilterFieldLayout
  interdependentFilteringQuery?: FilterQuery
  dateFormat?: AllowedDateFormats
}

const DataField = ({
  id,
  name,
  label: defaultLabel,
  disabled,
  type,
  inputRef,
  data,
  objectclassUuid,
  onChangeValues,
  uuidCurrentSitemodelObjectclass,
  groupedFieldValues,
  fieldLayout,
  interdependentFilteringQuery,
  dateFormat = 'date-time'
}: DataFieldProps) => {
  const {
    datafield: dataFieldLayout = {},
    display_actions: displayActions = false
  } = fieldLayout || {}

  const { label: layoutLabel } = dataFieldLayout
  const label = layoutLabel || defaultLabel

  const commonProps = {
    id,
    name,
    label,
    autoComplete: 'off',
    style: {
      paddingLeft: 40
    },
    heightClass: 'medium' as const,
    disabled
  }

  const defaultDataFieldLayout = getDefaultDataFieldLayout(type, name)

  const { component: defaultComponent, filteraction: defaultFilteraction } =
    defaultDataFieldLayout

  const { component: layoutComponent, filteraction: layoutFilteraction } =
    dataFieldLayout || {}

  const component = layoutComponent || defaultComponent
  const filteraction = layoutFilteraction || defaultFilteraction

  if (!component || !data) {
    return null
  }

  if (component === 'DatePicker') {
    return (
      <DatePicker
        {...commonProps}
        ref={inputRef}
        value={data.value as string}
        onChange={onChangeValues}
        format={dateFormat}
      />
    )
  }

  if (component === 'Unifree') {
    return (
      <Unifree
        {...commonProps}
        ref={inputRef}
        value={data.value as string}
        onChange={onChangeValues}
      />
    )
  }

  if (component === 'AutocompleteMultiselection') {
    return (
      <RelationDataField
        {...{ name, objectclassUuid, groupedFieldValues, displayActions }}
      >
        <AutocompleteMultiselectionField
          {...commonProps}
          {...{
            inputRef,
            objectclassUuid,
            selectedOptions: Array.isArray(data.value)
              ? data.value.map(({ des, uuid }) => ({ id: uuid, value: des }))
              : [],
            onChangeValues,
            interdependentFilteringQuery
          }}
        />
      </RelationDataField>
    )
  }

  if (component === 'AutocompleteUniselection') {
    return (
      <RelationDataField
        {...{ name, objectclassUuid, groupedFieldValues, displayActions }}
      >
        <AutocompleteUniselectionField
          {...commonProps}
          {...{
            inputRef,
            objectclassUuid,
            onChangeValues,
            uuidCurrentSitemodelObjectclass,
            selectedOptions: Array.isArray(data.value) ? data.value : []
          }}
        />
      </RelationDataField>
    )
  }

  if (component === 'StandardUniselection') {
    if (!filteraction) {
      console.error('StandardUniselection must always have a filteraction')
      return null
    }

    return (
      <StandardUniselectionField
        {...commonProps}
        filteractionUuid={filteraction}
        {...{
          inputRef,
          onChangeValues,
          objectclassUuid,
          uuidCurrentSitemodelObjectclass,
          selectedOptions: Array.isArray(data.value) ? data.value : []
        }}
      />
    )
  }

  if (component === 'StandardMultiselection') {
    if (!filteraction) {
      console.error('StandardMultiselection must always have a filteraction')
      return null
    }

    return (
      <StandardMultiselectionField
        {...commonProps}
        filteractionUuid={filteraction}
        {...{
          inputRef,
          onChangeValues,
          objectclassUuid,
          uuidCurrentSitemodelObjectclass,
          selectedOptions: Array.isArray(data.value) ? data.value : []
        }}
      />
    )
  }

  return null
}

type FieldProps = {
  fieldLayout?: FilterFieldLayout
  props?: FilterField
  disabled?: boolean
  fieldValues: FieldValues
  handleChange?: (value: FieldValues) => void
  uuidCurrentSitemodelObjectclass?: string
  groupedFieldValues: GroupedFieldValues
  interdependentFilteringQuery?: FilterQuery
  dateFormat?: AllowedDateFormats
}

const Field = ({
  fieldLayout,
  props,
  fieldValues,
  handleChange,
  uuidCurrentSitemodelObjectclass,
  groupedFieldValues,
  interdependentFilteringQuery,
  disabled,
  dateFormat
}: FieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  if (!props?.type || !props?.name || !Object.keys(fieldValues).length) {
    return null
  }

  const { uuid, name, objectclassUuid = '' } = props
  const fieldData = fieldValues[name]

  const onChangeValues: DataFieldProps['onChangeValues'] = (value) => {
    handleChange?.({
      [name]: {
        ...fieldData,
        value
      }
    })
  }

  const onChangeOperator = (operator: FieldValues['']['operator']) => {
    // inputRef.current?.focus()
    handleChange?.({
      [name]: {
        ...fieldData,
        operator
      }
    })
  }

  return (
    <StyledField
      key={uuid}
      className='gx-2 mt-2 filter-field field-sm position-relative'
      fieldLayout={fieldLayout}
    >
      <DataField
        {...props}
        inputRef={inputRef}
        data={fieldData}
        {...{
          fieldLayout,
          objectclassUuid,
          onChangeValues,
          uuidCurrentSitemodelObjectclass,
          groupedFieldValues,
          interdependentFilteringQuery,
          disabled,
          dateFormat
        }}
      />
      <Operators
        currentOperator={fieldValues[name]?.operator}
        type={fieldData?.type === 'RELATION' ? 'array' : 'string'}
        handleChange={onChangeOperator}
      />
    </StyledField>
  )
}

export default Field
