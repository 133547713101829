import HeroBannerSecondary from 'component/HeroBannerSecondary'
import { HeroBannerSecondaryContainerProps } from './HeroBannerSecondaryContainer.types'
import { StyledContainer } from './styles'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const HeroBannerSecondaryContainer = ({
  props
}: HeroBannerSecondaryContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)
  return (
    <StyledContainer id={props.anchorIdentifier} style={styleProps}>
      <HeroBannerSecondary
        {...props}
        des={<SafeHtmlContent html={props.des} />}
        complement={<SafeHtmlContent html={props.complement} />}
      />
    </StyledContainer>
  )
}

export default HeroBannerSecondaryContainer
