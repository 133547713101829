import { useEffect, useState } from 'react'

import { useFetchObject } from 'sitemodel/shared/hooks'
import { ObjectType } from 'sitemodel/shared/types'
import FrameButton from './FrameButton'

type Props = {
  des: string
  complement: string
  uuid: string
  sitemodel_content?: string
  Document?: ObjectType[] | undefined
  uuidObjectClass?: string
}

const Frame = ({
  des,
  complement,
  uuid,
  sitemodel_content: sitemodelContent,
  Document: document,
  uuidObjectClass
}: Props) => {
  const [image, setImage] = useState<string>()

  const object = useFetchObject({
    objectUuid: (document?.[0] as ObjectType)?.uuid,
    objectclassUuid: window.idObjectclassDocument,
    showLoading: false
  })

  useEffect(() => {
    const des = (object?.url?.[0] as ObjectType)?.des
    if (des) {
      setImage(des)
    }
  }, [object])
  return (
    <div className='card mt-3 shadow-sm' style={{ borderColor: '#eeeff1' }}>
      {image && <img src={image} className='card-img-top' alt={des} />}
      <div className='card-body'>
        <h5 className='card-title text-capitalize'>{des}</h5>
        <p className='card-text text-muted'>{complement}</p>
        <FrameButton
          uuid={uuid}
          des={des}
          sitemodelContent={sitemodelContent as string}
          uuidObjectClass={uuidObjectClass?.toUpperCase()}
        />
      </div>
    </div>
  )
}

export default Frame
