import {
  FocusEventHandler,
  KeyboardEventHandler,
  useEffect,
  useId,
  useReducer,
  useRef,
  useState
} from 'react'
import {
  Container,
  MenuItems,
  MenuGroup,
  MenuGroupTrigger,
  MenuTrigger,
  MenuTriggerWrapper
} from './NavigationMenuBar.styles'
import { Text } from '@yes.technology/react-toolkit'

type ItemProps = {
  label: string
  href: string
}

type Placement = 'left' | 'right'

type NavigationMenuProps = {
  label: string
  items: ItemProps[]
  expanded: boolean
  active: boolean
  onToggleMenu: () => void
  renderLink?: (linkProps: ItemProps) => JSX.Element
  placement: Placement
}

const defaultLinkRenderer = ({ href, label }: ItemProps) => (
  <a href={href}>{label}</a>
)

const NavigationMenu = ({
  label,
  items,
  expanded,
  active,
  placement,
  onToggleMenu,
  renderLink = defaultLinkRenderer
}: NavigationMenuProps) => {
  const menuItemsId = useId()

  return (
    <MenuTriggerWrapper>
      <MenuTrigger placement={placement}>
        <Text variant='content-04'>|</Text>
        <button
          type='button'
          aria-haspopup
          aria-expanded={expanded}
          aria-controls={menuItemsId}
          onClick={onToggleMenu}
        >
          <Text
            variant={active || expanded ? 'content-emphasis-04' : 'content-04'}
          >
            {label}
          </Text>
        </button>
      </MenuTrigger>
      <Text as='h2' variant='content-emphasis-04'>
        {label}
      </Text>
      <MenuItems
        id={menuItemsId}
        visibleTabletUp={expanded}
        placement={placement}
      >
        {items.map((item) => (
          <Text as='li' variant='content-04' key={item.label}>
            {renderLink(item)}
          </Text>
        ))}
      </MenuItems>
    </MenuTriggerWrapper>
  )
}

export type NavigationMenuBarProps = {
  menus: Pick<NavigationMenuProps, 'label' | 'items'>[]
  activeMenuLabel: string
  label?: string
  placement?: Placement
} & Pick<NavigationMenuProps, 'renderLink'>

export default function NavigationMenuBar({
  placement = 'left',
  menus,
  activeMenuLabel,
  label,
  renderLink
}: NavigationMenuBarProps) {
  const menuGroupId = useId()
  const [menuGroupOpened, toggleMenuGroup] = useReducer(
    (opened) => !opened,
    false
  )
  const [expandedMenuLabel, setExpandedMenuLabel] = useState('')

  const getOnToggleMenu = (toggledMenuLabel: string) => () => {
    setExpandedMenuLabel((prevExpandedMenuLabel) =>
      prevExpandedMenuLabel === toggledMenuLabel ? '' : toggledMenuLabel
    )
  }

  const onBlurMenuGroup: FocusEventHandler = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setExpandedMenuLabel('')
    }
  }

  const menuGroupRef = useRef<HTMLUListElement>(null)
  const adjustMenuGroup = () => {
    const menuGroup = menuGroupRef.current

    if (!menuGroup) {
      return
    }

    const adjust = () => {
      if (window.innerWidth >= 768) {
        menuGroup.style.left = '0'
        return
      }

      if (menuGroup.getBoundingClientRect().left !== 0) {
        menuGroup.style.left = `-${menuGroup.getBoundingClientRect().left}px`
      }
    }
    adjust()

    window.addEventListener('resize', adjust)

    return () => {
      window.removeEventListener('resize', adjust)
    }
  }
  useEffect(adjustMenuGroup, [menuGroupOpened])

  const onKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'Escape') {
      menuGroupRef.current
        ?.querySelector<HTMLButtonElement>('[aria-expanded=true]')
        ?.focus()
      setExpandedMenuLabel('')
    }

    return true
  }

  return (
    <Container aria-label={label} {...{ onKeyDown }}>
      <MenuGroupTrigger
        type='button'
        aria-controls={menuGroupId}
        aria-expanded={menuGroupOpened}
        onClick={toggleMenuGroup}
      >
        <Text variant='content-04'>{activeMenuLabel}</Text>
      </MenuGroupTrigger>
      <MenuGroup id={menuGroupId} onBlur={onBlurMenuGroup} ref={menuGroupRef}>
        {menus.map((menu) => (
          <NavigationMenu
            key={menu.label}
            {...menu}
            {...{ renderLink, placement }}
            active={!expandedMenuLabel && activeMenuLabel === menu.label}
            expanded={expandedMenuLabel === menu.label}
            onToggleMenu={getOnToggleMenu(menu.label)}
          />
        ))}
      </MenuGroup>
    </Container>
  )
}
