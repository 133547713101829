import { useQueries } from '@tanstack/react-query'
import {
  FilteractionObject,
  useIsMobile,
  YesObject
} from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'

import { fetchFilteraction } from 'filteraction/FilteractionResolver'
import { TopAreaPositionType } from 'site/shared/types'
import { fetchYesObjectsWithFilteraction } from 'yesObject/shared/api/fetchYesObjectsWithFilteraction'
import NavigationMenuBar from './NavigationMenuBar'

type NavigationMenuBarProps = React.ComponentProps<typeof NavigationMenuBar>

const renderLink: NavigationMenuBarProps['renderLink'] = ({ href, label }) => (
  <a href={href}>{label}</a>
)

const defaultFetchFilteractionResult = async (
  filteractionId: string
): Promise<Pick<YesObject, 'des' | 'complement'>[] | null> => {
  const filteraction: FilteractionObject | undefined = await fetchFilteraction(
    filteractionId,
    false
  )

  if (!filteraction) {
    return null
  }

  const { objects } = await fetchYesObjectsWithFilteraction({
    filteraction,
    orderby: [
      {
        date_creation_row: 'asc' // temporary classification (sort) ;)
      }
    ]
  })

  return objects
}

export type NavigationMenuAreaProps = Pick<
  TopAreaPositionType,
  'menus' | 'align'
> & {
  fetchFilteractionResult?: typeof defaultFetchFilteractionResult
  isShowOnMobile?: boolean
}

export default function NavigationMenuArea({
  align: placement,
  menus = [],
  isShowOnMobile = true,
  fetchFilteractionResult = defaultFetchFilteractionResult
}: NavigationMenuAreaProps) {
  const { t } = useTranslation()

  const isMobile = useIsMobile()

  const label = t('navigation-menu.navigation')

  const filteractionResults = useQueries({
    queries: menus.map(({ filteraction }) => ({
      queryKey: ['filteraction-result', filteraction],
      queryFn: async () => {
        return fetchFilteractionResult(filteraction)
      }
    }))
  })

  const fetchedMenus: NavigationMenuBarProps['menus'] = menus.map(
    (menu, menuIndex) => ({
      label: menu.des,
      items: (filteractionResults[menuIndex].data || []).map((item) => ({
        label: item.des,
        href: item.complement
      }))
    })
  )

  if (!isShowOnMobile && isMobile) {
    return null
  }

  return (
    <NavigationMenuBar
      {...{ renderLink, label, placement }}
      menus={fetchedMenus}
      activeMenuLabel={label}
    />
  )
}
