import { YesObject } from '@yes.technology/react-toolkit'
import ArticleContainer from '../ArticleContainer'
import { ArticleButton } from '../ArticleContainer/ArticleContainer.types'
import useStyleMaps, { StyleMap } from 'site/Renderer/hooks/useStyleMap'

type ArticlesListContainerProps = {
  props: {
    items?: YesObject[]
    button_source_map?: { [uuid: string]: ArticleButton[] }
    styleMap?: StyleMap
    textColor?: string
  }
}

const ArticlesListContainer = ({ props }: ArticlesListContainerProps) => {
  const {
    items,
    button_source_map: buttonSourceMap,
    styleMap,
    textColor
  } = props

  const styleProps = useStyleMaps(styleMap)

  return (
    <div style={styleProps}>
      {items?.map((item: YesObject, index: number) => {
        return (
          <ArticleContainer
            key={item.uuid}
            props={{
              position: `${index % 2 === 0 ? 'right' : 'left'}`,
              des: item.des,
              complement: item.complement,
              imageUrl: item.documento,
              buttons: buttonSourceMap?.[item.uuid],
              textColor
            }}
          />
        )
      })}
    </div>
  )
}

export default ArticlesListContainer
