import { YesObject } from '@yes.technology/react-toolkit'

type SelectObjectSourceProps = {
  objectFromIndex?: YesObject
  objectFromUuid?: YesObject
  fetchObjectFromIndex: () => Promise<unknown>
  fetchObjectFromUuid: () => Promise<unknown>
  isFetchingObjectFromUuid: boolean
  isFetchingObjectFromIndex: boolean
}

export default function selectObjectSource({
  objectFromIndex,
  objectFromUuid,
  fetchObjectFromIndex,
  fetchObjectFromUuid,
  isFetchingObjectFromUuid,
  isFetchingObjectFromIndex
}: SelectObjectSourceProps) {
  return {
    yesObject: objectFromIndex || objectFromUuid,

    refetchObject: async () => {
      if (objectFromIndex) {
        return fetchObjectFromIndex()
      }

      if (objectFromUuid) {
        return fetchObjectFromUuid()
      }
    },

    isFetchingObject: isFetchingObjectFromUuid || isFetchingObjectFromIndex
  }
}
