import { ArrayFieldValue } from '../../types'

export type SelectionOption = {
  id: string
  value: string
  label: string
  code?: string
  uuid_Objectclass?: string
}

export const parseObjectsToOptions = (
  objects: ArrayFieldValue
): SelectionOption[] =>
  objects.map((object) => ({
    id: object?.uuid,
    value: object?.des,
    label: object?.des,
    code: object?.code,
    uuid_Objectclass: object?.uuid_Objectclass
  }))

export const parseOptionsToObjects = (
  options: SelectionOption[]
): ArrayFieldValue =>
  options?.map((option) => ({
    uuid: option.id,
    des: option.value,
    code: option.code || '',
    uuid_Objectclass: option.uuid_Objectclass || ''
  })) || []
