import React from 'react'
import CheckpointCard from '../CheckpointCard/CheckpointCard'
import { Checkpoint } from '../../types'
import { Container } from './styles'
import { useFetchYesObjects } from 'yesObject/shared'
import { TransferOrder } from 'component/TransferOrderCard/types'
import { CollapsibleCard, StatusObject } from '@yes.technology/react-toolkit'
import { useStatuses } from 'status/shared'
import { useTranslation } from 'react-i18n-lite'
import { SectionTitle } from 'component/TransportOrderCard/TransportOrderCard.styles'

interface CheckpointsSectionProps {
  checkpoints: Checkpoint[]
}

function CheckpointsSection({ checkpoints }: CheckpointsSectionProps) {
  const { t } = useTranslation()

  const { yesObjects: transferOrders }: { yesObjects: TransferOrder[] } =
    useFetchYesObjects({
      filterBody: {
        objectclass: window.uuidObjectclassTransferorder || '',
        query: {
          $and: [
            {
              'checkpoint.uuid': {
                $in: checkpoints?.map((c) => c.uuid)
              }
            }
          ]
        }
      },
      enabled: !!checkpoints?.length
    })

  const statuses = useStatuses({
    objectclassUuid: window.uuidObjectclassCheckpoint
  })

  const getStatus = (statusCode: string): StatusObject | undefined => {
    return statuses.find((status) => status.code === statusCode)
  }

  return (
    <CollapsibleCard
      hierarchyCardLevel={1}
      heightClass='doubleXSmall'
      headerColor='blue'
      centerHeaderElement={
        <SectionTitle>{t('transport-card.checkpoints')}</SectionTitle>
      }
    >
      <Container>
        {checkpoints.map((checkpoint) => (
          <CheckpointCard
            key={checkpoint.uuid}
            checkpoint={checkpoint}
            status={getStatus(checkpoint.uuid_status)}
            transferOrders={transferOrders}
          />
        ))}
      </Container>
    </CollapsibleCard>
  )
}

export default CheckpointsSection
