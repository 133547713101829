import { useTranslation } from 'react-i18n-lite'
import useTableContext from '../hooks/useTableContext'
import TableRow from './TableRow'
import { FieldSettings } from 'shared/types'

type TableBodyProps = {
  isLoading: boolean
  fieldSettings?: FieldSettings
}

function TableBody({ isLoading, fieldSettings }: TableBodyProps) {
  const { t } = useTranslation()

  const {
    objects,
    changedFieldValues,
    mode,
    informational,
    columnsNames,
    actionColumns
  } = useTableContext()

  if (isLoading) {
    return (
      <tbody>
        {[...Array(6)].map((_, insertionIndex) => (
          <TableRow
            key={insertionIndex}
            insertionIndex={insertionIndex.toString()}
            {...{ isLoading }}
          />
        ))}
      </tbody>
    )
  }

  if (!informational && mode === 'insert') {
    return (
      <tbody>
        {Object.keys(changedFieldValues).map((insertionIndex) => (
          <TableRow key={insertionIndex} {...{ insertionIndex, isLoading }} />
        ))}
      </tbody>
    )
  }

  const selectionColumnsLength = 1
  const contentCellsLength = columnsNames.length
  const actionCellsLength = actionColumns.length
  const totalColumnsLength =
    selectionColumnsLength + contentCellsLength + actionCellsLength

  return (
    <tbody>
      {objects.length === 0 && (
        <tr>
          <td className='p-3 text-center' colSpan={totalColumnsLength}>
            {t('data-table.no-records-found')}
          </td>
        </tr>
      )}

      {objects.map((rowObject) => (
        <TableRow
          key={rowObject.uuid}
          {...{ rowObject, isLoading, fieldSettings }}
        />
      ))}
    </tbody>
  )
}

export default TableBody
