type DownloadUrlProps = {
  url: string
  filename: string
}

export default function downloadUrl({ url, filename }: DownloadUrlProps) {
  const link = document.createElement('a')
  link.download = filename
  link.href = url
  link.style.visibility = 'hidden'

  document.body.appendChild(link)

  link.click()
  link.remove()
}
