import { Breakpoint } from 'shared/hooks/useBreakpoint'
import styled from 'styled-components'

export const SIZE_MAP: Record<string, Record<Breakpoint, number>> = {
  GenericObjectCard: { xl: 4, lg: 3, md: 2, sm: 1 },
  TaskObjectCard: { xl: 4, lg: 3, md: 2, sm: 1 },
  ContactObjectCard: { xl: 4, lg: 2, md: 2, sm: 1 },
  TransportOrderCard: { xl: 6, lg: 4, md: 3, sm: 1 }
}

export const KanbanMainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

export const NavigationContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 60px;
    background-color: #004099;
    color: #fff;
  }
`
