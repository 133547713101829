import { RefObject, useEffect, useState } from 'react'

const CHART_PADDING = 16

export type UseExpandedChartStylesProps = {
  expandedChart?: boolean
  chartRef: RefObject<HTMLDivElement>
  chartWrapperRef: RefObject<HTMLDivElement>
}

export default function useExpandedChartStyles({
  expandedChart,
  chartRef,
  chartWrapperRef
}: UseExpandedChartStylesProps) {
  const [originalChartSize, setOriginalChartSize] = useState({
    height: 0,
    width: 0
  })

  const [expandedChartWrapperSize, setExpandedChartWrapperSize] = useState({
    height: 0,
    width: 0
  })

  useEffect(() => {
    if (expandedChart) {
      const wrapperResizeObserver = new ResizeObserver(([chartParent]) => {
        const { height = 0, width = 0 } = chartParent.contentRect || {}

        setExpandedChartWrapperSize({
          height: height - CHART_PADDING * 2,
          width: width - CHART_PADDING * 2
        })
      })

      if (chartWrapperRef.current) {
        wrapperResizeObserver.observe(chartWrapperRef.current)
      }

      return () => {
        wrapperResizeObserver.disconnect()
      }
    }

    const chartResizeObserver = new ResizeObserver(([chartDiv]) => {
      const { height = 0, width = 0 } = chartDiv.contentRect || {}

      setOriginalChartSize({
        height,
        width
      })
    })

    if (chartRef.current) {
      chartResizeObserver.observe(chartRef.current)
    }

    return () => {
      chartResizeObserver.disconnect()
    }
  }, [chartRef, chartWrapperRef, expandedChart])

  const bestExpandedScale = Math.min(
    expandedChartWrapperSize.height / originalChartSize.height,
    expandedChartWrapperSize.width / originalChartSize.width
  )

  const expandedChartStyles = expandedChart
    ? {
        ...originalChartSize,
        transform: `scale(${bestExpandedScale})`,
        transformOrigin: 'center top',
        margin: 'auto'
      }
    : undefined

  return expandedChartStyles
}
