import { render, screen } from '@testing-library/react'
import { MemoryRouter } from 'react-router-dom'

import Position from 'site/Render/components/TopArea/Position'
import { RepresentationEnum } from 'site/shared/enums'

it('Position RepresentationEnum.LogoArea', () => {
  const props = {
    representation: RepresentationEnum.LogoArea,
    des: 'my logo',
    image: 'https://my.image.png',
    link: '/home'
  }

  render(<Position {...props} />, { wrapper: MemoryRouter })

  const image = screen.getByAltText(/my logo/i)
  expect(image).toHaveAttribute('src', 'https://my.image.png')

  const link = screen.getByRole('link')
  expect(link).toHaveAttribute('href', '/home')
})

it('Position RepresentationEnum.Component exist', async () => {
  const props = {
    representation: RepresentationEnum.Component,
    des: 'anything',
    component: 'LogoArea',
    link: '/',
    image: '/'
  }

  const { container } = render(<Position {...props} />, {
    wrapper: MemoryRouter
  })

  expect(container.firstChild).not.toBeNull()
})

it('Position RepresentationEnum.Component not exist', async () => {
  const props = {
    representation: RepresentationEnum.Component,
    des: 'anything'
  }

  const { container } = render(<Position {...props} />, {
    wrapper: MemoryRouter
  })
  expect(container.firstChild).toBeNull()
})

it('Position RepresentationEnum.Des', () => {
  const props = {
    representation: RepresentationEnum.Des,
    des: 'my des'
  }

  render(<Position {...props} />, { wrapper: MemoryRouter })

  screen.getByText(/my des/i)
})

it('Position RepresentationEnum.Link', () => {
  const props = {
    representation: RepresentationEnum.Link,
    des: 'my link',
    link: '/'
  }

  render(<Position {...props} />, { wrapper: MemoryRouter })

  screen.getByText(/my link/i)

  const link = screen.getByRole('link')
  expect(link).toHaveAttribute('href', '/')
})

it('Position RepresentationEnum.Link not exist', () => {
  const props = {
    representation: RepresentationEnum.Link,
    des: 'anything'
  }

  const { container } = render(<Position {...props} />, {
    wrapper: MemoryRouter
  })
  expect(container.firstChild).toBeNull()
})

it('Position default', () => {
  const props = {
    representation: RepresentationEnum.Icon,
    des: 'anything'
  }

  const { container } = render(<Position {...props} />, {
    wrapper: MemoryRouter
  })
  expect(container.firstChild).toBeNull()
})
