type FormProps = {
  onSubmit: (event: React.FormEvent) => void
  children: React.ReactNode
  style?: React.CSSProperties
}
const Form = ({ onSubmit, children, style }: FormProps) => {
  return (
    <form onSubmit={onSubmit} style={style}>
      {children}
    </form>
  )
}

export default Form
