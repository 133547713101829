import { FieldSettings } from 'shared/types'
import evaluateFieldDependency from '../../shared/evaluateFieldDependency/evaluateFieldDependency'
import {
  FieldValues,
  FilterField,
  FilterSectionLayout,
  GroupedFieldValues
} from '../../types'
import { InterdependentFilteringConfig } from '../../types/layout'
import Field from '../Field/Field'

type FilterFormProps = {
  sectionLayout?: FilterSectionLayout
  fields: FilterField[]
  fieldValues: FieldValues
  handleChange?: (value: FieldValues) => void
  interdependentFilteringConfig?: InterdependentFilteringConfig
  uuidCurrentSitemodelObjectclass?: string
  groupedFieldValues: GroupedFieldValues
  fieldSettings?: FieldSettings
}

const Fields = ({
  sectionLayout,
  fields,
  fieldValues,
  handleChange,
  uuidCurrentSitemodelObjectclass,
  groupedFieldValues,
  interdependentFilteringConfig,
  fieldSettings
}: FilterFormProps) => {
  return (
    <div className='container-fluid px-0'>
      <div className='row align-items-end'>
        {fields.map((field) => {
          if (!field.uuid) return null

          const {
            disabled,
            interdependentFilteringQuery,
            dependant,
            dependantFieldName
          } = evaluateFieldDependency({
            fieldName: field.name || '',
            fieldValues,
            interdependentFilteringConfig
          })

          const handleChangeAndClearDependant: typeof handleChange = (
            value
          ) => {
            if (!handleChange) {
              return
            }

            if (!dependantFieldName || !dependant?.value.length) {
              handleChange(value)
              return
            }

            handleChange({
              ...value,
              [dependantFieldName]: {
                ...dependant,
                value: Array.isArray(dependant.value) ? [] : ''
              }
            })
          }

          const dateFormat =
            fieldSettings?.[field?.name as string]?.date_format || 'date-time'

          return (
            <Field
              key={field.uuid}
              props={field}
              fieldLayout={sectionLayout?.filter_fields.find(
                (fieldLayout) => fieldLayout.field_uuid === field.uuid
              )}
              handleChange={handleChangeAndClearDependant}
              {...{
                fieldValues,
                uuidCurrentSitemodelObjectclass,
                groupedFieldValues,
                interdependentFilteringQuery,
                disabled,
                dateFormat
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Fields
