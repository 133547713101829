import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background: #006bff;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 40px 16px;
  place-content: center;
  text-align: center;
  max-width: 1200px;

  @media (min-width: 768px) {
    gap: 20px;
    margin: 20px 0;
    font-size: 24px;
    line-height: 32px;
  }
`

export const Des = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 48px;
  }
`

export const Complement = styled.p`
  margin: 0;
  color: #ffffff;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 32px;

    > * {
      max-width: 420px;
    }
  }
`
