import SupportBannerSecondary from 'component/SupportBannerSecondary'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { SupportBannerSecondaryContainerProps } from './SupportBannerSecondaryContainer.types'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'

const SupportBannerSecondaryContainer = ({
  props
}: SupportBannerSecondaryContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)

  return (
    <SupportBannerSecondary
      {...props}
      style={styleProps}
      des={props.des && <SafeHtmlContent html={props.des} />}
      complement={
        props.complement && <SafeHtmlContent html={props.complement} />
      }
    />
  )
}

export default SupportBannerSecondaryContainer
