import { ArrayFieldValue } from 'site/Renderer/components/FilterFields/types'

const uuidObjectclassSituation = window.uuidObjectclassSituation
export const uuidObjectclassStatus = window.uuidObjectclassStatus
const statusField = window.statusField
const situationField = window.situationField
const uuidInitialValueSituation = window.uuidInitialValueSituation

export const specialRelationFields = [
  {
    columnJson: statusField,
    objectclassUuid: uuidObjectclassStatus
  },
  {
    columnJson: situationField,
    objectclassUuid: uuidObjectclassSituation
  }
]

export const isSpecialRelationFieldByObjectclass = (objectclassUuid: string) =>
  specialRelationFields.some(
    (specialField) =>
      specialField.objectclassUuid?.toUpperCase() ===
      objectclassUuid?.toUpperCase()
  )

export const isSpecialRelationFieldByColumnJson = (columnJson: string) =>
  specialRelationFields.some(
    (specialField) =>
      specialField.columnJson?.toUpperCase() === columnJson?.toUpperCase()
  )

export const isSituationField = (columnJson: string) =>
  columnJson?.toUpperCase() === situationField?.toUpperCase()

export const isStatusField = (columnJson: string) =>
  columnJson?.toUpperCase() === statusField?.toUpperCase()

export const isSituationObjectclass = (objectclassUuid?: string) =>
  objectclassUuid?.toUpperCase() === uuidObjectclassSituation?.toUpperCase()

export const isStatusObjectclass = (objectclassUuid: string) =>
  objectclassUuid?.toUpperCase() === uuidObjectclassStatus?.toUpperCase()

// Maybe soon we should get this data via API to work with the translation.
export const situationInitialValue: ArrayFieldValue[number] = {
  uuid: uuidInitialValueSituation,
  des: 'Ativo',
  code: '40',
  uuid_Objectclass: uuidObjectclassSituation
}
