import {
  Button,
  Pagination,
  SortOption,
  SyntheticButton,
  YesObject
} from '@yes.technology/react-toolkit'
import { Link, To } from 'react-router-dom'
import usePersistentSelection from '../../FilterFields/hooks/usePersistentSelection'
import { useTranslation } from 'react-i18n-lite'
import DocumentActions from './DocumentActions'
import { useSiteState } from 'siteState/shared'
import useNavigateMergingState from 'shared/hooks/useNavigateMergingState/useNavigateMergingState'

export type ActionsProps = {
  informational?: boolean
  idObjectClass: string
  object: YesObject
  pagination?: Pagination
  sortOptions?: SortOption[]
  objects?: YesObject[]
  target?: '_self' | '_blank'
  type?: 'view' | 'document' | null
  objectclassQueryString: string
}

const Actions = ({
  idObjectClass,
  object,
  pagination,
  sortOptions,
  objects,
  type,
  target = '_self',
  objectclassQueryString
}: ActionsProps) => {
  const [_, setObjectIndex] = useSiteState<number | number>('object-index')

  const { t } = useTranslation()
  const navigateMergingState = useNavigateMergingState()

  const offset = pagination?.offset || 0
  const objectIndexInPage =
    objects?.findIndex((obj) => obj.uuid === object.uuid) || 0
  const objectIndex = offset + objectIndexInPage

  const { selectAndLink, shouldRenderSelectAndLink } = usePersistentSelection()

  const getVisualizeOption = (to: To) => ({
    children: 'Visualizar',
    onClick: () => {
      setObjectIndex(objectIndex)
      navigateMergingState(to, {
        state: {
          sortOptions
        }
      })
    }
  })

  const selectAndLinkOption = {
    children: t('select-and-link.action'),
    onClick: () => selectAndLink([object])
  }

  const getOptions = (to: To) => [getVisualizeOption(to), selectAndLinkOption]

  if (type === 'document') {
    return (
      <DocumentActions
        object={object}
        target={target}
        getOptions={getOptions}
      />
    )
  }

  if (type === null) {
    return <></>
  }

  const path = {
    search: `?object-uuid=${object.uuid}&${objectclassQueryString}=${idObjectClass}`,
    hash: '#object-view'
  }

  return (
    <>
      {shouldRenderSelectAndLink() ? (
        <SyntheticButton
          options={getOptions(path)}
          heightClass='small'
          dropdownLabel='more'
        />
      ) : (
        <Link
          target={target}
          to={path}
          onClick={() => setObjectIndex(objectIndex)}
          state={{
            sortOptions
          }}
          replace
        >
          <Button variant='secondary' heightClass='small'>
            Visualizar
          </Button>
        </Link>
      )}
    </>
  )
}

export default Actions
