import { FilteractionObject } from '@yes.technology/react-toolkit'

const INTERPOLATION_PATTERN = /\{\{\s?([\w\-]+)\s?\}\}/g
const OBJECT_PATTERN = /[{}]/g

function interpolateFilteractionQuery(
  filteraction: FilteractionObject,
  object?: Record<string, any>
) {
  const query = filteraction.query

  const matches = query.match(INTERPOLATION_PATTERN)

  if (!matches || !object) return query

  return matches.reduce((queryAcc, param) => {
    const objectKey = param.replace(OBJECT_PATTERN, '')

    const value = object[objectKey]

    if (value && typeof value === 'string') {
      return queryAcc.replace(param, value)
    }

    return queryAcc
  }, query)
}

export default interpolateFilteractionQuery
