import { Record, String, Array, Unknown, Optional } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

type AccordionItem = {
  uuid: string
  des: string
  complement: string
}

export type AccordionProps = {
  props: {
    items: AccordionItem[]
    styleMap?: StyleMap
  }
}

const AccordionItemGuard = Record({
  uuid: String,
  des: String,
  complement: String
})

export const AccordionTypeGuard = Record({
  items: Array(AccordionItemGuard),
  styleMap: Optional(Unknown)
})
