import { render, screen } from '@testing-library/react'
import { MemoryRouter } from 'react-router-dom'

// redux
import configureMockStore from 'redux-mock-store'
import { Provider } from 'react-redux'

import TopArea from 'site/Render/components/TopArea'
import layoutMock from 'site/shared/mocks/layout'

const mockStore = configureMockStore([])

const initialState = {
  authentication: {
    token: '123456'
  }
}

const store = mockStore(initialState)

it('Test all TopArea positions', () => {
  render(
    <Provider store={store}>
      <TopArea layout={layoutMock} />
    </Provider>,
    { wrapper: MemoryRouter }
  )

  const image = screen.getByAltText(/my logo/i)
  expect(image).toHaveAttribute('src', 'https://my.image.png')

  const link = screen.getAllByRole('link')
  expect(link[0]).toHaveAttribute('href', '/home')
  expect(link[1]).toHaveAttribute('href', '/my-link')
  expect(screen.getByText(/my des/i))
  expect(screen.getByText(/my link/i))
  expect(screen.getByText(/configure/i))
  expect(screen.getByText(/logout/i))
})
