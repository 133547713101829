import {
  Field,
  PDFAreaLine,
  PDFCard,
  YesObject
} from '@yes.technology/react-toolkit'
import getStyles from './styles'
import SubCard from '../SubCard/SubCard'

interface ObjectCardProps {
  fields: Field[]
  relationClasses: Field[]
  object: YesObject
  title: string
}

function ObjectCard({
  object,
  title,
  fields,
  relationClasses
}: ObjectCardProps) {
  const styles = getStyles()

  return (
    <PDFCard style={styles.mainCard} noGutters>
      <PDFAreaLine
        des={title}
        heightClass='doubleXSmall'
        colorScheme='blue'
        centered
      />
      <SubCard title='Dados gerais' fields={fields} object={object} />
      {relationClasses?.map(
        (relationClass) =>
          object?.[relationClass.column_json] && (
            <SubCard
              key={object.uuid + relationClass.column_json}
              title={relationClass.display_text.des}
              fields={Object.values(
                relationClass.relation?.object_class?.fields || {}
              )}
              object={(object[relationClass.column_json] as YesObject[])[0]} // TODO: Deal with multiplicity
            />
          )
      )}
    </PDFCard>
  )
}

export default ObjectCard
