import { StatusObject } from '@yes.technology/react-toolkit'
import useStatusActions from './useStatusActions'
import useStatuses from './useStatuses'

type UseInitialStatusProps = {
  objectclassUuid: string
}

export default function useInitialStatus({
  objectclassUuid
}: UseInitialStatusProps): StatusObject | undefined {
  const statuses = useStatuses({
    objectclassUuid
  })

  const { statusActions } = useStatusActions({ statuses })

  const initialStatusAction = statusActions.find(
    (statusAction) => !statusAction.allowed_origin_status
  )

  return initialStatusAction?.destination_status?.[0]
}
