import {
  CardWithAreaLine,
  CollapsibleCard
} from '@yes.technology/react-toolkit'
import styled from 'styled-components'
import { FilterButton } from '../../FilterFields.styles'

export const StyledCardWithAreaLine = styled(CardWithAreaLine)<{
  backgroundColor?: string
  color?: string
}>`
  & > :first-child {
    nav {
      background-color: ${({ backgroundColor }) =>
        backgroundColor || '#006bff'};
      padding: 12px 40px;

      @media (max-width: 768px) {
        height: 100%;
      }

      > div * {
        ${({ color }) => color && `color: ${color};`}
      }

      @media (min-width: 768px) {
        > div > div {
          width: 33.333%;
        }
      }
    }
  }
`

export const StyledCollapsibleCard = styled(StyledCardWithAreaLine).attrs({
  as: CollapsibleCard
})``

export const StyledFilterButton = styled(FilterButton)`
  @media (min-width: 768px) {
    margin-right: 24px;
  }
`

export const FirstElement = styled.div<{ color?: string }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ color }) => color || '#ffffff'};
  width: fill-available;
`

export const CenterWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`
