import useStyleMaps, { StyleMap } from 'site/Renderer/hooks/useStyleMap'
import { Container, Image, Subtitle, Title } from './Unauthorized.styles'

export interface UnauthorizedProps {
  des?: string
  complement?: string
  imageSrc?: string
  styleMap?: StyleMap
}

const Unauthorized = ({
  des,
  complement,
  imageSrc,
  styleMap
}: UnauthorizedProps) => {
  const styleProps = useStyleMaps(styleMap)

  return (
    <Container style={styleProps}>
      {imageSrc && <Image src={imageSrc} alt='Unauthorized access' />}
      <Title>{des ?? 'Unauthorized'}</Title>
      {complement && <Subtitle>{complement}</Subtitle>}
    </Container>
  )
}

export default Unauthorized
