import { YesObject } from '@yes.technology/react-toolkit'
import {
  ComponentPropsStylesType,
  ComponentPropsType
} from 'sitemodel/shared/types/sitemodel.type'

/**
 * Applies styles to component props based on a style configuration.
 * @param props - The component props.
 * @param styles - The style configuration.
 * @returns The component props with styles applied.
 */
export const applyStylesToProps = (
  props: ComponentPropsType,
  styles: ComponentPropsStylesType
) => {
  return Object.entries(props).reduce((acc, [propName, propValue]) => {
    const styleConfig = styles[propName]

    if (!styleConfig) {
      acc[propName] = propValue
      return acc
    }

    const isArrayOfYesObjectsWithReplacement =
      styleConfig.replacement === 'in_list' && Array.isArray(propValue)

    const hasDirectStyleValueForReplacement =
      styleConfig.replacement === 'direct' && styleConfig.property_value

    if (isArrayOfYesObjectsWithReplacement) {
      acc[propName] = (propValue as YesObject[]).map((object) => {
        const propertyValue =
          styleConfig.property_value ||
          object[styleConfig.property_value_key as string]
        return {
          ...object,
          [styleConfig.key]: `<span style="${styleConfig.property}: ${propertyValue}">${object[styleConfig.key]}</span>`
        }
      })
    } else if (hasDirectStyleValueForReplacement) {
      acc[propName] =
        `<span style="${styleConfig.property}: ${styleConfig.property_value}">${props[styleConfig.key]}</span>`
    }

    return acc
  }, {} as ComponentPropsType)
}
