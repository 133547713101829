import { Fields } from '@yes.technology/react-toolkit'
import { FieldSettings } from 'shared/types'

export function updateDisplayText(
  fields: Fields,
  fieldSettings: FieldSettings
): Fields {
  if (!fieldSettings || !fields) {
    return fields
  }

  const updatedFields: Fields = { ...fields }

  Object.entries(fieldSettings).forEach(([columnName, configField]) => {
    const originalField = updatedFields[columnName]

    if (originalField && configField.display_text?.des) {
      updatedFields[columnName] = {
        ...originalField,
        display_text: {
          ...originalField.display_text,
          des: configField.display_text.des
        }
      }
    }
  })

  return updatedFields
}
