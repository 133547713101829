import { Renderer } from 'site/Renderer'
import { SitemodelContext } from './hooks/useSitemodel'
import { SitemodelContextValue } from 'sitemodel/shared/types'
import SiteRenderContainer from 'site/Render/Container'
import NotFoundPage from 'infrastructure/NotFoundPage'

interface Render {
  content: SitemodelContextValue
  isLoading: boolean
}

const Render = ({ content, isLoading }: Render) => {
  if (!isLoading && !Object.keys(content).length) {
    return <NotFoundPage />
  }

  if (!content?.service) {
    return <SiteRenderContainer content={content} />
  }

  return (
    <SitemodelContext.Provider value={content}>
      <Renderer {...content} />
    </SitemodelContext.Provider>
  )
}

export default Render
