import { useDocumentTitle } from 'infrastructure/shared/hooks'
import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorMessage from 'shared/components/ErrorMessage'
import { useFetchObject, useSitemodelContent } from 'sitemodel/shared/hooks'
import { ApiSystemType } from 'infrastructure/Application/Container'
import { AuthenticationType } from 'authentication/shared/types'
import Iframe from 'shared/components/Iframe'
import useIframe from 'site/Render/hooks/useIframe'
import TopLine from 'site/Renderer/components/TopLine'

type EventDataMessage = {
  finishedInteraction?: boolean
}

const WorkFlowEngineContainer: React.FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const { uuid } = useParams<string>()
  const sitemodel = useFetchObject({
    objectUuid: window.defaultSitemodelUuidWorkflowEngine,
    objectclassUuid: window.idObjectclassSitemodel,
    showLoading: true,
    onError: setErrorMessage
  })

  useDocumentTitle({ title: sitemodel?.des })

  const content = useSitemodelContent({
    sitemodelContent: sitemodel?.sitemodel_content
  })

  const topAreaLayout = content?.top_area_layout

  const isLoading = useSelector<ApiSystemType>(
    ({ api: { isPending } }) => isPending
  )

  const token = useSelector(
    ({ authentication }: AuthenticationType) => authentication.token
  )
  const url = window.waWorkflowengineUrl

  const queryString = `token=${token}&lang=pt-BR`
  const iframeSrc = `${url}workflowmodel/new/${uuid}?${queryString}`
  const { iframeAttributes, receiveMessage } = useIframe({
    origin: url,
    src: iframeSrc
  })

  const onReceivedMessage = useCallback(
    (event: MessageEvent) => {
      receiveMessage(event)
      const { finishedInteraction }: EventDataMessage = event.data

      if (finishedInteraction) {
        navigate(`/${window.defaultWorkflowEngineListUuid}`)
      }
    },
    [navigate, receiveMessage]
  )

  useEffect(() => {
    window.addEventListener('message', onReceivedMessage)
    return () => window.removeEventListener('message', onReceivedMessage)
  }, [onReceivedMessage])

  return (
    <>
      <TopLine props={{ layout: topAreaLayout }} />
      {uuid && iframeAttributes ? (
        <Iframe key={iframeAttributes.src} {...iframeAttributes} />
      ) : null}
      {!isLoading && errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  )
}

export default WorkFlowEngineContainer
