export const MODIFIABLE_FIELDS_DENYLIST = [
  'uuid',
  'sid',
  'date_creation_row',
  'date_modification_row',
  'situation',
  'uuid_status',
  'uuid_user_creation_row',
  'uuid_user_modification_row',
  'version'
]
