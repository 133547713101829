import {
  DocumentListCard,
  feedbackcard,
  Text,
  YesLoading
} from '@yes.technology/react-toolkit'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18n-lite'
import downloadUrl from 'shared/utils/url/downloadUrl'

const DownloaderLoading = () => {
  const { t } = useTranslation()

  return (
    <div
      className='h-100 w-100 position-fixed d-flex align-items-center justify-content-center text-center p-4'
      style={{ top: 0, left: 0, background: '#fff' }}
    >
      <DocumentListCard
        className='d-flex flex-column m-auto align-items-center p-4 w-100'
        style={{ minWidth: 269, maxWidth: 358 }}
      >
        <YesLoading />
        <Text as='h2' variant='content-emphasis-06' className='mt-4 mb-4'>
          {t('report.wait-a-moment')}
          <br />
          {t('report.download-being-generated')}
        </Text>
        <Text as='div' variant='content-05'>
          <p className='m-0'>{t('report.can-take-some-minutes')}</p>
          <p className='m-0'>{t('report.please-wait')}</p>
        </Text>
      </DocumentListCard>
    </div>
  )
}

type DocumentDownloaderProps = {
  url: string | null
  filename: string
  loading?: boolean
}

export default function DocumentDownloader({
  url,
  filename,
  loading
}: DocumentDownloaderProps) {
  const { t } = useTranslation()

  useEffect(() => {
    if (url) {
      downloadUrl({ url, filename })

      feedbackcard(t('report.download-finished-title'), {
        message: t('report.download-finished-message'),
        type: 'success'
      })
    }
  }, [url, t, filename])

  return loading ? <DownloaderLoading /> : null
}
