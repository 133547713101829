import { useEffect, useState } from 'react'
import { SkeletonDots, YesObject } from '@yes.technology/react-toolkit'

import styled from 'styled-components'
import { useFetchYesObjects } from 'yesObject/shared'

export const Image = styled.img``

type ImageDocumentProps = {
  documents: YesObject[] | string
  width?: string
  height?: string
}

const ImageDocument = ({ documents, width, height }: ImageDocumentProps) => {
  const [image, setImage] = useState<string>('')

  const { yesObjects, isLoading, fetchYesObjects } = useFetchYesObjects({
    filterBody: {
      query: {
        $and: [
          {
            'uuid.des': {
              $eq: typeof documents === 'object' ? documents?.[0]?.uuid : ''
            }
          }
        ]
      },
      objectclass: window.idObjectclassDocument,
      pagination: { limit: 10, offset: 0 },
      orderby: [{ date_creation_row: 'desc' }]
    },
    enabled: false
  })

  useEffect(() => {
    if (documents && typeof documents === 'object') {
      fetchYesObjects()
    } else {
      setImage(documents)
    }
  }, [documents, fetchYesObjects])

  useEffect(() => {
    const storageUrl = (yesObjects[0]?.url?.[0] as YesObject)?.des
    if (storageUrl) {
      setImage(storageUrl)
    }
  }, [yesObjects])

  return (
    <>
      {isLoading && <SkeletonDots />}
      {!isLoading && image && (
        <Image
          src={image}
          alt={yesObjects?.[0]?.des || image}
          {...{ width, height }}
        />
      )}
    </>
  )
}

export default ImageDocument
