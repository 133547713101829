import { FilteractionObject } from '@yes.technology/react-toolkit'
import apiAsync from 'infrastructure/shared/api/api-async'

export default async function fetchFilteractions(
  objectclassUuid: unknown,
  showLoading: boolean
): Promise<FilteractionObject[] | null> {
  const response = await apiAsync.requestSync({
    endpoint: 'msPlatform',
    path: 'objects/_filter',
    method: 'POST',
    obj: {
      query: {
        'situation.des': {
          $eq: '40'
        },
        'filter_action_objectclass.uuid': {
          $in: [objectclassUuid]
        }
      },
      objectclass: 'UUID_OBJECTCLASS_FILTERACTION',
      orderby: [],
      pagination: {
        offset: 0,
        limit: 100
      }
    },
    showLoading
  })

  return response.data.objects as FilteractionObject[] | null
}
