import { Fields, YesObject } from '@yes.technology/react-toolkit'

const REGEX_PATTERN = /\{\{([^\}]+)\}\}/g

export interface ConcatFields {
  [fieldname: string]: string
}

export function concatenateField(object: YesObject, sourcemap: string): string {
  return sourcemap.replace(REGEX_PATTERN, (match, p1) => {
    const keys = p1.split('.')
    const value = getValueFromObject(object, keys)
    if (value !== undefined) {
      return value
    }
    return ''
  })
}

function getValueFromObject(
  obj: YesObject,
  keys: string[]
): string | undefined {
  let value: any = obj
  for (const key of keys) {
    if (value?.[key] !== undefined) {
      value = value[key]
    } else {
      return undefined
    }
  }
  return typeof value === 'string' ? value : undefined
}

export function concatenateFields(
  concatFields: ConcatFields,
  yesObject: YesObject
) {
  return Object.keys(concatFields || {}).reduce((acc, fieldname) => {
    if (concatFields[fieldname]) {
      return {
        ...acc,
        [fieldname]: concatenateField(yesObject, concatFields[fieldname])
      }
    }
    return acc
  }, yesObject)
}

export const concatenateDisplayText = ({
  concatFields,
  fields,
  columnName
}: {
  concatFields?: ConcatFields
  fields?: Fields
  columnName: string
}) => {
  const concatColumnPattern = concatFields?.[columnName]

  return (
    concatColumnPattern?.replace(REGEX_PATTERN, (substring, keys) => {
      const key = keys.split('.')[0]
      return fields?.[key]?.display_text.des || ''
    }) || fields?.[columnName]?.display_text.des
  )
}
