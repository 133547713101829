import {
  Field,
  FilteractionObject,
  formatDateTime,
  SituationObject
} from '@yes.technology/react-toolkit'

import { isSituationObjectclass } from 'shared/utils/specialRelationFields/specialRelationFields'
import { FieldValues, FieldValuesValue } from '../../types'
import { normalizeOperator } from '../../shared/fields'
import { decodeQuery } from '../../shared/FilterLanguage'

type GetValueProps = {
  currentField?: Field
  value: FieldValuesValue
  situations: Pick<
    SituationObject,
    'code' | 'des' | 'uuid' | 'uuid_Objectclass'
  >[]
}

const getValue = ({
  currentField,
  value,
  situations
}: GetValueProps): FieldValuesValue => {
  if (value && currentField?.type === 'DATETIME') {
    return formatDateTime(new Date(value as string), 'pt-BR')
  } else if (
    !currentField?.relation_objectclass_uuid ||
    !isSituationObjectclass(currentField.relation_objectclass_uuid)
  ) {
    return value
  }

  const situation = situations.find(({ code }) => code === value)

  return situation
    ? [
        {
          code: situation.code,
          des: situation.des,
          uuid: situation.uuid,
          uuid_Objectclass: situation.uuid_Objectclass
        }
      ]
    : value
}

type NormalizeFilterGroupProps = {
  filterGroup: {
    [fieldKey: string]: Pick<FieldValues[''], 'value' | 'operator'>
  }
  sections: Field[][]
  situations: GetValueProps['situations']
}

const normalizeFilterGroup = ({
  filterGroup,
  sections,
  situations
}: NormalizeFilterGroupProps): FieldValues =>
  Object.fromEntries(
    Object.entries(filterGroup).map(([fieldKey, fieldValue]) => {
      const currentField = sections
        .flat()
        .find((field) => field?.column_json === fieldKey)
      const { type = 'STRING' } = currentField || {}
      const { value, operator: originalOperator } = fieldValue

      return [
        fieldKey,
        {
          value: getValue({
            situations,
            value,
            currentField
          }),
          operator: normalizeOperator({
            originalOperator,
            fieldType: type
          }),
          type
        }
      ]
    })
  )

type PopulateFilterStateProps = {
  initialFieldValues: FieldValues
  values: {
    [group: string]: {
      [fieldKey: string]: Pick<FieldValues[''], 'value' | 'operator'>
    }
  }
  sections: Field[][]
  situations: GetValueProps['situations']
}

const getQueryData = ({
  initialFieldValues,
  values,
  sections,
  situations
}: PopulateFilterStateProps) => {
  return Object.fromEntries(
    Object.entries(values).map(([groupKey, filterGroup]) => [
      groupKey,
      {
        ...initialFieldValues,
        ...normalizeFilterGroup({ filterGroup, sections: sections, situations })
      }
    ])
  )
}

type Filteraction = Pick<
  FilteractionObject,
  'uuid' | 'des' | 'query' | 'complement'
>

type getQueryDataFromFilteractionProps = {
  sections: PopulateFilterStateProps['sections']
  situations: GetValueProps['situations']
  initialFieldValues: FieldValues
  filteraction: Filteraction
}

const getQueryDataFromFilteraction = ({
  sections: fields,
  situations,
  initialFieldValues,
  filteraction
}: getQueryDataFromFilteractionProps) => {
  const { values, composition } = decodeQuery(filteraction.query)

  const queryData = getQueryData({
    initialFieldValues,
    values,
    sections: fields,
    situations
  })

  return { queryData, composition }
}

export default getQueryDataFromFilteraction
