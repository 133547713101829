import {
  Field,
  RelationClass as RelationClassType,
  SortOption,
  YesObject
} from '@yes.technology/react-toolkit'
import { useObjectclass } from 'objectclass/shared'
import { useEffect, useMemo, useRef } from 'react'
import DataTable from 'site/Renderer/components/DataTables'
import { DataTableConfigProps } from 'site/Renderer/components/DataTables/DataTable'
import useTableLayouts from 'site/Renderer/components/DataTables/hooks/useTableLayouts'
import useTableMode from 'site/Renderer/components/DataTables/hooks/useTableMode'
import useChangedFieldValues from 'site/Renderer/components/DataTables/hooks/useChangedFieldValues'
import { useFetchYesObjects } from 'yesObject/shared'
import getRelationCounterpartField from '../../lib/getRelationCountertpartField/getRelationCounterpartField'
import { updateDisplayText } from 'shared/utils'
import { FieldSettings } from 'shared/types'

type RelationClassProps = DataTableConfigProps & {
  relationFieldRelation?: RelationClassType
  fieldValues?: YesObject
  field?: Field
  informational?: boolean
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  fieldSettings?: FieldSettings
}

const RelationClass = ({
  relationFieldRelation,
  fieldValues,
  layoutClassificationUuid,
  hierarchyLevel,
  actionButton,
  field,
  informational,
  navigationSliderBackgroundColor,
  navigationSliderColor,
  fieldSettings
}: RelationClassProps) => {
  const objectclassUuid = relationFieldRelation?.uuid_objectclass || ''

  const { objectclass } = useObjectclass({ objectclassUuid })

  const { fields = {} } = objectclass || {}

  const updatedFields = useMemo(
    () => updateDisplayText(fields, fieldSettings),
    [fieldSettings, fields]
  )

  const columns = useMemo(() => Object.keys(updatedFields), [updatedFields])

  const columnsLayouts = useTableLayouts({ layoutClassificationUuid })

  const orderBy = columnsLayouts[0].defaultOrderBy.map(
    ([key, value]) => ({ [key]: value }) as SortOption
  )

  const columnsToRemove = columnsLayouts?.[0]?.removeColumns ?? []

  columnsToRemove.forEach((columnToRemove) => {
    const index = columns.indexOf(columnToRemove)
    if (index > -1) {
      columns.splice(index, 1)
    }
  })

  const relationCounterpartField = getRelationCounterpartField({
    relationFieldRelation,
    fieldValues
  })

  const [relationCounterpartColumn] = Object.keys(
    relationCounterpartField || {}
  )

  const {
    yesObjects,
    isLoading,
    pagination,
    totalObjects,
    setPage,
    sortOptions,
    setSortOptions,
    fetchYesObjects: refetchObjects
  } = useFetchYesObjects({
    filterBody: {
      query: {
        $and: [
          {
            [`${relationCounterpartColumn}.uuid`]: {
              $in: [`${fieldValues?.uuid}`]
            },
            'situation.des': {
              $eq: '40'
            }
          }
        ]
      },
      objectclass: objectclassUuid
    },
    enabled: !!relationCounterpartColumn && !!objectclassUuid
  })

  const prevOrderBy = useRef({})

  useEffect(() => {
    if (JSON.stringify(prevOrderBy.current) !== JSON.stringify(orderBy)) {
      setSortOptions(orderBy)
      prevOrderBy.current = orderBy
    }
  }, [orderBy, setSortOptions])

  return (
    <DataTable
      informational={informational}
      embedded={true}
      idObjectClass={objectclassUuid}
      objects={yesObjects}
      onSort={setSortOptions}
      setCurrentPage={setPage}
      actionColumnsPosition='last'
      title={field?.display_text.des}
      displayLayoutSelection={false}
      columnsLayouts={columnsLayouts?.[0]?.columnsLayouts}
      {...{
        objectclass,
        columns,
        fields: updatedFields,
        isLoading,
        pagination,
        totalObjects,
        sortOptions,
        actionButton,
        hierarchyLevel,
        refetchObjects,
        relationCounterpartField,
        navigationSliderBackgroundColor,
        navigationSliderColor,
        fieldSettings
      }}
      {...useTableMode()}
      {...useChangedFieldValues()}
    />
  )
}

export default RelationClass
