import { Style } from '@react-pdf/types'

const fieldView: Style = {
  minWidth: '8%',
  maxWidth: '19%',
  marginRight: 4,
  marginTop: 4
}

const fieldSection: Style = {
  flexDirection: 'row',
  flexWrap: 'wrap'
}

const sectionTitle: Style = {
  fontFamily: 'Barlow',
  fontSize: 10,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 1.4
}

const baseCard: Style = {
  marginTop: 4,
  marginHorizontal: 4,
  flexGrow: 1,
  width: undefined
}

const innerContainer: Style = {
  paddingLeft: 4
}

export default function getStyles() {
  return {
    fieldView,
    fieldSection,
    sectionTitle,
    baseCard,
    innerContainer
  }
}
