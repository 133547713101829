import { useMemo } from 'react'
import { useObjectclass } from 'objectclass/shared'
import useYesObject from 'yesObject/shared/hooks/useYesObject'

import getClassifiedFields from './getClassifiedFields'
import { Field } from '@yes.technology/react-toolkit'
import { updateDisplayText } from 'shared/utils'
import { FieldSettings } from 'shared/types'

type Props = {
  objectclassUuid: string
  classificationUuid?: string
  fieldSettings?: FieldSettings
}

const useObjectFields = ({
  objectclassUuid,
  classificationUuid,
  fieldSettings
}: Props) => {
  const { objectclass, isLoading: isLoadingObjectclass } = useObjectclass({
    objectclassUuid
  })

  const updatedObjectClass = useMemo(() => {
    if (!objectclass) {
      return undefined
    }

    const updatedFields = updateDisplayText(
      objectclass?.fields || {},
      fieldSettings
    )
    return {
      ...objectclass,
      fields: updatedFields
    }
  }, [fieldSettings, objectclass])

  const classificationObject = useYesObject({
    objectUUID: classificationUuid || '',
    objectclassUUID: window.classificationObjectclassUuid
  })

  const allFields = useMemo(
    () =>
      Object.values(updatedObjectClass?.fields || {}).map((field) => {
        if (field?.type === 'RELATION') {
          const relationClasses = Object.values(
            updatedObjectClass?.relation_classes || {}
          )
          return {
            ...field,
            relation: relationClasses?.filter(
              (relation) => field.column_json === relation?.column_json
            )?.[0]
          }
        }

        return field
      }) as Field[],
    [updatedObjectClass]
  )

  const classifiedFields = useMemo(
    () =>
      getClassifiedFields(
        allFields,
        classificationObject?.yesObject?.classification_content as string
      ),
    [allFields, classificationObject?.yesObject?.classification_content]
  )

  const fields = useMemo(
    () =>
      classifiedFields.filter(
        (field) => field && field.type !== 'RELATION'
      ) as Field[],
    [classifiedFields]
  )

  const relationFields = useMemo(
    () =>
      classifiedFields.filter(
        (field): field is Field => field?.type === 'RELATION'
      ),
    [classifiedFields]
  )

  return {
    objectclass: updatedObjectClass,
    fields,
    relationFields,
    isLoading: isLoadingObjectclass || classificationObject?.isLoading
  }
}

export default useObjectFields
