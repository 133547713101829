import apiAsync from 'infrastructure/shared/api/api-async'

type ReloadTokenData = { token: string }

export type ReloadTokenResponse = {
  data: {
    expiration_date: number
    token: string
  }
  httpStatusCode: number
  success: boolean
}

export function postReloadToken(
  obj: ReloadTokenData,
  showLoading?: boolean
): Promise<ReloadTokenResponse> {
  return apiAsync.requestSync({
    endpoint: 'msAuth',
    path: 'reload-token',
    method: 'POST',
    obj,
    showLoading
  })
}
