import { Icon, Text } from '@yes.technology/react-toolkit'
import { useIsOpen } from 'shared/hooks'
import styled from 'styled-components'

type MenuItemType = {
  label: string
  items: {
    label: string
    href: string
  }[]
}

export const MenuItemContainer = styled.div`
  border-bottom: 2px solid #f7f8fa;
  padding: 12px 0;
`

export const MenuItemLabel = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const MenuSubItemContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
`

export const MenuSubItem = styled.a`
  text-decoration: none;
  color: #303032;
`

const MenuItem = ({ label, items }: MenuItemType) => {
  const { isOpen, open, close } = useIsOpen(false)

  return (
    <MenuItemContainer>
      <MenuItemLabel onClick={isOpen ? close : open}>
        <Text variant='content-emphasis-03'>{label}</Text>
        <Icon iconName={isOpen ? 'DropdownArrowUpNew' : 'DropdownArrowDown'} />
      </MenuItemLabel>
      {isOpen && (
        <MenuSubItemContainer>
          {items.map((item, itemIndex) => (
            <MenuSubItem key={`menu-item-${itemIndex}`} href={item.href}>
              <Text variant='content-03'>{item.label}</Text>
            </MenuSubItem>
          ))}
        </MenuSubItemContainer>
      )}
    </MenuItemContainer>
  )
}

export default MenuItem
