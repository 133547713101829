import { shortenNumber } from '@yes.technology/react-toolkit'
import chroma from 'chroma-js'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  ChartWrapperOptions,
  GoogleChartWrapperChartType
} from 'react-google-charts'
import { useTranslation } from 'react-i18n-lite'
import { StyledBrazilChart } from '../Cockpit.styles'
import { ChartFormat } from '../Cockpit.types'
import { ReactComponent as BrazilMapSvg } from '../assets/br.svg'
import { getContrastColor } from '../utils/color'
import { DataChart } from './ChartWrapper'

const statesByRegion = {
  'Centro-Oeste': ['BR-DF', 'BR-GO', 'BR-MT', 'BR-MS'],
  Nordeste: [
    'BR-AL',
    'BR-BA',
    'BR-CE',
    'BR-MA',
    'BR-PB',
    'BR-PE',
    'BR-PI',
    'BR-RN',
    'BR-SE'
  ],
  Norte: ['BR-AC', 'BR-AP', 'BR-AM', 'BR-PA', 'BR-RO', 'BR-RR', 'BR-TO'],
  Sudeste: ['BR-ES', 'BR-MG', 'BR-RJ', 'BR-SP'],
  Sul: ['BR-PR', 'BR-RS', 'BR-SC']
}

const statesWithOverlappingLabels: StatesWithOverlappingLabels = {
  'BR-RN': {
    offsetX: 30,
    offsetY: -70
  },
  'BR-PB': {
    offsetX: 70,
    offsetY: -40
  },
  'BR-PE': {
    offsetX: 130,
    offsetY: -10
  },
  'BR-AL': {
    offsetX: 60,
    offsetY: 10
  },
  'BR-SE': {
    offsetX: 50,
    offsetY: 50
  }
}

interface StatesWithOverlappingLabels {
  [x: string]: {
    offsetX: number
    offsetY: number
  }
}

interface BrazilMapProps {
  chartModel: GoogleChartWrapperChartType
  data: DataChart
  options: ChartWrapperOptions['options']
  title: string
  format?: ChartFormat
}

interface ColoredData {
  region: string
  value: string | number | null
  color: string
}

interface ApplyColorsProps {
  coloredData: ColoredData[]
  defaultColor: string
  isRegionMap: boolean
  isStateMap: boolean
  svgElement: React.RefObject<SVGSVGElement>
}

interface SetLabelsProps {
  labelSelector: string
  data: DataChart
  svgElement: React.RefObject<SVGSVGElement>
  coloredData: ColoredData[]
  defaultBackgroundColor?: string
  format?: ChartFormat
  locale?: string
}

export function applyColors({
  coloredData,
  defaultColor,
  isRegionMap,
  isStateMap,
  svgElement
}: ApplyColorsProps) {
  if (!svgElement.current) return

  const paths = svgElement.current.querySelectorAll('path')

  paths.forEach((path) => {
    const stateId = path.getAttribute('id')

    let backgroundColor = defaultColor
    const strokeColor = '#ccc'

    if (isStateMap) {
      backgroundColor =
        coloredData.find((p) => p.region === stateId)?.color || defaultColor
    }

    if (isRegionMap && stateId) {
      const region = Object.entries(statesByRegion).find(([, states]) =>
        states.includes(stateId)
      )?.[0]

      backgroundColor =
        coloredData.find((p) => p.region === region)?.color || defaultColor
    }

    path.setAttribute('fill', backgroundColor)
    path.setAttribute('stroke', strokeColor)
  })
}

export function setLabels({
  labelSelector,
  data,
  svgElement,
  coloredData,
  defaultBackgroundColor = '#F1F1F1',
  format,
  locale = 'en-US'
}: SetLabelsProps) {
  if (!svgElement.current) return

  const svg = svgElement.current

  // Adiciona o filtro de sombra ao SVG
  const defs =
    svg.querySelector('defs') ||
    document.createElementNS('http://www.w3.org/2000/svg', 'defs')
  if (!svg.querySelector('defs')) svg.appendChild(defs)

  const filter = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'filter'
  )
  filter.setAttribute('id', 'textShadow')
  filter.setAttribute('x', '-50%')
  filter.setAttribute('y', '-50%')
  filter.setAttribute('width', '200%')
  filter.setAttribute('height', '200%')

  const feDropShadow = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'feDropShadow'
  )
  feDropShadow.setAttribute('dx', '1') // Deslocamento horizontal da sombra
  feDropShadow.setAttribute('dy', '1') // Deslocamento vertical da sombra
  feDropShadow.setAttribute('stdDeviation', '2') // Intensidade da sombra (desfoque)
  feDropShadow.setAttribute('flood-color', 'rgba(0, 0, 0, 0.5)')

  filter.appendChild(feDropShadow)
  defs.appendChild(filter)

  const circles = svg.querySelectorAll(labelSelector + ' circle')

  circles.forEach((circle) => {
    const id = circle.getAttribute('id') || ''
    const label = data.find((p) => p[0] === id)?.[1]

    const isLabel = label !== undefined && label !== null && label !== ''

    const isStateWithLabelOverlapping = Object.keys(
      statesWithOverlappingLabels
    ).includes(id)

    if (isLabel) {
      let cx = circle.getAttribute('cx') || '0'
      let cy = circle.getAttribute('cy') || '0'

      const initialXStatePosition = cx // Posição inicial da linha de ligação no eixo X
      const initialYStatePosition = cy // Posição inicial da linha de ligação no eixo Y

      if (isStateWithLabelOverlapping) {
        const { offsetX, offsetY } = statesWithOverlappingLabels[id]

        cx = `${Number(cx) + offsetX}` // Posição final da linha de ligação no eixo X
        cy = `${Number(cy) + offsetY}` // Posição final da linha de ligação no eixo Y

        // Adiciona linha de ligação entre o estado e o label
        const lineLabelSvg = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg'
        )

        lineLabelSvg.setAttribute('width', '1000')
        lineLabelSvg.setAttribute('height', '1000')

        const line = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line'
        )

        line.setAttribute('x1', initialXStatePosition)
        line.setAttribute('y1', initialYStatePosition)

        line.setAttribute('x2', cx)
        line.setAttribute('y2', cy)

        line.setAttribute('stroke', '#ccc')
        line.setAttribute('stroke-width', '1')

        lineLabelSvg.appendChild(line)

        svg?.appendChild(lineLabelSvg)
      }

      const backgroundColor =
        coloredData.find((p) => p.region === id)?.color ||
        defaultBackgroundColor

      const labelColor = isStateWithLabelOverlapping
        ? '#000'
        : getContrastColor(backgroundColor)

      const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
      )
      text.setAttribute('x', cx)
      text.setAttribute('y', cy)
      text.setAttribute('font-size', '1.5rem')
      text.setAttribute('text-anchor', 'middle')
      text.setAttribute('fill', labelColor)
      text.setAttribute('stroke', 'none')

      if (labelColor === '#ffffff' && !isStateWithLabelOverlapping) {
        text.setAttribute('filter', 'url(#textShadow)')
      }

      const tspanName = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
      )
      tspanName.textContent = id.replace('BR-', '')
      tspanName.setAttribute('x', cx)
      tspanName.setAttribute('dy', '0')

      const tspanLabel = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
      )

      tspanLabel.textContent = label.toString()

      // Formata o label  de acordo com o formato especificado
      switch (format) {
        case 'decimal':
          tspanLabel.textContent = Intl.NumberFormat(locale).format(
            label as number
          )
          break
        case 'short':
          tspanLabel.textContent = shortenNumber(label as number)
          break
        default:
          break
      }

      tspanLabel.setAttribute('x', cx)
      tspanLabel.setAttribute('dy', '1em')

      text.appendChild(tspanName)
      text.appendChild(tspanLabel)

      svg?.appendChild(text)
    }
  })
}

function BrazilMap({
  chartModel,
  data,
  options,
  title,
  format = 'none'
}: BrazilMapProps) {
  const svgRef = useRef<SVGSVGElement>(null)

  const mapRenderingRef = useRef({ stateMap: false, regionMap: false })

  const { displayMode, defaultColor, colorAxis } = options || {}

  const isRegionMap = displayMode === 'regions'
  const isStateMap = displayMode === 'states'

  const { language: locale } = useTranslation()

  const coloredData = useMemo(() => {
    const values = data.slice(1).map((item) => Number(item[1]))
    const minValue = Math.min(...values)
    const maxValue = Math.max(...values)
    const colorScale = chroma
      .scale(colorAxis.colors)
      .domain([minValue, maxValue])
    return data.slice(1).map(([region, value]) => ({
      region,
      value,
      color: value ? colorScale(Number(value)).hex() : defaultColor
    }))
  }, [colorAxis.colors, data, defaultColor])

  const updateRegionChart = useCallback(() => {
    if (!isRegionMap) return

    applyColors({
      coloredData,
      defaultColor,
      isRegionMap,
      isStateMap,
      svgElement: svgRef
    })
    setLabels({
      labelSelector: '#regions_label',
      data,
      svgElement: svgRef,
      coloredData,
      format,
      locale
    })

    mapRenderingRef.current.regionMap = true
  }, [isRegionMap, coloredData, defaultColor, isStateMap, data, format, locale])

  const updateStateChart = useCallback(() => {
    if (!isStateMap) return

    applyColors({
      coloredData,
      defaultColor,
      isRegionMap,
      isStateMap,
      svgElement: svgRef
    })
    setLabels({
      labelSelector: '#states_label',
      data,
      svgElement: svgRef,
      coloredData,
      format,
      locale
    })

    mapRenderingRef.current.stateMap = true
  }, [isStateMap, coloredData, defaultColor, isRegionMap, data, format, locale])

  useEffect(() => {
    if (isRegionMap && !mapRenderingRef.current.regionMap) {
      updateRegionChart()
    } else if (isStateMap && !mapRenderingRef.current.stateMap) {
      updateStateChart()
    }
  }, [updateRegionChart, updateStateChart])

  return (
    <StyledBrazilChart {...{ chartModel }}>
      <h2>{title}</h2>
      <BrazilMapSvg width={'100%'} ref={svgRef} />
    </StyledBrazilChart>
  )
}

export default BrazilMap
