import React from 'react'
import { Helmet } from 'react-helmet-async'
import { MetadataProps } from './Metadata.types'

function Metadata({ props }: MetadataProps) {
  const { title } = props

  if (!title) return null

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

export default Metadata
