import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import Render from './Render'
import { useSitemodelContent } from 'sitemodel/shared/hooks'
import { getApplicationSystemOwner, tagManager } from 'shared/utils'
import useSitemodelFromUrl from './shared/hooks/useSitemodelFromUrl'
import { ApplicationSystem } from 'sitemodel/shared/hooks/useSitemodelLocalParams/useSitemodelLocalParams'

const Create = () => {
  const { sitemodel, isLoading } = useSitemodelFromUrl()

  const content = useSitemodelContent({
    sitemodelContent: sitemodel?.sitemodel_content
  })

  const documentTitle = content?.title || sitemodel?.des

  const location = useLocation()

  const username = useSelector(({ authentication }) => authentication?.username)

  tagManager.setDataLayer({
    event: 'pageview',
    page: {
      url: location.pathname,
      title: documentTitle
    },
    user: {
      username
    }
  })

  const applicationSystem = useMemo<Partial<ApplicationSystem>>(
    getApplicationSystemOwner,
    []
  )

  const cloudfrontUrl = window.cloudfrontUrl

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
        {applicationSystem?.owner && (
          <link
            rel='icon'
            href={`${cloudfrontUrl}/static-files/favicon/${applicationSystem.owner}.ico`}
          />
        )}
        {applicationSystem?.owner && (
          <link
            rel='manifest'
            href={`${cloudfrontUrl}/static-files/pwa/${applicationSystem.owner}/manifest.json`}
            crossOrigin='anonymous'
          />
        )}
      </Helmet>

      <Render {...{ content, isLoading }} />
    </>
  )
}

export default Create
