import { Objectclass } from '@yes.technology/react-toolkit'
import apiAsync from 'infrastructure/shared/api/api-async'

export default async function fetchObjectclass(
  objectclassUuid: unknown,
  showLoading: boolean
): Promise<Objectclass | null> {
  const response = await apiAsync.requestSync({
    endpoint: 'objectclass',
    path: objectclassUuid,
    method: 'GET',
    showLoading
  })

  return response?.data?.[0] || null
}
