import { DocumentListCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const ContentContainer = styled.div`
  span > a {
    color: #009cff !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
`

export const AccordionTitle = styled.summary`
  list-style: none;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  &::-webkit-details-marker {
    display: none;
  }
`

export const AccordionContainer = styled(DocumentListCard)`
  padding: 40px 40px 16px;
  margin-bottom: 16px;

  details[open] > summary > figure {
    transform: rotate(180deg);
  }
`

export const AccordionDivider = styled.hr`
  margin: 24px 0;
`
