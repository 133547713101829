import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import {
  createStateSyncMiddleware,
  initStateWithPrevTab
} from 'redux-state-sync'

import apiAsync from 'infrastructure/shared/api/api-async'

import * as reducers from './ducks'
import { migrations } from './migrations'

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  blacklist: ['api'],
  migrate: createMigrate(migrations, { debug: false })
}

const setAcceptLanguageMiddleware = (apiAsync: any) => {
  return () => (next: any) => (action: any) => {
    if (action.type === 'i18n / changeLanguage') {
      apiAsync.setAcceptLanguage(action.lang)
    }
    return next(action)
  }
}

const initialState = {}

const rootReducer = (state: any, action: any) => {
  const isClearStore =
    action.type === 'redux_store/CLEAR' || action.type === 'login/LOGOUT'
  if (isClearStore) {
    // resets the current state
    const newState = appReducer(undefined, action)
    // and preserves authentication
    newState.authentication = reducers.authentication(
      state.authentication,
      action
    )
    return newState
  }
  return appReducer(state, action)
}

const appReducer = combineReducers(reducers)
const persistedReducer = persistReducer(persistConfig, rootReducer)

const setAcceptLanguage = setAcceptLanguageMiddleware(apiAsync)

// A middleware to sync redux state across browser tabs
const config = {
  // Only 'login/SUCCEEDED' and 'login/LOGOUT' will be triggered in other tabs
  whitelist: ['login/SUCCEEDED', 'login/LOGOUT']
}

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: [thunk, setAcceptLanguage, createStateSyncMiddleware(config)],
  devTools: process.env.NODE_ENV !== 'production'
})

// init state with other tabs
initStateWithPrevTab(store)

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch

export default store
