import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import axiosRetry from 'axios-retry'

import IHTTPClient, { DefaultHeaders } from '../types/HTTPClient.interface'

const defaultOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

class HTTPClient implements IHTTPClient {
  client: AxiosInstance
  token?: string
  language?: string

  constructor() {
    this.client = axios.create(defaultOptions)

    axiosRetry(this.client, { retries: 3 })
  }

  _getLanguage() {
    const headerLanguages: Record<string, string> = {
      'pt-BR': 'pt-BR, en-US',
      de: 'de, en-US',
      en: 'en-US'
    }

    return headerLanguages[this.language || 'en']
  }

  _updateHeaders() {
    this.client.defaults.headers = {
      ...defaultOptions.headers,
      'Accept-Language': this._getLanguage(),
      ...(this.token && { AUTH_TOKEN: this.token })
    } as DefaultHeaders
  }

  setToken(token: string) {
    this.token = token
    this._updateHeaders()
  }

  setLanguage(language: string) {
    this.language = language
    this._updateHeaders()
  }

  get(url: string, config?: AxiosRequestConfig<any> | undefined) {
    return this.client.get(url, config)
  }

  post(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) {
    return this.client.post(url, data, config)
  }

  put(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) {
    return this.client.put(url, data, config)
  }

  patch(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) {
    return this.client.patch(url, data, config)
  }

  delete(url: string, config?: AxiosRequestConfig<any> | undefined) {
    return this.client.delete(url, config)
  }
}

export default new HTTPClient()
