import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18n-lite'

import { ActiveSessionCard, GoBackButton } from './styles'
import { AuthenticationType } from 'authentication/shared/types'
import useIframe from 'site/Render/hooks/useIframe'
import Iframe from 'shared/components/Iframe'
import { useCallback, useEffect } from 'react'
import { Icon, Text } from '@yes.technology/react-toolkit'
import { useLogout } from 'shared/hooks'

export function shouldProcessMessage(
  eventOrigin: string,
  expectedOrigin: string
): boolean {
  return eventOrigin === expectedOrigin.replace(/\/$/g, '')
}

type ActiveSessionProps = {
  goBack?: () => void
  close?: () => void
}

const ActiveSession = ({ goBack, close }: ActiveSessionProps) => {
  const { language, setLanguage, t } = useTranslation()

  const { token } = useSelector(
    ({ authentication }: AuthenticationType) => authentication
  )

  const { handleLogout } = useLogout()

  const encodeHref = encodeURIComponent(window.location.href)
  const redirectOrigin = `redirectOrigin=${encodeHref}`
  const waAuthenticationUrl = window.waAuthenticationUrl
  const encodeToken = encodeURIComponent(token)
  const encodeLanguage = encodeURIComponent(language)

  const iframeSrc = `${waAuthenticationUrl}active-session?token=${encodeToken}&lang=${encodeLanguage}`

  const { iframeAttributes, receiveMessage } = useIframe({
    origin: waAuthenticationUrl,
    src: iframeSrc,
    title: 'Active Session',
    initialHeight: '300px'
  })

  const handleModifyCredentials = useCallback(() => {
    window.location.assign(
      `${waAuthenticationUrl}credentials?${redirectOrigin}&token=${encodeURIComponent(token)}`
    )
  }, [redirectOrigin, waAuthenticationUrl, token])

  const onReceivedMessage = useCallback(
    (event: MessageEvent) => {
      receiveMessage(event)

      if (!shouldProcessMessage(event.origin, waAuthenticationUrl)) return

      if (event.data.logout) {
        close?.()
        handleLogout()
      }

      if (event.data.modifyCredentials) {
        handleModifyCredentials()
      }

      if (event.data.language) {
        setLanguage(event.data.language)
      }
    },
    [
      waAuthenticationUrl,
      receiveMessage,
      close,
      handleLogout,
      handleModifyCredentials,
      setLanguage
    ]
  )

  useEffect(() => {
    window.addEventListener('message', onReceivedMessage)
    return () => window.removeEventListener('message', onReceivedMessage)
  }, [onReceivedMessage])

  return (
    <ActiveSessionCard>
      {goBack && (
        <GoBackButton onClick={goBack}>
          <Icon iconName='DropdownArrowLeft' />
          <Text variant='content-emphasis-03'>{t('navigation.back')}</Text>
        </GoBackButton>
      )}
      {iframeAttributes && <Iframe {...iframeAttributes} />}
    </ActiveSessionCard>
  )
}

export default ActiveSession
