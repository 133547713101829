import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #303032;
`

export const Image = styled.img`
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: 610px;
    height: auto;
  }
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

  @media (min-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }
`

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 100%;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-width: 650px;
  }
`
