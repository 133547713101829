import { View, Text } from '@react-pdf/renderer'
import getStyles from './styles'
import { operators } from 'site/Renderer/components/FilterFields/components/Operators/Operators'

interface OperatorFieldProps {
  label: string
  operator: string
  value: string | string[]
}

const MAPPED_OPERATORS = Object.fromEntries(
  Object.keys(operators).map((type) => [
    type,
    Object.fromEntries(
      operators[type].map((operator) => [operator.operator, operator.des])
    )
  ])
)

function getOperatorDes(operatorKey: string, type = 'string') {
  return MAPPED_OPERATORS[type][operatorKey]
}

function OperatorField({ label, operator, value }: OperatorFieldProps) {
  const styles = getStyles()
  return (
    <View>
      <Text style={styles.labelText}>{label}</Text>
      <View style={styles.mainContainer}>
        <View style={styles.operatorBox}>
          <Text style={styles.operatorText}>
            {getOperatorDes(
              operator,
              Array.isArray(value) ? 'array' : 'string'
            )}
          </Text>
        </View>
        <View style={styles.valuesBox}>
          {Array.isArray(value) ? (
            value.map((val, idx) => (
              <Text style={styles.valueText} key={val + idx}>
                {val}
              </Text>
            ))
          ) : (
            <Text style={styles.valueText}>{value}</Text>
          )}
        </View>
      </View>
    </View>
  )
}

export default OperatorField
