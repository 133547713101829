import {
  useFetchFilteractionDescriptors,
  useSitemodelFilteractionParams,
  useSitemodelLocalParams,
  useSitemodelStateGrouping
} from 'sitemodel/shared/hooks'

const useSitemodelContentAggregator = (state: Record<string, object>) => {
  const stateGrouped = useSitemodelStateGrouping(state)

  useSitemodelLocalParams(stateGrouped)

  const resultsById = useFetchFilteractionDescriptors({
    stateFilteractions: stateGrouped['filteraction']
  })

  useSitemodelFilteractionParams({
    filteractionDescriptors: resultsById,
    filteractionManifestState: stateGrouped['filteraction']
  })
}

export default useSitemodelContentAggregator
