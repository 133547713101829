import { useLocation } from 'react-router-dom'
import { GroupedFieldValues } from '../../types'
import { useRef, useState } from 'react'

type SitemodelHistory = {
  url: string
  targetField: string
  filterState: GroupedFieldValues
}

type LocationStateType = {
  sitemodelHistory?: SitemodelHistory[]
  persistentSelection?: GroupedFieldValues
  shouldPersistData?: boolean
  shouldResetData?: boolean
}

export const useLocationState = () => {
  const location = useLocation()

  const locationState = location.state as LocationStateType
  const persistentSelection = locationState?.persistentSelection

  const [shouldResetData, setShouldResetData] = useState(
    !!locationState?.shouldResetData
  )
  const [shouldPersistData, setShouldPersistData] = useState(
    !!locationState?.shouldPersistData
  )

  const latestLocationState = useRef<LocationStateType>()

  if (locationState !== latestLocationState.current) {
    if (locationState) {
      if (!!locationState.shouldResetData !== shouldResetData) {
        setShouldResetData(!!locationState.shouldResetData)
      }

      if (!!locationState.shouldPersistData !== shouldPersistData) {
        setShouldPersistData(!!locationState?.shouldPersistData)
      }
    }

    latestLocationState.current = locationState
  }

  return {
    locationState,
    persistentSelection,
    shouldResetData,
    shouldPersistData,
    setShouldResetData,
    setShouldPersistData
  }
}
