import { compact } from 'shared/utils'
import { AlignEnum, RepresentationEnum } from 'site/shared/enums'
import { TopAreaLayoutType, TopAreaPositionType } from 'site/shared/types'
import { useSiteState } from 'siteState/shared'

type UseTaskTopAreaLayoutArgs = {
  taskDes?: string
}

export default function useTaskTopAreaLayout({
  taskDes
}: UseTaskTopAreaLayoutArgs = {}) {
  const [topAreaLayoutState] = useSiteState<TopAreaLayoutType>('topAreaLayout')

  const topAreaLayout: TopAreaLayoutType = {
    position_1: [
      {
        order: 1,
        align: AlignEnum.Right,
        representation: RepresentationEnum.LogoArea,
        image:
          'https://yes-image-wiki.s3.amazonaws.com/yes-logo/logo-yes-230.f2d86437baf07e1a9f09.png',
        des: 'Yes Network',
        link: '/'
      }
    ],
    position_2: compact<TopAreaPositionType>([
      Boolean(taskDes) && {
        order: 1,
        align: AlignEnum.Left,
        representation: RepresentationEnum.Des,
        des: taskDes
      }
    ]),
    position_3: [
      {
        order: 1,
        align: AlignEnum.Left,
        representation: RepresentationEnum.Component,
        component: 'Login'
      }
    ]
  }

  return topAreaLayoutState ?? topAreaLayout
}
