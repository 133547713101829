import Unauthorized, { UnauthorizedProps } from './Unauthorized'

export interface UnauthorizedContainerProps {
  props?: UnauthorizedProps
}

const UnauthorizedContainer = ({ props }: UnauthorizedContainerProps) => {
  return <Unauthorized {...props} />
}

export default UnauthorizedContainer
