import { render, screen } from '@testing-library/react'
import { MemoryRouter } from 'react-router-dom'

import LogoArea from 'site/Render/components/TopArea/LogoArea'

it('Complete component', () => {
  const props = {
    des: 'my logo',
    image: 'https://my.image.png',
    link: '/home'
  }

  render(<LogoArea {...props} />, { wrapper: MemoryRouter })

  const image = screen.getByAltText(/my logo/i)
  expect(image).toHaveAttribute('src', 'https://my.image.png')

  const link = screen.getByRole('link')
  expect(link).toHaveAttribute('href', '/home')
})

it('Component with incomplete props', async () => {
  const props = {
    des: 'my logo'
  }

  const { container } = render(<LogoArea {...props} />, {
    wrapper: MemoryRouter
  })

  expect(container.firstChild).toBeNull()
})
