import useTableContext from '../../hooks/useTableContext'
import { DatePicker, Unifree, YesObject } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import AutocompleteUniselectionField from '../../../FilterFields/components/Field/AutocompleteUniselectionField'
import { FieldValuesValue } from '../../../FilterFields/types'
import { EditableCellInputProps } from './TableCell.types'
import { MODIFIABLE_FIELDS_DENYLIST } from 'shared/constants'

export default function EditableCellInput({
  value,
  formattedValue,
  columnName,
  objectUuidOrIndex
}: EditableCellInputProps) {
  const disabled = MODIFIABLE_FIELDS_DENYLIST.includes(columnName)
  const { language } = useTranslation()
  const { setChangedFieldValues, changedFieldValues, objectclass, fields } =
    useTableContext()
  const field = fields?.[columnName]
  const label = field?.display_text.des
  const type = field?.type || 'STRING'

  const currentValue = (() => {
    if (
      changedFieldValues[objectUuidOrIndex] &&
      columnName in changedFieldValues[objectUuidOrIndex]
    ) {
      return changedFieldValues[objectUuidOrIndex][columnName]
    }

    if (type === 'RELATION') {
      return value
    }

    return formattedValue
  })()

  const onChange = (inputValue: FieldValuesValue) => {
    const normalizedValue = Array.isArray(inputValue)
      ? inputValue.map(({ des, uuid }) => ({ des, uuid }))
      : inputValue

    if (objectUuidOrIndex) {
      setChangedFieldValues({
        ...changedFieldValues,
        [objectUuidOrIndex]: {
          ...changedFieldValues[objectUuidOrIndex],
          [columnName]: normalizedValue
        }
      })
      return
    }
  }

  if (type === 'RELATION') {
    const selectedOptions = Array.isArray(currentValue)
      ? (currentValue as YesObject[])
      : []

    return (
      <AutocompleteUniselectionField
        {...{ disabled }}
        onChangeValues={onChange}
        selectedOptions={selectedOptions}
        objectclassUuid={
          objectclass?.relation_classes?.[columnName].uuid_objectclass || ''
        }
        aria-label={label}
      />
    )
  }

  if (type === 'DATETIME') {
    return (
      <DatePicker
        {...{ disabled, onChange }}
        heightClass='medium'
        format='date-time'
        lang={language}
        value={typeof currentValue === 'string' ? currentValue : ''}
        aria-label={label}
      />
    )
  }

  return (
    <Unifree
      {...{ disabled, onChange }}
      value={(currentValue || '').toString()}
      aria-label={label}
    />
  )
}
