import { AutoField, Fields, MapContainer, SIDField } from './styles'
import { MapMarker, RouteSectionProps } from './types'
import { useTranslation } from 'react-i18n-lite'
import { Unifree, useIsMobile, Map } from '@yes.technology/react-toolkit'

function RouteSection({ route, checkpoints }: RouteSectionProps) {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  if (!route) {
    return null
  }

  const {
    sid,
    estimated_time: estimatedTime,
    estimated_km: estimatedDistance
  } = route

  const validCheckpoints = checkpoints.filter(
    ({ address }) => address?.[0]?.latitude && address?.[0]?.longitude
  )

  const mapMarkers: MapMarker[] = validCheckpoints.map(({ address }) => ({
    coord: {
      lat: Number(address?.[0]?.latitude),
      lng: Number(address?.[0]?.longitude)
    },
    status: 'todo'
  }))

  return (
    <div>
      <Fields>
        <SIDField>
          <Unifree
            label={t('route-section.sid-label')}
            value={sid || ''}
            heightClass={isMobile ? 'medium' : 'xSmall'}
            informational
          />
        </SIDField>
        <AutoField>
          <Unifree
            label={t('route-section.estimated')}
            value={`${estimatedTime} | ${estimatedDistance} km`}
            heightClass={isMobile ? 'medium' : 'xSmall'}
            informational
          />
        </AutoField>
      </Fields>
      <MapContainer>
        <Map checkpoints={mapMarkers} />
      </MapContainer>
    </div>
  )
}

export default RouteSection
