import { Field, Objectclass, YesObject } from '@yes.technology/react-toolkit'
import { chunk } from 'lodash'
import { useMemo } from 'react'
import CardsReport from 'report/CardsReport'
import ListReport from 'report/ListReport'
import { FilterFieldsResponse } from 'shared/hooks/useFilterFields/useFilterFields'
import useTableLayouts from 'site/Renderer/components/DataTables/hooks/useTableLayouts'
import { GroupedFieldValues } from 'site/Renderer/components/FilterFields/types'

type UseSplitReportComponentsProps = {
  splitThreshold: number
  fields: Field[]
  relationFields: Field[]
  filterFields?: FilterFieldsResponse
  filterComposition: string
  fieldValues: GroupedFieldValues
  yesObjects: YesObject[]
  reportType: 'list' | 'card'
  layoutClassificationUuid?: string
  objectclass?: Objectclass
}

export default function useSplitReportComponents({
  splitThreshold,
  fields,
  relationFields,
  filterFields,
  filterComposition,
  fieldValues,
  yesObjects,
  reportType,
  layoutClassificationUuid,
  objectclass
}: UseSplitReportComponentsProps): JSX.Element[] {
  const columns = useMemo(
    () => fields.map((field) => field.column_json),
    [fields]
  )

  const columnsLayoutsResponse = useTableLayouts({ layoutClassificationUuid })
  const columnsLayouts = columnsLayoutsResponse?.[0]?.columnsLayouts

  const columnsToRemove = useMemo(
    () => new Set(columnsLayoutsResponse?.[0]?.removeColumns ?? []),
    [columnsLayoutsResponse]
  )

  const filteredColumns = useMemo(
    () => columns.filter((column) => !columnsToRemove.has(column)),
    [columns, columnsToRemove]
  )

  return useMemo(() => {
    return chunk(yesObjects, splitThreshold).map((objects) =>
      reportType === 'list' ? (
        <ListReport
          key={objects[0].uuid}
          values={fieldValues}
          title={
            objectclass?.des ||
            filterFields?.objectclass?.des ||
            'Filtering Action'
          }
          {...{
            filterComposition,
            filterFields,
            objects,
            columns: filteredColumns,
            columnsLayouts,
            objectclass: objectclass || filterFields?.objectclass
          }}
        />
      ) : (
        <CardsReport
          key={objects[0].uuid}
          fields={filterFields}
          objectFields={fields}
          relationClasses={relationFields}
          values={fieldValues}
          title={
            objectclass?.des ||
            filterFields?.objectclass?.des ||
            'Filtering Action'
          }
          objectclass={objectclass || filterFields?.objectclass}
          {...{ objects, filterComposition }}
        />
      )
    )
  }, [
    filteredColumns,
    columnsLayouts,
    fieldValues,
    fields,
    filterComposition,
    filterFields,
    relationFields,
    reportType,
    splitThreshold,
    yesObjects,
    objectclass
  ])
}
