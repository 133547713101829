import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const useLogout = () => {
  const dispatch = useDispatch()

  const handleLogout = useCallback(() => {
    dispatch({
      type: 'login/LOGOUT'
    })
  }, [dispatch])

  return { handleLogout }
}

export default useLogout
