import { getSubdomain } from 'tldts'

export function getApplicationSystemOwner() {
  const applicationSystemSubdomains = window.applicationSystemSubdomains
  const urlParsed = getSubdomain(window.location.href) || ''

  const applicationSystemSubdomain = applicationSystemSubdomains?.filter(
    (object) => object.des === urlParsed
  )?.[0]

  return {
    owner: (applicationSystemSubdomain?.subapplicationsystemowner ||
      applicationSystemSubdomain?.applicationsystemowner) as string
  }
}
