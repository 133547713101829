import { CollapsibleCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const CollapsibleObjectCard = styled(CollapsibleCard)`
  div:has(div) {
    height: 100%;
  }

  nav {
    padding: 16px 12px;
  }
`

export const CollapsibleObjectCardList = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const CollabsibleObjectCardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CollapsibleObjectCardActions = styled.div`
  display: flex;
  width: 50%;
  max-width: 500px;
  gap: 32px;
`
